import { FilterResultType, FilterSelectedType, ShopItemType } from "api/shop/types";
import { ShopCategoryEnum } from "constants/shop";
import { BoxType } from "models/shop";
import { getPublicImageUrl } from "utilities/imageUtils";
import { create } from "zustand";

const subMenuData: ShopCmpType[] = [
    {
        value: ShopCategoryEnum.WATER,
        label: "Acqua",
        imgSrc: getPublicImageUrl(`category-placeholder/water.svg`),
    },
    {
        value: ShopCategoryEnum.DRINK,
        label: "Bibite",
        imgSrc: getPublicImageUrl(`category-placeholder/coke.svg`),
    },
    {
        value: ShopCategoryEnum.JUICE,
        label: "Succhi",
        imgSrc: getPublicImageUrl(`category-placeholder/juice.svg`),
    },
    {
        value: ShopCategoryEnum.BEER,
        label: "Birre",
        imgSrc: getPublicImageUrl(`category-placeholder/beer.svg`),
    },
    {
        value: ShopCategoryEnum.WINE,
        label: "Vino",
        imgSrc: getPublicImageUrl(`category-placeholder/wine.svg`),
    },
];

export type ShopCmpType = {
    value: ShopCategoryEnum;
    label: string;
    imgSrc: string;
};

type StateType = {
    categories: ShopCmpType[];
    filters: Record<ShopCategoryEnum, FilterResultType | null>;
    selectedFilters: Record<ShopCategoryEnum, FilterSelectedType>;
    elements: Record<ShopCategoryEnum, ShopItemType[]>;
    enabledCategories: ShopCategoryEnum[] | undefined;
    boxes: BoxType[] | undefined;
    expandedBoxKey: string | undefined;
    setBoxes: (boxes: any) => void;
    setExpandedBoxKey: (expandedBoxKey: string) => void;
    setEnabledCategories: (enabledCategories: any) => void;
    setFilterData: ({ type, value }: { type: any, value: any }) => void;
    setSelectedFilterData: ({ type, value }: { type: any, value: any }) => void;
};

export const useShopStore = create<StateType>((set) => ({
    categories: subMenuData,
    filters: {

        [ShopCategoryEnum.BEER]: null,
        [ShopCategoryEnum.DRINK]: null,
        [ShopCategoryEnum.JUICE]: null,
        [ShopCategoryEnum.WATER]: null,
        [ShopCategoryEnum.WINE]: null,
    },
    selectedFilters: {

        [ShopCategoryEnum.BEER]: {
            brands: undefined,
            types: undefined
        },
        [ShopCategoryEnum.DRINK]: {
            brands: undefined,
            types: undefined
        },
        [ShopCategoryEnum.JUICE]: {
            brands: undefined,
            types: undefined
        },
        [ShopCategoryEnum.WATER]: {
            brands: undefined,
            types: undefined
        },
        [ShopCategoryEnum.WINE]: {
            brands: undefined,
            types: undefined
        },
    },
    elements: {
        [ShopCategoryEnum.BEER]: [],
        [ShopCategoryEnum.DRINK]: [],
        [ShopCategoryEnum.JUICE]: [],
        [ShopCategoryEnum.WATER]: [],
        [ShopCategoryEnum.WINE]: [],
    },
    enabledCategories: undefined,
    boxes: undefined,
    expandedBoxKey: undefined,
    setExpandedBoxKey: (expandedBoxKey: string) => set((oldState) => {

        return { expandedBoxKey };
    }),
    setBoxes: (boxes: any) => set((oldState) => {

        return { boxes };
    }),
    setEnabledCategories: (enabledCategories: any) => set((oldState) => {
        return { enabledCategories };
    }),
    setFilterData: ({ type, value }: { type: any, value: any }) => set((oldState) => {
        oldState.filters[type as ShopCategoryEnum] = value;
        return { filters: { ...oldState.filters } };
    }),
    setSelectedFilterData: ({ type, value }: { type: any, value: any }) => set((oldState) => {
        oldState.selectedFilters[type as ShopCategoryEnum] = value;
        return { selectedFilters: { ...oldState.selectedFilters } };
    }),
}))