import ReactGA from 'react-ga4';
import { AnalyticsActionType, AnalyticsEventActionType } from './types';
import settings from 'settings';

let enabled = false;

export default {
    init() {
        if (settings.googleAnalyticsTrackingId != null && settings.googleAnalyticsTrackingId != "") {
            enabled = true;

            console.log("Google Analytics ENABLED");
            ReactGA.initialize(settings.googleAnalyticsTrackingId!);
        } else {

            console.warn("Google Analytics DISABLED");
        }
    },
    sendPageChanged(url: string, title: string) {
        console.log("GA sendPageChanged", enabled)
        // console.log("GA-sendPageChange: ", enabled, " url:", url, ", title: ", title);

        if (!enabled) {
            return;
        }

        ReactGA.send({ hitType: AnalyticsActionType.PAGE_VIEW, page: url, title: title });
    },
    sendEvent(category: string, action: AnalyticsEventActionType, label: string, customData: any) {
        console.log("GA sendEvent", enabled)
        // console.log("GA-sendEvent:", enabled, "cat: ", category, ", action: ", action, ", label: ", label, ", data: ", customData);

        if (!enabled) {
            return;
        }
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            ...customData
        });
    }
}