import { useContext } from "react";
import CartElement from "./CartElement";
import { useCartStore } from "store/cartStore";

type PropsType = {
    showProductIcon?: boolean;
};

export default ({ showProductIcon }: PropsType) => {
    const data = useCartStore(state => state.data);

    /* console.log("CartList", data) */

    return <div>
        <div className="shadow-box box-sm">
            <div className="d-flex flex-column gap-3">

                <div className="d-flex flex-column gap-2">

                    {data.map((d, idx) => (<>
                        {idx > 0 ? <hr className="my-0" /> : null}
                        <div className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column">
                                <CartElement data={d} showProductIcon={showProductIcon} />
                            </div>
                        </div>
                    </>))
                    }
                </div>
            </div>
        </div>
    </div>
}