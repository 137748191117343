import { useContext, useRef, useState } from "react";
import { UserRegistrationBody } from "api/user/types";
import { REGISTRATION_TYPE } from "constants/auth";
import { UserProfile } from "models/user";
import { Link, useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "utilities/imageUtils";
import FacebookLoginBtn from "./FacebookLoginBtn";
import GoogleLoginBtn from "./GoogleLoginBtn";
import RegistrationForm from "./RegistrationForm";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import FormField from "components/common/form/FormField";
import api from "api";
import { useAuthStore } from "store/authStore";
import { LocationType } from "models/location";
import { useAppStore } from "store/appStore";
import urlUtils from "utilities/urlUtils";

type PropsType = {
};

export default ({ }: PropsType) => {
    const [mailSent, setMailSent] = useState(false);

    const getInitFormData = () => {
        return {
            email: "",
        };
    };

    const [forgotPswFormData, setForgotPswFormData] = useState(
        getInitFormData()
    );
    const forgotPswFormSchema = Yup.object().shape({
        email: Yup.string().required("Email richiesta").email("Email non valida"),
    });

    return (
        <>
            <div className="mt-3">
                <Formik
                    initialValues={forgotPswFormData}
                    enableReinitialize
                    validationSchema={forgotPswFormSchema}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);
                        setMailSent(false);
                        console.log("Forgot psw clicked!");
                        api.auth.forgotPassword(values.email).then((res) => {
                            // onLoginCompletedInternal(res.token, res.user, res.warehouseToken, res.defaultUserLocation);
                            setMailSent(true);
                        }).catch(() => {
                            setFieldError('email', "L'invio non è andato a buon fine.");
                            setMailSent(false);
                        }).finally(() => setSubmitting(false))
                    }}
                >
                    {(formik) => {
                        const {
                            errors,
                            touched,
                            isValid,
                            dirty,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        } = formik;
                        return (
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                // handleSubmit();
                            }}>
                                <div className="d-flex flex-column gap-2 gap-lg-3">
                                    <FormField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e: any) => {
                                            if (mailSent) {
                                                setMailSent(false);
                                            }
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                    />


                                    <button className="btn btn-primary" disabled={!(dirty && isValid) || mailSent} onClick={() => handleSubmit()}>
                                        Invia
                                    </button>


                                    {mailSent && <div className="d-flex gap-2 flex-column">

                                        <label className="text-left">
                                            Invio avvenuto correttamente.
                                            <br />
                                            A breve riceverai un’e-mail all’indirizzo indicato, contenente le istruzioni per
                                            la creazione di una nuova password.
                                        </label>

                                    </div>}
                                </div>
                            </Form >
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};
