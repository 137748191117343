import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { allRoutes } from 'router';
import analyticsUtils from 'utilities/analyticsUtils';



const RouteTracker = () => {

    const location = useLocation();

    useEffect(() => {
        const currentRoute = allRoutes.find((route: { path: string; }) => route.path === location.pathname);
        if (currentRoute) {

            analyticsUtils.sendPageChanged(location.pathname, currentRoute.name);
        }
    }, [location]);

    return null;
};


export default RouteTracker;
