import ProfileLayout from "components/layout/ProfileLayout";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import { UserOrder } from "models/user";
import OrderItem from "components/order/OrderItem";
import OrderBox from "components/order/OrderBox";
import { convertToDateString } from "utilities/convertUtils";
import { ORDER_STATUS_ENUM } from "constants/order";
import urlUtils from "utilities/urlUtils";
import OrderRender from "components/order/OrderRender";
import { useCartStore } from "store/cartStore";
import { useAppStore } from "store/appStore";
import { MessageBoxButtonsType, MessageBoxIcon } from "components/common/MessageBox";

export default () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<UserOrder | undefined>();
  const showMessageBox = useAppStore(state => state.showMessageBox);

  useEffect(() => {
    if (id != null) {
      api.user.getOrderDetail(id).then((res) => {
        setOrder(res);
      });
    } else {
      setOrder(undefined);
    }
  }, [id]);

  const addCartFromOrder = useCartStore(state => state.addCartFromOrder);

  if (order == null) {
    return null;
  }

  const onBuyAgainClick = () => {
    if (id != null) {
      api.user.getOrderDetailForCart(id).then((res) => {
        addCartFromOrder({ order: res });
      });
    }
  }

  const deleteOrder = () => {

    showMessageBox({
      icon: MessageBoxIcon.ALERT,
      title: 'Conferma l’eliminazione',
      text: <><p>Sei sicuro/a di voler eliminare l&apos;ordine?</p><p><strong>NOTA BENE: </strong>L&apos;operazione è irreversibile.</p></>,
      type: MessageBoxButtonsType.YESNO,
      onOk() {
        if (id != null) {
          api.user.deleteOrder(id).then(() => {
            navigate(urlUtils.user.orders.main(), { replace: true });
          })
        }
      },
    })
  }

  const getStatusButtons = () => {
    if (order != null) {
      switch (order.status) {
        case ORDER_STATUS_ENUM.PENDING:
        case ORDER_STATUS_ENUM.NOT_PAID: {
          return (<><button className="btn btn-primary btn-full" onClick={() => navigate(urlUtils.paymentOrder(order.id))}>
            Vai al pagamento
          </button>
            <button className="btn btn-default btn-error btn-full" onClick={() => deleteOrder()}>Elimina ordine</button>
          </>);
        }
        case ORDER_STATUS_ENUM.DRAFT: {
          return (<>
            <button className="btn btn-error btn-full" onClick={() => deleteOrder()}>Elimina ordine</button>
          </>);
        }
        case ORDER_STATUS_ENUM.DELIVERED: return (<button className="btn btn-primary btn-full" onClick={() => onBuyAgainClick()}>
          Acquista di nuovo
        </button>);
      }
    }
    return null;
  }

  console.log("order", order)

  return <ProfileLayout showBack title={`Ordine n° ${order?.number}`} >
    <div className="container container-close mt-3 px-0">

      {order?.delivered ? <div className="mb-3"><p>Consegnato: {convertToDateString(order.delivered)}</p></div> : undefined}

      <div className="row flex-bottom-container">
        <div className="col-12 flex-bottom-item">
          <div className="d-flex flex-column gap-custom mb-3 gSpace-1875">
            {order != null ? <OrderRender order={order} /> : null}
          </div>
          <div className="vstack gap-3 mt-5">
            {getStatusButtons()}
          </div>
        </div>
      </div>
    </div>
  </ProfileLayout>;
}
