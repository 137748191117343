import { useState, useEffect } from "react";
import SelectAddress from "./SelectAddress";
import { UserAddress, UserAddressType } from "models/user";
import api from "api";

type PropsType = {
  onChange: (val: UserAddress | undefined) => void;
};
export default ({ onChange }: PropsType) => {
  const [showSelectAddressCard, setShowSelectAddressCard] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<
    UserAddress | undefined
  >();

  useEffect(() => {
    onChange(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    api.user
      .getDefaultShippingAddress()
      .then((res) => {
        setSelectedAddress(res);
      })
      .catch(() => setSelectedAddress(undefined));
  }, []);

  const onAddressChange = (val: UserAddress | undefined) => {
    setSelectedAddress(val);
  };

  return (
    <>
      <div className="mb-4">
        <div className="shadow-box">
          <div className="d-flex flex-column gap-2">
            <h5>Indirizzo di spedizione</h5>

            <div className="d-flex justify-content-between align-items-center gap-3">
              <div>
                {selectedAddress != null ? (
                  <>
                    <p className="text-primary fw-extrabold">
                      {[selectedAddress.name, selectedAddress.surname].join(
                        " "
                      )}
                    </p>
                    <h6 className="text-secondary fw-normal">
                      {selectedAddress.address},{" "}
                      {selectedAddress.postalCode} {selectedAddress.city} (
                      {selectedAddress.postalCode})
                    </h6>
                  </>
                ) : null}
              </div>

              <a
                className="item-center"
                onClick={() => setShowSelectAddressCard(true)}
              >
                <span className="material-symbols-outlined  fill-1">
                  edit
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <SelectAddress
        type={UserAddressType.SHIPPING}
        selectedAddressId={selectedAddress?.id}
        show={showSelectAddressCard}
        setShowValue={setShowSelectAddressCard}
        onChange={onAddressChange}
      />
    </>
  );
};
