import { ShopItemDetailType } from "api/shop/types";
import { ORDER_STATUS_ENUM } from "constants/order";
import { BoxType } from "./shop";
import { SUBSCRIPTION_STATUS_ENUM } from "constants/subscriptions";
import { CouponDiscountType, CouponType } from "./coupon";

export enum UserLoginTypeEnum {
  APP = "app",
  GOOGLE = "google",
  FACEBOOK = "facebook",
};

export type UserProfile = {
  id: string;
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  phone: string | undefined;
  type: UserLoginTypeEnum | undefined;
  defaultShippingAddress: UserAddress | undefined;
  defaultBillingAddress: UserAddress | undefined;
};

export type UserSubscriptionCmp = {
  id: string | undefined;
  number: string | undefined;
  status: SUBSCRIPTION_STATUS_ENUM | undefined;
  createdAt: string | undefined;
  nextOrderDeliveryDate: string | undefined;
  nextOrderStatus: ORDER_STATUS_ENUM | undefined;
  nextOrderDeliveryFromHour: number | undefined;
  nextOrderDeliveryToHour: number | undefined;
  nextOrderShippingAddress: string | undefined;
};

export type UserSubscription = UserSubscriptionCmp & {
  startedAt: string;
  stoppedAt: string | undefined;
  defaultOrder: UserOrder;
}

export type UserOrderCmp = {
  id: string | undefined;
  number: string | undefined;
  status: ORDER_STATUS_ENUM | undefined;
  createdAt: string | undefined;
  delivered: string | undefined;
};

export type UserOrder = {
  id: string | undefined;
  number: string | undefined;
  status: ORDER_STATUS_ENUM | undefined;
  createdAt: string | undefined;
  delivered: string | undefined;
  itemDetails: UserOrderItemDetail[];
  boxes: UserOrderBox[];
  shippingMethodPrice: number | undefined;
  timeSlotDiscount: number | undefined;
  timeSlotDiscountAbsolute: boolean | undefined;
  couponCode: string | undefined;
  couponDiscounts: any[] | undefined;
  couponTotalDiscount: number | undefined;
  couponTotalDiscountAbsolute: boolean | undefined;
  subtotal: number | undefined;
  total: number | undefined;
  totalDiscount: number | undefined;
  totalWithDiscount: number | undefined;
};
export type UserOrderBox = {
  name: string | undefined;
  count: number;
  itemDetails: UserOrderItemDetail[];
};
export type UserOrderItemDetail = {
  id: string | undefined;
  brand: string | undefined;
  name: string | undefined;
  info: string | undefined;
  detailName: string | undefined;
  detailInfo: string | undefined;
  count: number;
  price: number;
  priceWithoutDiscount: number;
  discount: number;
  discountAbsolute: boolean;
  varPerc: number;
};


export type UserCartOrder = {
  id: string | undefined;
  number: string | undefined;
  status: ORDER_STATUS_ENUM | undefined;
  createdAt: string | undefined;
  delivered: string | undefined;
  deliveryDate: string | undefined;
  shippingAddress: UserAddress | undefined;
  billingAddress: UserAddress | undefined;
  coupon: CouponType | undefined;

  isLinkedToSubscription: boolean;

  deliveryFromHour: number;
  deliveryToHour: number;
  timeSlotDiscount: number | undefined;
  timeSlotDiscountAbsolute: boolean;

  shippingMethodPrice: number | undefined;

  couponId: string | undefined;
  couponCode: string | undefined;
  couponDiscounts: CouponDiscountType[] | undefined;

  itemDetails: UserCartOrderItemDetail[];
  boxes: UserCartOrderBox[];
};
export type UserCartOrderBox = {
  box: BoxType | undefined;
  name: string | undefined;
  count: number;
  itemDetails: UserCartOrderItemDetail[];
};
export type UserCartOrderItemDetail = {
  id: string | undefined;
  brand: string | undefined;
  name: string | undefined;
  info: string | undefined;
  detailName: string | undefined;
  detailInfo: string | undefined;
  count: number;
  price: number;
  discount: number;
  discountAbsolute: boolean;
  varPerc: number;
  itemDetail: ShopItemDetailType | undefined;
};


export type UserAddress = {
  id: string;
  type: UserAddressType | undefined;
  name: string | undefined;
  surname: string | undefined;
  address: string | undefined;
  city: string | undefined;
  province: string | undefined;
  postalCode: string | undefined;
  phone: string | undefined;
  notes: string | undefined;
  isDefault: boolean;
  locationId: string | undefined;
};

export enum UserAddressType {
  SHIPPING = "shipping",
  BILLING = "billing"
}
