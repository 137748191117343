import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import { getPublicImageUrl } from "utilities/imageUtils";

export enum MessageBoxIcon {
    ALERT = 'alert',
    SUCCESS = 'success'
}

export enum MessageBoxButtonsType {
    YESNO = 'yesno',
    CLOSE = 'close',
}

export type MessageBoxType = {
    icon?: MessageBoxIcon;
    title: string;
    text?: string | React.ReactNode;
    type: MessageBoxButtonsType;
    onOk?: () => void;
    onClose?: () => void
}

export default ({ icon, title, text, type, onOk, onClose }: MessageBoxType) => {
    const closeMessageBox = useAppStore(state => state.closeCurrentMessageBox);

    const renderButtons = () => {
        switch (type) {
            case MessageBoxButtonsType.YESNO: {
                return <>
                    <button className="btn btn-modal btn-outline-primary btn-md" aria-label="Close"
                        onClick={() => {
                            if (onClose != null) {
                                onClose();
                            }
                            closeMessageBox();
                        }}>No</button>
                    <button className="btn btn-modal btn-primary btn-md" aria-label="Close"
                        onClick={() => {
                            if (onOk != null) {
                                onOk();
                            }
                            closeMessageBox();
                        }}>Sì</button>
                </>;
            }
            case MessageBoxButtonsType.CLOSE: {
                return <><button className="btn btn-modal btn-modal-large btn-primary btn-md" aria-label="Close"
                    onClick={() => {
                        closeMessageBox();
                    }}>Chiudi</button>
                </>;
            }
        }
    }

    return <>
        <div className="modal-backdrop fade show" />
        <div className="modal modal-radius-sm fade show" id="modalAdressDelete" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ paddingRight: 15, display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        {
                            icon != null ?
                                <div className="mb-2">
                                    <img src={getPublicImageUrl(`${icon}.svg`)} width="40px" height="40px" />
                                </div> :
                                null
                        }

                        <h5 className="text-primary mb-2">{title}</h5>
                        {text ? <p>{text}</p> : null}

                        <div className="d-flex alig-items-center justify-content-center gap-3 text-uppercase mt-4">
                            {renderButtons()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;


}