import { createPortal } from "react-dom";
import api from "api";
import Image from "./Image";

export type SheetCardPropsType = {
  show: boolean;
  setShowValue: (val: boolean) => void;
  title?: string;
  imgUrl?: string;
  headerStart?: React.ReactNode;
  bodyTitle?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
};

export default ({
  show,
  setShowValue,
  title = "",
  imgUrl,
  headerStart,
  bodyTitle,
  body,
  footer,
}: SheetCardPropsType) => {
  return createPortal(<>
    <div className={`sheet-wrapper sheet-bottom ${show ? "sheet-open" : ""}`}>
      <div className="sheet">
        <div className="sheet-header">
          <div className="sheet-header-start">{headerStart}</div>

          <div className="sheet-header-center">
            <span>{title}</span>
          </div>

          <div className="sheet-header-end">
            <button className="btn btn-primary btn-default sheet-close-btn" onClick={() => setShowValue(false)}>Chiudi</button>
          </div>
        </div>

        {imgUrl != null ? (
          <div className="sheet-cover">
            <Image
              imageUrl={imgUrl}
              defaultImgUrl={`sheet/sheet-cover.jpg`}

            />
          </div>
        ) : null}

        {bodyTitle != null ? (
          <div className="sheet-title">{bodyTitle}</div>
        ) : null}

        <div className="sheet-body">{body}</div>

        {footer != null ? <div className="sheet-footer">{footer}</div> : null}
      </div>
    </div>
    {show ? <div className={`sheet-backdrop ${show ? "open" : ""}`}></div> : null}
  </>
    , document.body);
};
