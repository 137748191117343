import ProfileLayout from "components/layout/ProfileLayout"
import { UserProfileBody } from "api/user/types";
import FormField from "components/common/form/FormField";
import AppLayout from "components/layout/AppLayout";
import { Formik, Form } from "formik";
import { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { debounce } from "utilities/funcUtils";
import api from "api";
import { FormikObserver } from "components/common/form/FormikObserver";
import { useAuthStore } from "store/authStore";
import { useAppStore } from "store/appStore";
import { MessageBoxButtonsType, MessageBoxIcon } from "components/common/MessageBox";

export default () => {
  const user = useAuthStore(state => state.user);
  const setUserProfile = useAuthStore(state => state.setUserProfile);

  const [loading, setLoading] = useState(false);

  const getInitFormData = () => {
    return {
      name: user?.name ?? "",
      surname: user?.surname ?? "",
      phone: user?.phone ?? "",
      email: user?.email ?? "",
    };
  };

  const [formData, setFormData] = useState<UserProfileBody>(
    getInitFormData() as UserProfileBody
  );

  useEffect(() => {
    setFormData(getInitFormData());
  }, []);

  const signInSchema = Yup.object().shape({
    name: Yup.string().required("Nome richiesto"),
    surname: Yup.string().required("Cognome richiesto"),
    // phone: Yup.string().required("Telefono richiesto"),
  });

  const showMessageBox = useAppStore(state => state.showMessageBox);

  return <ProfileLayout showBack title="Il mio profilo">
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={signInSchema}
      onSubmit={(values) => {
        showMessageBox({
          icon: MessageBoxIcon.SUCCESS,
          title: 'Desideri aggiornare i dati del profilo ?',
          type: MessageBoxButtonsType.YESNO,
          onOk() {
            setLoading(true);
            api.user.editProfile(values).then((user) => {
              setUserProfile(user);
            })
              .finally(() => setLoading(false));
          },
        })
      }}
    >
      {(formik) => {
        const {
          errors,
          touched,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit
        } = formik;
        return (
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column gap-custom mb-3 gSpace-1875">
                  <div className="shadow-box p-3">
                    <div className="d-flex flex-column gap-2">
                      <FormField
                        name="name"
                        label="Nome"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormField
                        name="surname"
                        label="Cognome"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormField
                        icon="driink-email"
                        name="email"
                        label="Email"
                        type="email"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />

                      {/* <FormField
                        name="phone"
                        label="Telefono"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="mt-auto">
                  <button disabled={!(dirty && isValid) || loading} type="submit" className="btn btn-primary btn-full" onClick={() => handleSubmit()}>
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  </ProfileLayout >;
}
