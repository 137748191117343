import { useContext, useRef, useState } from "react";
import { UserRegistrationBody } from "api/user/types";
import { REGISTRATION_TYPE } from "constants/auth";
import { UserProfile } from "models/user";
import { Link, useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "utilities/imageUtils";
import FacebookLoginBtn from "./FacebookLoginBtn";
import GoogleLoginBtn from "./GoogleLoginBtn";
import RegistrationForm from "./RegistrationForm";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import FormField from "components/common/form/FormField";
import api from "api";
import { useAuthStore } from "store/authStore";
import { LocationType } from "models/location";
import { useAppStore } from "store/appStore";
import urlUtils from "utilities/urlUtils";

type PropsType = {
    onLoginCompleted: () => void;
};

export default ({ onLoginCompleted }: PropsType) => {

    const setAvailablePostalcodeLocation = useAppStore(state => state.setAvailablePostalcodeLocation);
    const setFromToken = useAuthStore(state => state.setFromToken);
    const setUserProfile = useAuthStore(state => state.setUserProfile);

    const [showRegistrationForm, setShowRegistrationForm] =
        useState<boolean>(false);
    const [oauthRegistrationData, setOauthRegistrationData] = useState<
        any | undefined
    >();
    const [registrationType, setRegistrationType] = useState<REGISTRATION_TYPE>(
        REGISTRATION_TYPE.APP
    );
    const [defaultRegData, setDefaultRegData] = useState<
        UserRegistrationBody | undefined
    >();

    const onLoginCompletedInternal = (token: string, user: UserProfile, warehouseToken: string, defaultUserLocation: LocationType) => {
        setAvailablePostalcodeLocation({ token: warehouseToken, location: defaultUserLocation })
        setFromToken(token);
        setUserProfile(user);
        onLoginCompleted();
    };

    const onRegistrationRequired = (
        type: REGISTRATION_TYPE,
        oauthData: any,
        defaultData: UserRegistrationBody | undefined
    ) => {
        setRegistrationType(type);
        setOauthRegistrationData(oauthData);
        setDefaultRegData(defaultData);
        setShowRegistrationForm(true);
    };

    const [registrationMode, setRegistrationMode] = useState(false);

    const changeRegistrationLoginView = (isReg: boolean) => {
        setRegistrationMode(isReg);
    }

    const getInitFormData = () => {
        return {
            email: "",
            password: "",
            saveToken: "",
        };
    };

    const [loginFormData, setFLoginormData] = useState(
        getInitFormData()
    );
    const [regFormData, setRefFormData] = useState(
        getInitFormData()
    );

    const loginFormSchema = Yup.object().shape({
        email: Yup.string().required("Email richiesta").email("Email non valida"),
        password: Yup.string().required("Password richiesta"),
        // phone: Yup.string().required("Telefono richiesto"),
    });

    const regFormSchema = Yup.object().shape({
        email: Yup.string().required("Email richiesta").email("Email non valida"),
        password: Yup.string().required("Nuova password richiesta")
            .min(8, "La password deve avere 8 caratteri")
            .matches(/[0-9]/, "La password deve avere almeno un numero")
            .matches(/[a-zA-Z]/, "La password deve avere almeno una lettera")
            .matches(/[^\w\s]/, "La password deve avere almeno un carattere speciale")
        // phone: Yup.string().required("Telefono richiesto"),
    });

    return (
        <>
            <div className="d-flex flex-column gap-2 gap-lg-3">
                {/* <FacebookLoginBtn onLoginCompleted={onLoginCompletedInternal} /> */}

                <GoogleLoginBtn
                    onLoginCompleted={onLoginCompletedInternal}
                    onRegistrationRequired={onRegistrationRequired}
                />
            </div>
            <div className="my-3 my-lg-4">

                <div className="line-separator">
                    oppure
                </div>

            </div>
            <div className="mt-3">
                {
                    !registrationMode ?
                        <Formik
                            initialValues={loginFormData}
                            enableReinitialize
                            validationSchema={loginFormSchema}
                            onSubmit={(values, { setFieldError, setSubmitting }) => {
                                setSubmitting(false);
                                console.log("Login clicked!");
                                api.auth.appLogin(values.email, values.password).then((res) => {
                                    onLoginCompletedInternal(res.token, res.user, res.warehouseToken, res.defaultUserLocation);
                                }).catch(() => {
                                    setFieldError('email', "L'accesso non è andato a buon fine.")
                                })
                            }}
                        >
                            {(formik) => {
                                const {
                                    errors,
                                    touched,
                                    isValid,
                                    dirty,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = formik;
                                return (
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                        <div className="d-flex flex-column gap-2 gap-lg-3">
                                            <FormField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errors={errors}
                                                touched={touched}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <FormField
                                                name="password"
                                                label="Password"
                                                type="password"
                                                errors={errors}
                                                touched={touched}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <div className="d-flex gap-2 mt-2 mb-3 justify-content-between align-items-center">
                                                <FormField
                                                    name={"saveToken"}
                                                    label="Rimani connesso"
                                                    type="checkbox"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {/* <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="checkbox-value" id="checkbox-id" />
                                                <label className="form-check-label light" htmlFor="checkbox-id">
                                                    Rimani connesso
                                                </label>
                                            </div> */}

                                                <div className="text-end">
                                                    <Link className="light" to={urlUtils.forgotPassword()}>Password dimenticata?</Link>
                                                </div>
                                            </div>

                                            <button className="btn btn-primary" disabled={!(dirty && isValid)} onClick={() => handleSubmit()}>
                                                Accedi
                                            </button>

                                            <div className="d-flex mt-2 gap-2 flex-column">

                                                <label className="text-center">
                                                    Non hai ancora un account? <a className="light" onClick={() => {
                                                        changeRegistrationLoginView(true);
                                                    }}>Registrati!</a>
                                                </label>

                                            </div>
                                        </div>
                                    </Form >
                                );
                            }}
                        </Formik> :


                        <Formik
                            initialValues={regFormData}
                            enableReinitialize
                            validationSchema={regFormSchema}
                            onSubmit={(values, { setFieldError, setSubmitting }) => {
                                console.log("Register clicked!");
                                setSubmitting(false);
                                setRegistrationType(REGISTRATION_TYPE.APP);
                                setDefaultRegData({
                                    email: values.email,
                                    password: values.password
                                } as UserRegistrationBody);
                                setShowRegistrationForm(true);
                            }}
                        >
                            {(formik) => {
                                const {
                                    errors,
                                    touched,
                                    isValid,
                                    dirty,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = formik;
                                return (

                                    < Form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                        <div className="d-flex flex-column gap-2 gap-lg-3">
                                            <FormField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errors={errors}
                                                touched={touched}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />


                                            <FormField
                                                name="password"
                                                label="Nuova password"
                                                type="password"
                                                errors={errors}
                                                touched={touched}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="d-flex gap-2 mt-1 justify-content-between align-items-center">
                                                <label>La password deve essere composta da almeno 8 caratteri, un carattere speciale e un numero.</label>
                                            </div>

                                            <div className="d-flex gap-2 mt-2 mb-3 justify-content-between align-items-center">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="checkbox-value" id="checkbox-id" />
                                                    <label className="form-check-label light" htmlFor="checkbox-id">
                                                        Rimani connesso
                                                    </label>
                                                </div>
                                            </div>

                                            <button className="btn btn-primary" disabled={!(dirty && isValid)} onClick={() => handleSubmit()}>
                                                Registrati
                                            </button>

                                            <div className="d-flex mt-2 gap-2 flex-column">

                                                <label className="text-center">
                                                    Hai già un account? <a className="light" onClick={() => {
                                                        changeRegistrationLoginView(false);
                                                    }}>Accedi!</a>
                                                </label>

                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                }
            </div >
            <RegistrationForm
                type={registrationType}
                show={showRegistrationForm}
                setShowValue={setShowRegistrationForm}
                data={defaultRegData}
                oauthData={oauthRegistrationData}
            />
        </>
    );
};
