import axios from "axios";
import urlUtils from "utilities/urlUtils";
import settings from "../settings";
import { useAuthStore } from "store/authStore";
import { useAppStore } from "store/appStore";

const instance = axios.create({
  baseURL: settings.baseUrl,
  timeout: settings.apiTimeoutMs,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // 'Authorization': 'token <your-token-here> -- https://docs.GitHub.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
  },
});

// export function setToken(token: string) {
//   instance.defaults.headers.common.Authorization = `Bearer ${token}`;
// }
// 
// export function removeToken() {
//   delete instance.defaults.headers.common.Authorization;
// }
// 
// export function setLocationToken(token: string) {
//   instance.defaults.headers.common.Location = `Bearer ${token}`;
// }
// 
// export function removeLocationToken() {
//   delete instance.defaults.headers.common.Location;
// }

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (status === 401) {
      useAuthStore.getState().clearUser();
      window.location.replace(urlUtils.login());
    } else {
      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

instance.interceptors.request.use(
  (config) => {
    const authToken = useAuthStore.getState().token;
    if (authToken != null) {
      config!.headers!.Authorization = `Bearer ${authToken}`;
    }

    const locToken = useAppStore.getState().confirmedToken;
    if (locToken != null) {
      config!.headers!.Location = `Bearer ${locToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
