import {
  AppRegistrationBody,
  GoogleRegistrationBody,
  UserAddressBody,
  UserRegistrationBody,
} from "api/user/types";
import { UserAddress } from "models/user";
import { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "yup-phone";
import FormField from "components/common/form/FormField";
import api from "api";
import SheetCard from "components/common/SheetCard";
import { REGISTRATION_TYPE } from "constants/auth";
import AddressFormBody from "components/user/AddressFormBody";
import { useAppStore } from "store/appStore";

type PropsType = {
  show: boolean;
  setShowValue: (val: boolean) => void;
  data: UserRegistrationBody | undefined;
  oauthData: any | undefined;
  type: REGISTRATION_TYPE;
  // data: UserAddress | undefined;
  // refreshData: () => void;
  // hideBackButton?: boolean;
};

export default ({
  show,
  setShowValue,
  data,
  type,
  oauthData,
}: // data,
  // refreshData,
  // hideBackButton = false,
  PropsType) => {

  const confirmedToken = useAppStore(state => state.confirmedToken);
  const confirmedLocation = useAppStore(state => state.confirmedLocation);
  const getInitFormData = (): UserRegistrationBody => {
    return {
      email: data?.email ?? "",

      name: data?.name ?? "",
      surname: data?.surname ?? "",
      phone: data?.phone ?? "",

      shippingAddress: {
        ...data?.shippingAddress,
        // TODO: Per il momento forzo l'inserimento dei dati della città selezionata, poi vediamo.
        city: confirmedLocation?.city,
        postalCode: confirmedLocation?.postalCode,
        province: confirmedLocation?.province,
        locationId: confirmedLocation?.id
      },
      billingAddress: {
        ...data?.billingAddress,
        // TODO: Per il momento forzo l'inserimento dei dati della città selezionata, poi vediamo.
        city: confirmedLocation?.city,
        postalCode: confirmedLocation?.postalCode,
        province: confirmedLocation?.province,
        locationId: confirmedLocation?.id
      },
    } as UserRegistrationBody;
  };
  //
  // const [isNew, setIsNew] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserRegistrationBody>(
    getInitFormData()
  );

  useEffect(() => {
    setFormData({ ...getInitFormData() });
  }, [data]);

  const [regError, setRegError] = useState<string>();

  const signInSchema = Yup.object().shape({
    sameAddressCheck: Yup.boolean(),
    email: Yup.string().required("Email richiesta"),

    name: Yup.string().required("Nome richiesto"),
    surname: Yup.string().required("Cognome richiesto"),
    // phone: Yup.string().required("Telefono richiesto"),
    shippingAddress: Yup.object().shape({
      address: Yup.string().required("Indirizzo richiesto"),
      city: Yup.string().required("Città richiesta"),
      province: Yup.string().required("Provincia richiesta"),
      postalCode: Yup.string().required("CAP richiesto"),
      phone: Yup.string() as any, //.phone("IT", "Telefono non valido"),
      //.required("Telefono richiesto")
    }),
    billingAddress: Yup.object()
      .when(["sameAddressCheck"], {
        is: (sameAddressCheck: boolean) => sameAddressCheck == false,
        then: (schema) =>
          schema.shape({
            address: Yup.string().required("Indirizzo richiesto"),
            city: Yup.string().required("Città richiesta"),
            province: Yup.string().required("Provincia richiesta"),
            postalCode: Yup.string().required("CAP richiesto"),
            phone: Yup.string() as any, //.phone("IT", "Telefono non valido"),
            //.required("Telefono richiesto")
          }),
      })
      .shape({}),
  });

  return (
    <Formik
      initialValues={{ ...formData, sameAddressCheck: false }}
      enableReinitialize
      validationSchema={signInSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        let newValues = {
          ...formData,
          ...values,
        };

        if (values.sameAddressCheck) {
          newValues.billingAddress = newValues.shippingAddress;
        }
        console.log("Registration form", newValues);

        let prom = null;
        switch (type) {
          case REGISTRATION_TYPE.GOOGLE: {
            prom = api.auth.googleRegistration({
              googleData: oauthData,
              user: newValues,
            } as GoogleRegistrationBody);
            break;
          }
          case REGISTRATION_TYPE.APP: {
            prom = api.auth.appRegistration({
              ...newValues,
              password: data?.password ?? '',
            });
            break;
          }
        }
        if (prom != null) {
          prom.then((res) => {
            // refreshData();
            setShowValue(false);
          }).catch((res) => {
            console.log("res", res, res?.response?.data?.message)
            // MEMO: Visualizzo sotto l'ultimo campo
            setRegError(res?.response?.data?.message ?? "La registrazione non è andata a buon fine.");
          });
        }
      }}
    >
      {(formik) => {
        const {
          errors,
          touched,
          isValid,
          isValidating,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        } = formik;

        if (isValidating && regError != null) {
          setRegError(undefined);
        }
        return (
          <Form>
            <SheetCard
              show={show}
              setShowValue={setShowValue}
              title="Indirizzo di spedizione"
              body={
                <div className="container gutter-md">
                  <div className="d-flex flex-column gap-4">
                    {/* <!-- fitler item - 1 --> */}
                    <div className="d-flex flex-column gap-3">
                      {/* <!-- Dati personali --> */}
                      <div
                        className="d-flex flex-column gap-custom gSpace-075"
                      /* style="--g-space: 0.75rem;" */
                      >
                        <h5>Dati personali</h5>

                        <div className="d-flex gap-2 flex-column">
                          <FormField
                            icon="driink-email"
                            name="email"
                            label="Email"
                            type="email"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={oauthData != null}
                          />

                          <FormField
                            name="name"
                            label="Nome"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <FormField
                            name="surname"
                            label="Cognome"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      {/* <!-- Indirizzo di spedizione --> */}
                      <div
                        className="d-flex flex-column gap-custom gSpace-075"
                      /* style="--g-space: 0.75rem;" */
                      >
                        <h5>Indirizzo di spedizione</h5>

                        <div className="d-flex gap-2 flex-column">
                          <AddressFormBody
                            prefix="shippingAddress"
                            hideDefaultCheck
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            locationFieldFromCB={true}
                            defaultLocation={confirmedLocation}
                          />
                        </div>
                      </div>

                      <div
                        className="registration-form-section d-flex flex-column gap-custom"
                      /* style="--g-space: 0.75rem;" */
                      >
                        {/* <!-- Indirizzo di fatturazione --> */}
                        <h5>Indirizzo di fatturazione</h5>

                        <div className="d-flex gap-2 flex-column">
                          <FormField
                            name="sameAddressCheck"
                            label="Coincide con l’indirizzo di spedizione"
                            type="checkbox"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {!values.sameAddressCheck && <AddressFormBody
                            prefix="billingAddress"
                            hideDefaultCheck
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={values.sameAddressCheck}
                          />}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              footer={
                <>{regError != null && (
                  <div className="mb-3">
                    <span className="error">{regError}</span>
                  </div>
                )}
                  <button
                    className="btn btn-primary btn-md btn-full"
                    disabled={!(dirty && isValid)}
                    onClick={() => handleSubmit()}
                  >
                    Registrati
                  </button>
                </>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};
