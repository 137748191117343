import StoreCartLayout from "components/layout/StoreCartLayout";
import LocationInput from "components/location/LocationInput";
import ShopItem from "components/shop/ShopItem";
import ShopItemList from "components/shop/ShopItemList";
import ShopFilterBtn from "components/shop/ShopFilterBtn";
import ShopMenu from "components/shop/ShopMenu";
import { ShopCategoryEnum } from "constants/shop";
import { useContext, useEffect, useState } from "react";
import api from "api";
import CartList from "components/cart/CartList";
import urlUtils from "utilities/urlUtils";
import { useShopStore } from "store/shopStore";

export default function HomePage() {
  const boxes = useShopStore(state => state.boxes);
  const setBoxes = useShopStore(state => state.setBoxes);

  useEffect(() => {
    if (boxes == null) {
      api.shop.getBoxes().then((res) => {
        setBoxes(res);
      });
    }
  }, []);

  const [type, setType] = useState<ShopCategoryEnum>(ShopCategoryEnum.WATER);
  return <>
    <StoreCartLayout
      containerClosed
      title="Il tuo pacchetto"
      showBack
      backLabel="Torna allo Shop"
      labelNextStep="Procedi"
      urlNextStep={urlUtils.cartSummary()}
    >
      <CartList showProductIcon={true} />
    </StoreCartLayout>
  </>;
}
