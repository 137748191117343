import api from "api";
import GenericLayout from "components/layout/GenericLayout"
import { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useCartStore } from "store/cartStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType, GaCartData } from "utilities/analyticsUtils/types";
import { getPublicImageUrl } from "utilities/imageUtils";
import urlUtils from "utilities/urlUtils";

export default () => {
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const cartdata = useCartStore(state => state.data);
  const clearCart = useCartStore(state => state.clearCart);

  useEffect(() => {
    const tkn = searchParams.get("token");
    const pId = searchParams.get("PayerID");
    if (id != null && tkn != null && pId != null) {
      api.orders.updatePay(id, tkn, pId).then(() => { });
    }

    const totalPriceWithoutDiscount = cartdata.reduce((total, item) => {
      return total + item.elem.priceWithoutDiscount;
    }, 0);
    const newGaCartData: GaCartData = {
      cartData: cartdata,
      cartTotal: totalPriceWithoutDiscount
    };
    analyticsUtils.sendEvent(AnalyticsActionCategory.NAVIGATION, AnalyticsEventActionType.CLICK, "checkout completed ko", newGaCartData);
    //spostato qui per poter tracciare il cart su GA
    clearCart();

  }, []);
  return <GenericLayout containerCustomClasses="">
    <div className="container">
      <div className="row flex-bottom-container gy-5 align-items-lg-center flex-row-reverse justify-content-between">
        <div className="col-12 col-lg-7">
          <div className="text-center">
            <img src={getPublicImageUrl("payment-error.svg")} className="img-fluid" width="100%" height="auto" />
          </div>
        </div>

        <div className="col-12 col-lg-4 flex-bottom-item">
          <div className="mb-3">
            <h1 className="mb-3">Noooooo!<br />Qualcosa è andato di traverso..</h1>
            <p>PayPal ha interrotto il collegamento con la nostra piattaforma e il pagamento non è
              andato a buon fine. Non ti preoccupare, ti assicuriamo che non è stato effettuato
              alcun
              addebito sul tuo conto.</p>
          </div>

          <div className="text-center text-lg-start">
            <Link to={urlUtils.paymentOrder(id)} className="btn btn-link btn-primary">
              Torna al carrello
            </Link>
          </div>
        </div>
      </div>
    </div>
  </GenericLayout>;
}
