import { Outlet, useNavigate } from "react-router-dom"
import LocationInput from "../location/LocationInput"
import Cart from "components/cart/Cart";
import CartBoxBuilderContainer from "components/cart/CartBoxBuilderContainer";
import urlUtils from "utilities/urlUtils";
import AppFooter from "./AppFooter";
import PaymentButtons from "components/cart/PaymentButtons";
import { useAppStore } from "store/appStore";
import { useEffect } from "react";
import { debounce } from "utilities/funcUtils";


type FullCartPropsType = {
    hideIfEmpty?: boolean;
    labelNextStep?: string;
    stickOnTop?: boolean;
    urlNextStep?: string;
    urlNextStepDisabled?: boolean;
    hideCart?: boolean;
    sideSlot?: React.ReactNode;
    showExpandCartRecup?: boolean;
};
const FullCart = ({ labelNextStep, stickOnTop, urlNextStep = urlUtils.cart(), urlNextStepDisabled = false, hideCart = false, sideSlot = null, showExpandCartRecup }: FullCartPropsType) => {
    return <div className={`box-cart ${stickOnTop ? 'sticky-top-desktop' : ''}`}>
        <CartBoxBuilderContainer />
        {hideCart ? null : <Cart labelNextStep={labelNextStep} urlNextStep={urlNextStep} urlNextStepDisabled={urlNextStepDisabled} showExpandCartRecup={showExpandCartRecup} />}
        {sideSlot}
    </div>
}

type PropsType = FullCartPropsType & {
    children: React.ReactNode;
    containerClosed?: boolean;
    showLocation?: boolean;
    title?: string;
    subtitle?: string;
    showBack?: boolean;
    backUrl?: string;
    backLabel?: string;
    hideCart?: boolean;
    sideSlot?: React.ReactNode;
    showExpandCartRecup?: boolean
};
export default ({ children, containerClosed = false, showLocation = true, title, subtitle, showBack, backUrl, backLabel, ...rest }: PropsType) => {
    const navigate = useNavigate();
    const handleBackButtonPress = () => {
        if (backUrl != null && backUrl != "") {
            navigate(backUrl);
        }
        else {
            navigate(-1);
        }
    }

    // --------------------------------------------------------------------------------------------------------
    // MEMO: Gestione dello scroll in basso, probabilmente ha senso renderlo più generico o riutilizzabile
    // Sembra che a volte cambi contesto, giro non bellissimo ma sembra abbastanza ok
    // --------------------------------------------------------------------------------------------------------
    //
    const isBottom = (el: HTMLElement) => {
        // console.log("isBottom", el.getBoundingClientRect().bottom, window.innerHeight);
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    const { changeScroll } = useAppStore();
    const trackScrolling = debounce(() => {
        // console.log("trackScrolling");
        // console.log("trackScrolling 2");
        const idElem = "main-container"
        const wrappedElement = document.getElementById(idElem);
        if (wrappedElement == null) {
            console.warn(`Element with id ${idElem} not found`)
            document.removeEventListener('scroll', trackScrolling);
        } else {
            // console.log("trackScrolling 3");
            changeScroll(isBottom(wrappedElement));
        }
    });

    const idElem1 = "wrapper-container-1"
    const idElem2 = "wrapper-container-2"
    const addScrollEventListeners = () => {
        const elem1 = document.getElementById(idElem1);
        const elem2 = document.getElementById(idElem2);
        if (elem1 == null) {
            console.warn(`Element with id ${idElem1} not found`)
        }
        if (elem2 == null) {
            console.warn(`Element with id ${idElem2} not found`)
        }
        elem1?.addEventListener('scroll', trackScrolling);
        elem2?.addEventListener('scroll', trackScrolling);
    }

    const removeScrollEventListeners = () => {
        const elem1 = document.getElementById(idElem1);
        const elem2 = document.getElementById(idElem2);
        if (elem1 == null) {
            console.warn(`Element with id ${idElem1} not found`)
        }
        if (elem2 == null) {
            console.warn(`Element with id ${idElem2} not found`)
        }
        elem1?.removeEventListener('scroll', trackScrolling);
        elem2?.removeEventListener('scroll', trackScrolling);
    }
    useEffect(() => {
        addScrollEventListeners();
        return () => {
            removeScrollEventListeners();
        }
    }, []);
    //
    // --------------------------------------------------------------------------------------------------------
    return <div id="wrapper-container-1" className="flex-grow-1 d-flex flex-column overflow-auto">
        {/* <div className="flex-grow-1"> */}
        <div id="wrapper-container-2" className="flex-grow-1 overflow-auto d-flex flex-column">
            <div id="main-container" className="main-container flex-grow-1">
                {showLocation ? <LocationInput containerClosed={containerClosed} /> : null}
                <div className={`container ${containerClosed ? 'container-close' : ''}`}>
                    <div className="row">
                        {showBack || title != null && title != "" ? <div className="col-12 mb-4">

                            <div className="arrow-back">
                                {showBack ? <>
                                    <a onClick={handleBackButtonPress} className="btn btn-action p-0">
                                        <span className="material-symbols-outlined">arrow_back</span>
                                    </a>
                                    <a onClick={handleBackButtonPress} className="btn btn-action p-0" style={{ display: "flex" }}>
                                        <span className="arrow-back__back-label">{backLabel}</span>
                                    </a>
                                </> : null}
                                <h3 className="arrow-back__title">{title}</h3>
                                {subtitle != null ? <div className="arrow-back__subtitle">
                                    <p>{subtitle}</p>
                                </div> : null}
                            </div>

                        </div> : null}
                        <div className={`col-12 col-lg-8 ${containerClosed ? '' : 'col-xl-9'}`}>
                            {children}
                        </div>

                        <div className={`d-none d-lg-block col-12 col-lg-4 ${containerClosed ? '' : 'col-xl-3'}`}>
                            <FullCart {...rest} stickOnTop />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-lg-block">
                <AppFooter />
            </div>
        </div>
        <div className="store-footer d-block d-lg-none position-relative">
            <div className="d-block d-lg-none box-cart-upper-shadow"></div>
            <FullCart {...rest} hideIfEmpty />
        </div>
    </div>
}