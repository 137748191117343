import {
  BoxCartElement,
  CartType,
  ICartElement,
  SimpleCartElement,
} from "models/cart";
import client from "../client";
import {
  OrderBodyType,
  OrderCartBoxBodyType,
  OrderCartItemDetailBodyType,
  PayPalPaymentType,
} from "./types";
import { PRODUCT_ENUM } from "constants/common";
import { convertToDateStringForAPI } from "utilities/convertUtils";
import { UserOrderCmp } from "models/user";
import urlUtils from "utilities/urlUtils";
import settings from "settings";
import { CouponType } from "models/coupon";

export function add(
  timeSlotId: string,
  timeSlotDay: number,
  timeSlotMonth: number,
  timeSlotYear: number,
  shippingAddressId: string,
  billingAddressId: string,
  shippingMethodId: string,
  type: CartType | undefined,
  ua: ICartElement[],
  coupon: CouponType | undefined
): Promise<UserOrderCmp> {
  const boxChartElems = ua
    .filter((o) => o.type === PRODUCT_ENUM.BOX)
    .map((o) => o as BoxCartElement);
  const simpleChartElems = ua
    .filter((o) => o.type === PRODUCT_ENUM.SIMPLE)
    .map((o) => o as SimpleCartElement);
  console.log("add orders", ua);
  const body: OrderBodyType = {
    timeSlotId: timeSlotId,
    timeSlotDate: convertToDateStringForAPI(
      new Date(timeSlotYear, timeSlotMonth - 1, timeSlotDay)
    ),
    shippingAddressId: shippingAddressId,
    billingAddressId: billingAddressId,
    shippingMethodId: shippingMethodId,
    isRecurring: type === CartType.RECURRING_DELIVERY,
    couponId: coupon?.id,
    order: {
      itemDetails: simpleChartElems.map(
        (item) =>
        ({
          itemDetailId: item.elem.id,
          count: item.count,
        } as OrderCartItemDetailBodyType)
      ),
      boxes: boxChartElems.map(
        (boxElem) =>
        ({
          boxId: boxElem.box.id,
          count: boxElem.count,
          itemDetails: boxElem.elems.map(
            (item) =>
            ({
              itemDetailId: item.id,
              count: 1,
            } as OrderCartItemDetailBodyType)
          ),
        } as OrderCartBoxBodyType)
      ),
    },
  };
  return client.post("orders", body).then((res) => res.data);
}

export function checkCouponCode(
  code: string,
  timeSlotId: string,
  timeSlotDay: number,
  timeSlotMonth: number,
  timeSlotYear: number,
  shippingAddressId: string,
  billingAddressId: string,
  shippingMethodId: string,
  type: CartType | undefined,
  ua: ICartElement[]
): Promise<CouponType> {

  // TODO: Unire costruzione body con quella dell'add order!
  const boxChartElems = ua
    .filter((o) => o.type === PRODUCT_ENUM.BOX)
    .map((o) => o as BoxCartElement);
  const simpleChartElems = ua
    .filter((o) => o.type === PRODUCT_ENUM.SIMPLE)
    .map((o) => o as SimpleCartElement);
  console.log("add orders", ua);
  const body: OrderBodyType = {
    timeSlotId: timeSlotId,
    timeSlotDate: convertToDateStringForAPI(
      new Date(timeSlotYear, timeSlotMonth - 1, timeSlotDay)
    ),
    shippingAddressId: shippingAddressId,
    billingAddressId: billingAddressId,
    shippingMethodId: shippingMethodId,
    isRecurring: type === CartType.RECURRING_DELIVERY,
    couponId: undefined,
    order: {
      itemDetails: simpleChartElems.map(
        (item) =>
        ({
          itemDetailId: item.elem.id,
          count: item.count,
        } as OrderCartItemDetailBodyType)
      ),
      boxes: boxChartElems.map(
        (boxElem) =>
        ({
          boxId: boxElem.box.id,
          count: boxElem.count,
          itemDetails: boxElem.elems.map(
            (item) =>
            ({
              itemDetailId: item.id,
              count: 1,
            } as OrderCartItemDetailBodyType)
          ),
        } as OrderCartBoxBodyType)
      ),
    },
  };
  return client.post(`orders/check-coupon/${code}`, body).then((res) => res.data);
}

export function pay(orderId: string): Promise<PayPalPaymentType> {
  return client
    .post(`orders/${orderId}/pay`, {
      returnUrl: `${settings.appBaseUrl}${urlUtils.paymentOk(orderId)}`,
      cancelUrl: `${settings.appBaseUrl}${urlUtils.paymentKo(orderId)}`,
    })
    .then((res) => res.data);
}

export function updatePay(
  orderId: string,
  token: string,
  payerID: string
): Promise<any> {
  return client.put(`orders/${orderId}/pay`, null, {
    params: {
      token,
      payerID,
    },
  });
}

export function updateSubscriptionPay(
  subscriptionId: string,
  ppSubscriptionId: string,
  baToken: string,
  token: string
): Promise<any> {
  return client.put(`subscriptions/${subscriptionId}/pay`, null, {
    params: {
      ppSubscriptionId,
      baToken,
      token,
    },
  });
}