import api from "api";
import { ShopItemType } from "api/shop/types";
import SheetCard from "components/common/SheetCard";
import { getItemImage, getPublicImageUrl } from "utilities/imageUtils";

type PropsType = {
    data: ShopItemType;
    show: boolean;
    setShowValue: (val: boolean) => void;
};

export default ({ data, show, setShowValue }: PropsType) => {
    return (
        <SheetCard
            show={show}
            setShowValue={setShowValue}
            title="Dettagli prodotto"
            imgUrl={data.image != null ? getItemImage(data.image) : undefined}
            bodyTitle={
                <div className="container gutter-md">
                    <div className="d-flex flex-column">
                        <small>{data.brand}</small>
                        <div className="d-flex align-items-center gap-custom gSpace-0625">
                            <span className="h2">{data.name}</span>
                            {/* MEMO: Bottone favorite
                            <button className="btn btn-action p-0">
                                <span className="material-symbols-outlined"> favorite </span>
                            </button> 
                            */}
                        </div>

                        <span className="h6 fw-normal">{data.info}</span>
                    </div>


                </div>
            }
            body={
                <div className="container gutter-md">
                    <p>{data.description}</p>
                </div>
            }
        />
    );
};
