import { Paginated } from "models/common";
import client from "../client";
import { LocationCheckResponseVM, LocationPagingQueryType } from "./types";
import { LocationType } from "models/location";

// export function checkCap(cap: string): Promise<LocationCheckResponseVM> {
//   return client
//     .post(`location/check/${cap}`)
//     .then((res) => res.data as LocationCheckResponseVM);
// }

export function checkLocationByLocationKey(locationKey: string): Promise<LocationCheckResponseVM> {
  return client
    .post(`location/check/${locationKey}`)
    .then((res) => res.data as LocationCheckResponseVM);
}

export function get(
  qry: LocationPagingQueryType
): Promise<Paginated<LocationType>> {
  return client
    .post(`location/get`, {
      page: qry.page,
      pageSize: qry.pageSize,
      sortBy: qry.sortBy,
      sortDirection: qry.sortDirection,
      getAll: qry.getAll,
      search: qry.search
    })
    .then((res) => res.data);
}

export function notification(email: string, postalCode: string): Promise<LocationCheckResponseVM> {
  return client
    .post(`location/notification`, {
      email: email,
      postalCode: postalCode
    })
    .then((res) => res.data as LocationCheckResponseVM);
}
