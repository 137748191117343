import { useState, useContext } from "react";
import { randomIntFromInterval } from "utilities/generateUtils";
import { ShopItemDetailType } from "api/shop/types";
import { BoxCartElement } from "models/cart";
import { getGroupedByData } from "utilities/shopUtils";
import { useShopStore } from "store/shopStore";
import { useCartStore } from "store/cartStore";

type PropsType = {
    data: BoxCartElement;
    active: boolean;
    onExpandCollapgeToggle: () => void;
};
export default ({ data, active, onExpandCollapgeToggle }: PropsType) => {

    const addToCart = useCartStore(state => state.addToCart);
    const removeToCart = useCartStore(state => state.removeToCart);
    const confirmBox = useCartStore(state => state.confirmBox);

    const boxes = useShopStore(state => state.boxes);
    const expandedBoxKey = useShopStore(state => state.expandedBoxKey);

    const handleAddToCart = (elem: ShopItemDetailType) => {
        let selectedBox = null;
        if (elem.enabledBoxes != null && elem.enabledBoxes.length > 0) {
            const selBoxId = elem.enabledBoxes[0];
            // TODO: Gestire casi più box abilitati
            selectedBox = boxes?.find((b) => b.id == selBoxId);
        }
        addToCart({ item: elem, selectedBox: selectedBox, boxKey: data.key });
    };

    const handleRemoveToCart = (elem: ShopItemDetailType) => {
        removeToCart({ item: elem, boxKey: data.key });
    };

    const handleConfirmBox = () => {
        confirmBox({ boxKey: data.key });
    };

    let isActive = active;

    isActive = data.key === expandedBoxKey ? true : false;

    return (
        <div className={`custom-box ${isActive ? 'active' : ''}`} id={`${data.key}`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="text-secondary d-flex align-items-center gap-custom flex-lg-fill w-100 gSpace-0625">
                    <i className="driink-box"></i>

                    <div className="d-flex align-items-center gap-2 flex-lg-fill justify-content-lg-between">
                        <p>
                            <span className="fw-extrabold">{data.elems.length} di {data.elemMaxCount}</span>
                            <span className="fw-bold">{' '}selezionate</span>
                        </p>
                        <a
                            data-bs-toggle="collapse"
                            href={`#accordionTarget-${data.key}`}
                            role="button"
                            aria-expanded={isActive ? "true" : "false"}
                            aria-controls="collapse-2"
                            className="btn btn-action p-0 custom-box-collapse"
                            onClick={() => onExpandCollapgeToggle()}
                        >
                            <i className="material-symbols-outlined"> expand_less </i>
                        </a>
                    </div>
                </div>
                <div className="d-lg-none">

                    <button className="btn btn-sm btn-primary"
                        onClick={() => handleConfirmBox()}
                        disabled={data.elems.length !== data.elemMaxCount}
                    >
                        Aggiungi
                    </button>

                </div>
            </div>
            <div
                className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
                id={`accordionTarget-${data.key}`}
                data-bs-parent="#accordionCustomBox"
            >
                <div className="d-flex flex-column gap-2 mt-custom mtSpace-18125">
                    {getGroupedByData(data.elems).map((o) => (
                        <div key={`${data.key}-${o.elem.id}`} className="d-flex align-items-center justify-content-between gap-1">
                            <div className="d-flex flex-column text-secondary">
                                <small>{o.elem.brand}</small>
                                <p className="fw-extrabold">{o.elem.name}</p>
                            </div>

                            <div className="number-selector">
                                <button
                                    className="btn btn-action"
                                    onClick={() => handleRemoveToCart(o.elem)}
                                    disabled={data.elems.length == 0}
                                >
                                    <span className="material-symbols-outlined text-primary">
                                        do_not_disturb_on
                                    </span>
                                </button>
                                <span className="number-selector-items">{o.count}</span>
                                <button
                                    className="btn btn-action"
                                    onClick={() => handleAddToCart(o.elem)}
                                    disabled={data.elems.length >= data.elemMaxCount}
                                >
                                    <span className="material-symbols-outlined text-primary">add_circle</span>
                                </button>
                            </div>
                        </div>
                    ))}

                    <div className="d-none d-lg-block mt-3">
                        <button
                            className="btn btn-primary btn-md btn-full"
                            onClick={() => handleConfirmBox()}
                            disabled={data.elems.length !== data.elemMaxCount}
                        >
                            Aggiungi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
