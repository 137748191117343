import { WeekDays } from 'constants/common';
import { LocationType } from 'models/location';
import { TimeSlotWithDateType } from 'models/timeslots';
import moment from 'moment';

export function convertToCurrency(val: number) {
  return `${val.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€`;
}

export function convertToCurrencyOrPerc(val: number, isAbsolute: boolean) {
  return isAbsolute ? convertToCurrency(val) : `${val}%`;
}

export function convertToLocationString(
  val: LocationType | undefined | null,
  showPostalCode: boolean,
) {
  if (val == null) {
    return '-';
  }
  let str = `${val.city}, ${val.province?.toUpperCase()}`;
  if (showPostalCode) {
    str += ` (${val.postalCode})`;
  }
  return str;
}

export function convertToDateString(val: Date | string, addWeekDay: boolean = false) {
  const dt = new Date(val);
  let mm = dt.getMonth() + 1; // getMonth() is zero-based
  let dd = dt.getDate();

  let strDt = [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    dt.getFullYear(),
  ].join('/');

  if (addWeekDay) {
    strDt = WeekDays[moment(dt).weekday() - 1].label + " " + strDt;
  }
  return strDt;
}

export function convertToDateStringForAPI(val: Date | string) {
  const dt = new Date(val);
  let mm = dt.getMonth() + 1; // getMonth() is zero-based
  let dd = dt.getDate();

  return [
    dt.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-');
}

export function getDayOfWeek(val: TimeSlotWithDateType | undefined) {
  if (val == null) {
    return null;
  }
  let mm = moment(new Date(val.year, val.month - 1, val.day));
  return WeekDays[mm.weekday() - 1].label;
}
