import PaymentButtons from "components/cart/PaymentButtons";
import StoreCartLayout from "components/layout/StoreCartLayout";
import { useEffect, useMemo, useState, useContext } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import GenericLayout from "components/layout/GenericLayout";
import { convertToCurrency, convertToCurrencyOrPerc } from "utilities/convertUtils";
import CouponCodeInput from "components/cart/CouponCodeInput";
import { TimeSlotWithDateType } from "models/timeslots";
import { UserAddress } from "models/user";
import { CartType, ICartElement } from "models/cart";
import { ShippingMethod } from "models/shippingMethods";
import { CouponType } from "models/coupon";
import { convertUserCartOrderBoxToICartElement, convertUserCartOrderItemDetailToICartElement, useCartStore } from "store/cartStore";
import { CartSummaryType } from "models/summary";
import { COUPON_DISCOUNT_PROPERTY } from "constants/coupons";
import PaymentPageCmp from "components/cart/PaymentPageCmp";

const SummaryVoiceCmp = ({ label, value }: { label: string, value: any | undefined }) => {
  return <div className="d-flex align-items-center justify-content-between">
    <p className="mb-0">{label}</p>
    <p className="mb-0">{value}</p>
  </div>
}

export default () => {
  let { id } = useParams();


  const clearCart = useCartStore(state => state.clearCart);

  const navigate = useNavigate();

  const [orderId, setOrderId] = useState<string | undefined>();
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  const [orderCartTotal, setOrderCartTotal] = useState<CartSummaryType | undefined>();

  useEffect(() => {
    setProcessingPayment(false);
    setOrderId(id);

    if (id != null) {
      api.user.getOrderDetailForCart(id).then(res => {
        var boxes = convertUserCartOrderBoxToICartElement(res.boxes);
        var itemDetails = convertUserCartOrderItemDetailToICartElement(res.itemDetails);
        api.summary.get(
          [...boxes, ...itemDetails],
          res.isLinkedToSubscription ? CartType.RECURRING_DELIVERY : CartType.SINGLE_DELIVERY,
          res.timeSlotDiscount,
          res.timeSlotDiscountAbsolute,
          res.shippingMethodPrice,
          res.couponCode,
          res.couponDiscounts
        ).then(res => {
          setOrderCartTotal(res);
        })
      })
    }
  }, [id]);

  const onPayPalClick = async () => {
    if (orderId != null) {
      payOrder(orderId);
    }
  };

  const payOrder = (orderId: string) => {
    if (orderId != null) {
      api.orders.pay(orderId).then((res) => {
        // navigate(res.href);

        // MEMO: Per il momento cancello la pagina per non mostrare il carrello vuoto.
        setProcessingPayment(true);
        /* clearCart(); */
        window.location.href = res.href;
      });
    }
  }

  return <PaymentPageCmp
    processingPayment={processingPayment}
    canBePayed={true}
    onPayPalClick={() => onPayPalClick()}
    cartTotal={orderCartTotal}
    couponCanBeAdded={false}
    couponCode={orderCartTotal?.couponCode}
    couponDiscounts={orderCartTotal?.couponDiscounts}
  />;
}
