import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "store/authStore";
import { useCartStore } from "store/cartStore";
import { getPublicImageUrl } from "utilities/imageUtils";
import urlUtils from "utilities/urlUtils";
import settings from "../../settings";
import { useAppStore } from "store/appStore";
import Cookies from 'js-cookie';
import LocationModal from "components/location/LocationModal";

export default function AppHeader() {
    const user = useAuthStore(state => state.user);


    const data = useCartStore(state => state.data);
    const confirmedLocation = useAppStore(state => state.confirmedLocation);
    const addCartFromOrder = useCartStore(state => state.addCartFromOrder);

    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false);
    const headerRef = useRef<HTMLElement>(null);

    const userIsLogged = user != null && user.id != null;
    //   useEffect(() => {
    //       clickOnMenuItem();
    //   }, [])

    useLayoutEffect(() => {
        document.body.style.setProperty('--header-height', `${headerRef?.current?.clientHeight ?? 0}px`);
    }, [headerRef])

    const onBurgerClick = () => {

        if (document.body.classList.contains('header-open')) {
            document.body.classList.remove('header-open');
            setMenuOpened(false);
        } else {
            document.body.classList.add('header-open');
            setMenuOpened(true);
        }
    }

    const clickOnMenuItem = () => {
        document.body.classList.remove('header-open');
        setMenuOpened(false);
    }


    const [locationModalVis, setLocationModalVis] = useState(false);
    const showLocationModal = () => {
        setLocationModalVis(true);
    }

    useEffect(() => {
        const countMoreThanOne = data.length;
        // document.cookie = `cart=${countMoreThanOne > 0 ? 'full' : ''};domain=${settings.domain}`
        Cookies.set('cart', countMoreThanOne > 0 ? 'full' : '', { domain: settings.domain });
    }, [data.length])

    useEffect(() => {
        // document.cookie = `locationKey=${confirmedLocation?.key ?? ''};domain=${settings.domain}`
        // document.cookie = `locationName=${confirmedLocation?.name ?? ''};domain=${settings.domain}`
        Cookies.set('locationKey', confirmedLocation?.key ?? '', { domain: settings.domain });
        Cookies.set('locationCity', confirmedLocation?.city ?? '', { domain: settings.domain });
        Cookies.set('locationPostalCode', confirmedLocation?.postalCode ?? '', { domain: settings.domain });
        Cookies.set('locationProvince', confirmedLocation?.province ?? '', { domain: settings.domain });
    }, [confirmedLocation?.id])

    useEffect(() => {
        // document.cookie = `userId=${user?.id ?? ''};domain=${settings.domain}`
        // document.cookie = `userName=${user?.name ?? ''};domain=${settings.domain}`
        // document.cookie = `userSurname=${user?.surname ?? ''};domain=${settings.domain}`
        Cookies.set('userId', user?.id ?? '', { domain: settings.domain });
        Cookies.set('userName', user?.name ?? '', { domain: settings.domain });
        Cookies.set('userSurname', user?.surname ?? '', { domain: settings.domain });
    }, [user?.id])

    return (
        <>
            <header ref={headerRef} className="header">
                <div className="container">
                    <div className="header-container">
                        <img
                            src={getPublicImageUrl(`driink-logo.svg`)} className="header-brand img-fluid" width="129px" height="auto" onClick={() => window.location.assign(urlUtils.public.main())} />

                        <div className="menu menu--right menu-collapse-lg align-items-lg-center">
                            <ul className="menu-nav">
                                <li className="menu-item">
                                    <a className="menu-link" href={urlUtils.public.comeFunziona()} onClick={() => clickOnMenuItem()}>Come funziona</a>
                                </li>
                                <li className="menu-item">
                                    <a className="menu-link" href={urlUtils.public.etichetta()} onClick={() => clickOnMenuItem()}>Etichetta</a>
                                </li>
                                <li className="menu-item">
                                    <a className="menu-link" href={urlUtils.public.diventaUnDriinker()} onClick={() => clickOnMenuItem()}>Diventa un Driinker</a>
                                </li>
                                <li className="menu-item">
                                    {confirmedLocation ? <Link className="btn btn-primary" to={urlUtils.main()} onClick={() => clickOnMenuItem()}>Shop</Link> :
                                        <button className="btn btn-primary" onClick={() => {
                                            clickOnMenuItem();
                                            showLocationModal();
                                        }}>Shop</button>}
                                </li>
                            </ul>

                            {user != null && user.id != null ? <button className="btn btn-default" onClick={() => navigate(urlUtils.user.main())}>
                                <div className="avatar">
                                    <span>{user.name?.charAt(0).toUpperCase()}</span>
                                </div>
                            </button> : <a href="#" className="item-center" onClick={() => navigate(urlUtils.user.main())}>
                                <span className="driink-account "></span>
                            </a>}

                            <a
                                onClick={() => navigate(urlUtils.cart())}
                                className="item-center"
                            >
                                <span
                                    className={`driink-cart filter filter-small ${data.length > 0 ? "filter-active" : ""
                                        }`}
                                ></span>
                            </a>

                            <button className={`hamburger hamburger--squeeze ${menuOpened ? 'is-active' : ''}`} type="button" onClick={onBurgerClick}>
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            {confirmedLocation == null ? <LocationModal show={locationModalVis} setShow={(val) => setLocationModalVis(val)} /> : null}
        </>
    );
}
