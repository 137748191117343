import { AxiosError, AxiosResponse } from "axios";
import client from "../client";
import { LocationCheckResponseVM } from "./types";
import { PassThrough } from "stream";

export function getBrandImage(id: string): Promise<string | undefined> {
  return client
    .get(`images/brand/${id}`, { responseType: 'blob' })
    .then(manageResponse)
    .catch(manageError)
    ;
}

export function getItemImage(id: string): Promise<string | undefined> {
  return client
    .get(`images/item/${id}`, { responseType: 'blob' })
    .then(manageResponse)
    .catch(manageError)
    ;
}
export function getItemmageForInfo(id: string): Promise<string | undefined> {
  return client
    .get(`images/item/${id}/info`, { responseType: 'blob' })
    .then(manageResponse)
    .catch(manageError)
    ;
}
export function getItemDetailImage(id: string): Promise<string | undefined> {
  return client
    .get(`images/itemdetail/${id}`, { responseType: 'blob' })
    .then(manageResponse)
    .catch(manageError)
    ;
}

const manageResponse = async (response: AxiosResponse<any, any>) => {
  if (response.data == null) {
    return undefined;
  }
  let blob = new Blob(
    [response.data],
    { type: response.headers['content-type'] }
  )
  let image = window.URL.createObjectURL(blob)
  return image
}
const manageError = async (response: AxiosError<any, any>) => {
  return undefined;
}