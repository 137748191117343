import { useContext, useRef, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import FormField from "components/common/form/FormField";
import api from "api";

type PropsType = {
    token: string;
    onPasswordChanged: () => void
};

export default ({ token, onPasswordChanged }: PropsType) => {
    const getInitFormData = () => {
        return {
            password: "",
            newPassword: ""
        };
    };


    const [regFormData, setRefFormData] = useState(
        getInitFormData()
    );

    const regFormSchema = Yup.object().shape({
        password: Yup.string().required("Nuova password richiesta")
            .min(8, "La password deve avere 8 caratteri")
            .matches(/[0-9]/, "La password deve avere almeno un numero")
            .matches(/[a-zA-Z]/, "La password deve avere almeno una lettera")
            .matches(/[^\w\s]/, "La password deve avere almeno un carattere speciale"),
        newPassword: Yup.string().oneOf([Yup.ref('password')], 'Le password devono coincidere')
        // phone: Yup.string().required("Telefono richiesto"),
    });

    return (
        <>
            <Formik
                initialValues={regFormData}
                enableReinitialize
                validationSchema={regFormSchema}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    console.log("Register clicked!");
                    api.auth.resetPassword(token, values.password).then(() => {
                        onPasswordChanged();
                    }).catch(() => {
                        setFieldError('password', "Il cambio password non è andato a buon fine.")
                    }).finally(() => setSubmitting(false));
                }}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        isValid,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    } = formik;
                    return (

                        < Form onSubmit={(e) => {
                            e.preventDefault();
                            // handleSubmit();
                        }}>
                            <div className="d-flex flex-column gap-2 gap-lg-3">
                                <FormField
                                    name="password"
                                    label="Nuova password"
                                    type="password"
                                    errors={errors}
                                    touched={touched}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="d-flex gap-2 mt-1 justify-content-between align-items-center">
                                    <label>La password deve essere composta da almeno 8 caratteri, un carattere speciale e un numero.</label>
                                </div>

                                <FormField
                                    name="newPassword"
                                    label="Conferma nuova password"
                                    type="password"
                                    errors={errors}
                                    touched={touched}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <button className="btn btn-primary" disabled={!(dirty && isValid)} onClick={() => handleSubmit()}>
                                    Cambia password
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
