import { ErrorMessage, Field } from "formik";

type FieldWrapperPropsType = {
  hideWrapper?: boolean;
  icon?: string;
  wrapperClass?: string;
  children: React.ReactNode;
};

const FieldWrapper = ({
  icon,
  hideWrapper,
  wrapperClass = "form-floating",
  children,
}: FieldWrapperPropsType) => {
  if (hideWrapper) {
    return <>{children}</>;
  }
  return (
    <div className={`${wrapperClass} ${icon != null ? "form-icon" : ""}`}>
      {children}
    </div>
  );
};

type PropsType = {
  hideWrapper?: boolean;
  icon?: string;
  name: any;
  label: any;
  type?: string;
  errors?: any;
  touched?: any;
  onChange?: any;
  onBlur?: any;
  disabled?: boolean;
};

export default ({
  hideWrapper,
  icon,
  name,
  label,
  type = "text",
  errors,
  touched,
  onChange,
  onBlur,
  disabled = false,
}: PropsType) => {
  if (type == "checkbox") {
    return (
      <FieldWrapper
        wrapperClass="form-check"
        hideWrapper={hideWrapper}
        icon={icon}
      >
        <Field
          className="form-check-input"
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
        <ErrorMessage name={name} component="span" className="error" />
      </FieldWrapper>
    );
  }

  const getTypeParam = () => {
    if (type === "textarea") {
      return "text";
    }
    return type;
  };
  const getAsParam = () => {
    if (type === "textarea") {
      return "textarea";
    }
    return undefined;
  };
  return (
    <FieldWrapper hideWrapper={hideWrapper} icon={icon}>
      <Field
        type={getTypeParam()}
        as={getAsParam()}
        name={name}
        id={name}
        className={`form-control ${errors[name] && touched[name] ? "input-error" : null
          }`}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={label}
      />

      <label htmlFor={name}>{label}</label>
      {icon != null ? (
        <div /* for="input-id" */ className="icon">
          <i className={icon}></i>
        </div>
      ) : null}

      <ErrorMessage name={name} component="span" className="error" />
    </FieldWrapper>
  );
};
