import { useEffect, useState } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import { getItemImage } from "utilities/imageUtils";

type PropsType = {
    imageUrl?: string
    defaultImgUrl: string;
    className?: string;
    width?: string;
    height?: string;

}

export default ({ imageUrl, defaultImgUrl, className, width, height }: PropsType) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(imageUrl);

    useEffect(() => {
        if (imageSrc != null) {
            setImageSrc(imageSrc); // Reimposta il percorso dell'immagine principale all'inizio di ogni render
        } else {
            handleImageError();
        }
    }, [imageSrc]);

    const handleImageError = () => {
        // Se si verifica un errore nel caricamento dell'immagine principale,
        // imposta il percorso dell'immagine sull'immagine alternativa.
        setImageSrc(getPublicImageUrl(defaultImgUrl));
    };

    return <picture>
        <img src={imageSrc} onError={handleImageError} className={className} width={width} height={height} />
    </picture>
}
