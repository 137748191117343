import api from "api";
import { ShopItemType } from "api/shop/types";
import Image from "components/common/Image";
import { ShopCategoryEnum } from "constants/shop";
import { useState } from "react";
import ShopItemSub from "./ShopItemSub";
import ShopItemInfo from "./ShopItemInfo";
import { getItemImage } from "utilities/imageUtils";
import { getBrandImage } from "utilities/imageUtils";

type PropsType = {
    type: ShopCategoryEnum;
    data: ShopItemType;
};

export default ({ type, data }: PropsType) => {
    const [showInfo, setShowInfo] = useState(false);
    return <div className="shadow-box">
        <div className="d-flex flex-column gap-3">

            <div className="box-item-head">
                <div className="d-inline-flex align-items-center gap-2 text-secondary">
                    <h4>{data.name}</h4>

                    <button className="btn btn-action p-0 bottle-info" type="button" data-sheet-trigger="target-1">
                        <span
                            className="material-symbols-outlined"
                            onClick={() => setShowInfo(true)}
                        > info </span>
                    </button>


                    {data.var && <img src="/img/leaf.svg" width="24px" height="24px" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Vuoto a rendere" data-bs-offset="[40, 2]" />}

                </div>

                <div className="brand-image">
                    <Image

                        defaultImgUrl={`brand/brand-placeholder.png`}
                        imageUrl={getBrandImage(data.brandImageId)}
                    />
                </div>
            </div>



            <div className="box-item-preview">
                <Image
                    defaultImgUrl={`product/product-placeholder.png`}
                    className="object-fit-cover"
                    width="100%"
                    height="130px"
                    imageUrl={data.image != null ? getItemImage(data.image) : undefined}
                />
            </div>


            <div className="d-flex flex-column gap-3">

                {data.itemDetails.map((elm, idx) => (
                    <>
                        {idx > 0 ? <hr className="my-0 d-none d-lg-block" /> : null}
                        <ShopItemSub data={{ ...elm, type: type }} />
                    </>
                ))}
            </div>
        </div>
        <ShopItemInfo data={data} show={showInfo} setShowValue={setShowInfo} />
    </div>
}