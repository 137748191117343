import { LabelType } from "./common";

export enum ORDER_STATUS_ENUM {
  UNKNOWN = "unknown",
  DRAFT = "draft",
  PENDING = "pending",
  PAID = "paid",
  NOT_PAID = "not-paid",
  DELIVERING = "delivering",
  DELIVERED = "delivered",
}

export const ORDER_STATUS_LABEL: LabelType<ORDER_STATUS_ENUM>[] = [
  {
    value: ORDER_STATUS_ENUM.UNKNOWN,
    label: "",
  },
  {
    value: ORDER_STATUS_ENUM.DRAFT,
    label: "In elaborazione",
  },
  {
    value: ORDER_STATUS_ENUM.PENDING,
    label: "Da pagare",
  },
  {
    value: ORDER_STATUS_ENUM.PAID,
    label: "Pagato",
  },
  {
    value: ORDER_STATUS_ENUM.NOT_PAID,
    label: "Pagamento fallito",
  },
  {
    value: ORDER_STATUS_ENUM.DELIVERING,
    label: "In consegna",
  },
  {
    value: ORDER_STATUS_ENUM.DELIVERED,
    label: "Consegnato",
  },
];
