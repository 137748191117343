import { UserAddress } from "models/user";

type PropsType = {
  data: UserAddress;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export default ({ data, onEditClick, onDeleteClick }: PropsType) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex flex-column gap-1">
          <div className="d-flex align-items-center gap-1 text-primary">
            <span className="fw-extrabold lh-1">{[data.name, data.surname].join(" ")}</span>


            {data.isDefault ? <span className="material-symbols-outlined fill-1">
              star
            </span> : null}

          </div>

          <span className="h6 fw-normal">{data.address}</span>
          <span className="h6 fw-normal">
            {[data.postalCode, data.city, `(${data.province})`].join(" ")}
          </span>

          <span className="h6 fw-normal">Numero di telefono: {data.phone}</span>
        </div>

        {/* <a className="item-center" onClick={() => onEditClick()}>
          <span className="material-symbols-outlined  fill-1">edit</span>
        </a> */}
      </div>
      <div className="d-flex align-items-center gap-2">
        <button className="btn btn-md btn-outline-primary" onClick={() => onEditClick()}>Modifica</button>
        <button className="btn btn-md btn-outline-error" onClick={() => onDeleteClick()}>Elimina</button>
      </div>
    </>
  );
};
