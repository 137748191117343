import api from "api";
import LoginForm from "components/auth/LoginForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppStore } from "store/appStore";
import { validateEmail } from "utilities/funcUtils";
import urlUtils from "utilities/urlUtils";

type PropsType = {
};

export default ({ }: PropsType) => {
    let { cap } = useParams();
    const { clearPostalcodeLocation } = useAppStore(state => state);

    useEffect(() => { clearPostalcodeLocation(); }, [])


    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string | null>(null);

    const sendEmail = () => {
        if (validateEmail(email)) {
            // TODO: Da capire successivamente, al momento non dovrebbe mai apparire.
            console.warn("TODO", email)
            // api.location.notification(email, cap).then(() => {
            //     // MEMO: Per ora rimando in homepage (sito pubblico), vedere poi che fare.
            //     window.location.href = urlUtils.public.main();
            // });
            window.location.href = urlUtils.public.main();
        } else {
            setEmailError("Inserire una email valida");
        }
    };

    // TODO: Inserire il salvataggio della mail

    return <main className="main-container lg-center-container overflow-auto">
        <div className="container">
            <div className="row gy-5 align-items-lg-center flex-row-reverse justify-content-between">
                <div className="col-12 col-lg-7">
                    <div className="text-center">
                        <img src="/img/login/home-login-ko.svg" className="img-fluid" width="100%" height="auto" />
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div className="vstack gap-3">
                        <h1>Noooooo!<br /><span className="text-primary fw-bold">Sei a troppi litri di distanza...</span>
                        </h1>
                        <p>Purtroppo non effettuiamo ancora consegne nella tua zona, ma
                            siamo certi che
                            presto le cose cambieranno!<br />Lasciaci la tua e-mail, ti avviseremo non appena sarà
                            possibile.</p>

                        <div className="d-flex flex-column gap-2">

                            <div className="form-floating form-icon">
                                <input type="text" className="form-control" id="input-id" placeholder="Inserisci la tua email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmailError(null);
                                        setEmail(e.target.value);
                                    }} />

                                <div className="icon">
                                    <i className="driink-email"></i>
                                </div>

                                <label htmlFor="input-id">Inserisci la tua email</label>
                            </div>
                            {emailError != null && <div className="text-danger">{emailError}</div>}

                            <a
                                onClick={() => {
                                    setEmailError(null);
                                    sendEmail();
                                }} className="btn btn-primary btn-full">
                                Invia
                            </a>
                        </div>

                        <a href={urlUtils.public.main()} className="btn btn-link btn-primary">
                            Torna in homepage
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </main>
}