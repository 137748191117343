import { DaySlotType } from "models/timeslots";
import client from "../client";
import { convertToDateStringForAPI } from "utilities/convertUtils";
import { useAppStore } from "store/appStore";

export function get(
  from: moment.Moment,
  to: moment.Moment,
  locationId: string | undefined
): Promise<DaySlotType[]> {
  const { confirmedLocation } = useAppStore.getState();
  return client
    .get(`timeslots/${locationId ?? '00000000-0000-0000-0000-000000000000'}`, {
      params: {
        from: convertToDateStringForAPI(from.toDate()),
        to: convertToDateStringForAPI(to.toDate()),
      },
    })
    .then((res) => res.data);
}
