import StoreCartLayout from "components/layout/StoreCartLayout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import urlUtils from "utilities/urlUtils";
import { useContext, useEffect, useState } from "react";
import { REGISTRATION_TYPE } from "constants/auth";
import { UserRegistrationBody } from "api/user/types";
import { UserProfile } from "models/user";
import FacebookLoginBtn from "components/auth/FacebookLoginBtn";
import GoogleLoginBtn from "components/auth/GoogleLoginBtn";
import LoginForm from "components/auth/LoginForm";
import GenericLayout from "components/layout/GenericLayout";
import ProfileLayout from "components/layout/ProfileLayout";
import ForgotPswForm from "components/auth/ForgotPswForm";
import api from "api";
import Spinner from "components/common/Spinner";
import ResetPswForm from "components/auth/ResetPswForm";

export default () => {
  const navigate = useNavigate();

  const [checkErrLoading, setCheckErrLoading] = useState(true);
  const [checkErr, setCheckErr] = useState(false);
  const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false);

  const [tkn, setTkn] = useState<string>();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const qpTkn = searchParams.get("tkn") ?? undefined;
    setCheckErrLoading(true);
    if (qpTkn != null) {
      api.auth.checkForgotPasswordToken(qpTkn).then(() => {
        setCheckErr(false);
      }).catch(() => {
        setCheckErr(true);
      }).finally(() => setCheckErrLoading(false));
    } else {

      setCheckErr(true);
      setCheckErrLoading(false);
    }
    setTkn(qpTkn);
  }, [])


  if (checkErrLoading) {
    return <Spinner page={true} />;
  }
  return <>
    <ProfileLayout title="Reset password" containerCustomClasses="container-close-2 mt-custom" titleContainerCustomClasses="container-close-2 mt-custom mb-3">
      {
        checkErr ?
          <label className="text-left">Token non più valido.</label> :
          passwordChangedSuccessfully ?
            <div className="d-flex mt-2 gap-2 flex-column">
              <div>
                <label className="text-left">Password cambiata con successo.</label>
              </div>
              <Link className="btn btn-primary" to={urlUtils.login()}>Val alla schermata di login</Link>
            </div>
            : <ResetPswForm token={tkn!} onPasswordChanged={() => setPasswordChangedSuccessfully(true)} />
      }
    </ProfileLayout>
    {/* <StoreCartLayout
      containerClosed
      showLocation={false}
      title="I tuoi dati"
      subtitle="Procedi con la registrazione o il login."
      showBack
      backLabel="Torna al tuo carrello"
      labelNextStep="Procedi" // TODO: Dove deve portare???
      urlNextStep={urlUtils.cartSummary()}
    >
      <LoginForm onLoginCompleted={onLoginCompletedInternal} />
    </StoreCartLayout> */}
  </>;
}
