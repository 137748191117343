import { useEffect, useRef, useState } from "react";
import CartBoxBuilder from "./CartBoxBuilder";
import { BoxCartElement } from "models/cart";
import { useCartStore } from "store/cartStore";
import { useShopStore } from "store/shopStore";

type PropsType = {
};

export default ({ }: PropsType) => {

    const boxesInProgress = useCartStore(state => state.boxesInProgress);

    const [oldBoxes, setOldBoxes] = useState<BoxCartElement[]>([]);
    const [showTooltipInfo, setShowTooltipInfo] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");
    const setExpandedBoxKey = useShopStore(state => state.setExpandedBoxKey);


    useEffect(() => {
        if (boxesInProgress.length > oldBoxes.length) {
            const oldBoxKeys = oldBoxes.map((b) => b.key);
            const newBoxes = boxesInProgress.filter(
                (b) => !oldBoxKeys.includes(b.key)
            );

            setOldBoxes(boxesInProgress);
            if (newBoxes.length > 0) {
                setTooltipMessage(newBoxes[0].box.description ?? "");
                setShowTooltipInfo(true);
                setTimeout(() => setShowTooltipInfo(false), 2000);
            }
        }
    }, [boxesInProgress.length]);


    const accordionCustomBoxRef = useRef<HTMLDivElement>(null);

    const [expandedBoxId, setExpandedBoxId] = useState<string>();
    const onExpandCollapgeToggle = (clickedKey: string) => {
        setExpandedBoxKey("");
        if (clickedKey === expandedBoxId) {
            setExpandedBoxId(undefined);

        } else {
            setExpandedBoxId(clickedKey);
        }
    }

    if (boxesInProgress.length === 0) {
        return null;
    }
    return (
        <div className="custom-box-wrapper">
            <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column mt-custom p-custom px-custom mtSpace-06875 pxSpace-0375">
                    <p className="h5">Stai creando un box personalizzato!</p>
                    <small className="mt-1">
                        {tooltipMessage}
                    </small>
                </div>

                <div className="d-flex flex-column gap-custom accordion gSpace-0625" id="accordionCustomBox" ref={accordionCustomBoxRef}>
                    {boxesInProgress.map((b) => (
                        <CartBoxBuilder
                            key={b.key}
                            data={b}
                            active={b.key === expandedBoxId}
                            onExpandCollapgeToggle={() => onExpandCollapgeToggle(b.key)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
