import { DaySlotType, TimeSlotWithDateType } from "models/timeslots";
import client from "../client";
import { convertToDateStringForAPI } from "utilities/convertUtils";
import { BoxCartElement, CartType, ICartElement, SimpleCartElement } from "models/cart";
import { ShippingMethod } from "models/shippingMethods";
import { PRODUCT_ENUM } from "constants/common";
import { CartSummaryBoxType, CartSummaryItemType, CartSummaryType } from "./types";
import { CartSummaryType as CartSummaryTypeResponse } from "models/summary";
import { CouponDiscountType, CouponType } from "models/coupon";

export function get(
  data: ICartElement[],
  orderType: CartType | undefined,
  timeslotDiscount: number | undefined,
  timeslotDiscountAbsolute: boolean,
  shippingMethodPrice: number | undefined,
  couponCode: string | undefined,
  couponDiscounts: CouponDiscountType[] | undefined,
): Promise<CartSummaryTypeResponse> {
  let boxes: CartSummaryBoxType[] = [];
  let items: CartSummaryItemType[] = [];

  data.forEach(d => {
    if (d.type === PRODUCT_ENUM.BOX) {
      const bElem = d as BoxCartElement;
      let count = 0;
      let box: CartSummaryBoxType = {
        id: bElem.box.id,
        count: bElem.count,
        items: []
      };
      let countElems: Record<string, number> = {};
      bElem.elems.forEach((e) => {
        if (countElems[e.id] == undefined) {
          countElems[e.id] = 1;
        } else {
          countElems[e.id] += 1;
        }
      });
      Object.entries(countElems).map(ce => box.items?.push({
        id: ce[0],
        count: ce[1]
      } as CartSummaryItemType));
      boxes.push(box);
      return count;
    } else if (d.type === PRODUCT_ENUM.SIMPLE) {
      let countElems: Record<string, number> = {};
      const sElem = d as SimpleCartElement;
      if (countElems[sElem.elem.id] == undefined) {
        countElems[sElem.elem.id] = sElem.count;
      } else {
        countElems[sElem.elem.id] += sElem.count;
      }
      Object.entries(countElems).map(ce => items?.push({
        id: ce[0],
        count: ce[1]
      } as CartSummaryItemType));
    }
  });

  let body: CartSummaryType = {
    boxes,
    items,
    isRecursive: orderType === CartType.RECURRING_DELIVERY,
    timeslotDiscount: timeslotDiscount,
    timeslotDiscountAbsolute: timeslotDiscountAbsolute,
    shippingMethodPrice: shippingMethodPrice,
    couponCode: couponCode,
    couponDiscounts: couponDiscounts,
    // timeslotId: selectedTimeSlot?.id,
    // shippingMethodId: selectedShippingMethod?.id,
    // couponId: selectedCoupon?.id
  };

  return client
    .post(`summary/cart/total`, body)
    .then((res) => res.data);
}
