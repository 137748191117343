import { Outlet, useNavigate } from "react-router-dom"
import LocationInput from "../location/LocationInput"
import { convertToCurrency } from "utilities/convertUtils";
import { useContext, useEffect } from "react";
import urlUtils from "utilities/urlUtils";
import { useCartStore } from "store/cartStore";
import { useAppStore } from "store/appStore";
import CartList from "./CartList";

type PropsType = {
    hideIfEmpty?: boolean;
    urlNextStepDisabled?: boolean;
    urlNextStep?: string;
    functionNextStep?: () => void;
    labelNextStep?: string;
    showExpandCartRecup?: boolean;
};

export default ({
    hideIfEmpty = false,
    urlNextStepDisabled = false,
    functionNextStep = undefined,
    urlNextStep = urlUtils.cart(),
    labelNextStep = "Vai al tuo pacchetto",
    showExpandCartRecup = false
}: PropsType) => {
    const data = useCartStore(state => state.data);
    const boxesInProgress = useCartStore(state => state.boxesInProgress);
    const cartTotal = useCartStore(state => state.cartTotal);
    let oldCartTotal = 0;
    const selectedCoupon = useCartStore(state => state.selectedCoupon);
    const selectedTimeslot = useCartStore(state => state.selectedTimeSlot);
    const selectedType = useCartStore(state => state.selectedType);


    const total = useCartStore(state => state?.count?.totalConfirmed);
    const calcCartTotal = useCartStore(state => state.calcCartTotal);

    useEffect(() => {
        if (useAppStore.getState().confirmedToken != null) {
            // MEMO: Se cambia il count totale ricarico il prezzo totale del carrello.
            calcCartTotal();
        }
    }, [total, selectedCoupon, selectedTimeslot, selectedType]);

    useEffect(() => {
        if (showExpandCartRecup) {
            console.log('expanderlogic')
            const cartContainer = document.querySelector('.cart-expander');
            const collapseCartDetailsDiv = document.getElementById('collapseCartDetails');
            if (cartTotal?.total === 0) {
                console.log('hide expander')
                cartContainer?.classList.remove('cart-has-items');


                // Aggiungi l'attributo CSS display: none
                if (collapseCartDetailsDiv) {
                    collapseCartDetailsDiv.style.display = 'none';
                }

            } else {
                console.log('show expander')
                cartContainer?.classList.add('cart-has-items');
                if (collapseCartDetailsDiv) {
                    collapseCartDetailsDiv.style.removeProperty('display');
                }
            }
        }
    }, [cartTotal?.total]);

    const navigate = useNavigate();

    useEffect(() => {
        const collapseElement = document.getElementById('collapseCartDetails');
        const hrElement = document.getElementById('hr-element');

        /* console.log("hrElement: ", hrElement);
        console.log("collapseElement: ", collapseElement); */

        if (collapseElement != null && hrElement != null) {
            const handleShow = () => {
                hrElement.classList.remove('fade-in');
                hrElement.classList.add('fade-out');
            };

            const handleHide = () => {
                hrElement.classList.remove('fade-out');
                hrElement.classList.add('fade-in');
            };

            collapseElement.addEventListener('show.bs.collapse', handleShow);
            collapseElement.addEventListener('hide.bs.collapse', handleHide);

            return () => {
                collapseElement.removeEventListener('show.bs.collapse', handleShow);
                collapseElement.removeEventListener('hide.bs.collapse', handleHide);
            };
        }
    }, []);

    if (hideIfEmpty && data.length === 0 && boxesInProgress.length == 0) {
        return null;
    }

    return <div className="bg-gradient-primary lateral-padding text-white pt-3 pb-3 pt-custom">
        <div className="box-cart-container">
            <div className="d-flex items-center w-full flex-row price-margin">
                <i className="driink-cart"></i>
                <div className="price">
                    <div className="price" style={{ marginLeft: 10 }}>

                        {cartTotal?.totalDiscount ?? 0 != 0 ?
                            <span className="lead discounted">{convertToCurrency((cartTotal?.total ?? 0))}</span> : null}

                        <span className="lead">{convertToCurrency((cartTotal?.total ?? 0) - (cartTotal?.totalDiscount ?? 0))}</span>
                    </div>
                </div>
                {showExpandCartRecup ?
                    <div className="cart-expander">
                        <a
                            className="btn btn-action p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            href={`#collapseCartDetails`}
                            aria-expanded="false"
                            aria-controls="collapseComponent"
                            style={{ position: "absolute", right: 10 }}
                        >
                            <i className="material-symbols-outlined">expand_less</i>
                        </a>
                    </div>
                    : null
                }
            </div>
            {showExpandCartRecup ?
                <div className="collapse"
                    id={`collapseCartDetails`} style={{ width: "100%" }}
                >
                    <div style={{ color: "black" }}>
                        <CartList showProductIcon={false} />
                    </div>
                </div>
                : null
            }
            <div className="cart-hr-container" >
                <hr id={`hr-element`} className="w-100 border-white my-0 d-none d-lg-block fade-in" />
            </div>

            <div className="mx-lg-auto">

                {labelNextStep != null ? (
                    <button
                        onClick={() =>
                            functionNextStep != null
                                ? functionNextStep()
                                : navigate(urlNextStep)
                        }
                        className="btn btn-link"
                        disabled={urlNextStepDisabled}
                    >
                        {labelNextStep}
                    </button>
                ) : null}

            </div>

        </div>
    </div>
}