import api from "api";
import Spinner from "components/common/Spinner";
import GenericLayout from "components/layout/GenericLayout";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCartStore } from "store/cartStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType, GaCartData } from "utilities/analyticsUtils/types";
import { getPublicImageUrl } from "utilities/imageUtils";
import { shareOnFacebook, shareOnInstagram } from "utilities/socialUtils";
import urlUtils from "utilities/urlUtils";

export default () => {
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const cartdata = useCartStore(state => state.data);
  const clearCart = useCartStore(state => state.clearCart);

  useEffect(() => {
    const tkn = searchParams.get("token");
    const pId = searchParams.get("PayerID");
    const subscriptionId = searchParams.get("subscription_id");
    const baToken = searchParams.get("ba_token");

    if (id != null && tkn != null && pId != null) {
      api.orders.updatePay(id, tkn, pId)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          navigate(urlUtils.paymentKo(id))
        });
    } else if (id != null && subscriptionId != null && baToken != null && tkn != null) {
      api.orders.updateSubscriptionPay(id, subscriptionId, baToken, tkn)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          navigate(urlUtils.paymentKo(id))
        });
    }

    const totalPriceWithoutDiscount = cartdata.reduce((total, item) => {
      return total + item.elem.priceWithoutDiscount;
    }, 0);

    const newGaCartData: GaCartData = {
      cartData: cartdata,
      cartTotal: totalPriceWithoutDiscount
    };

    analyticsUtils.sendEvent(AnalyticsActionCategory.NAVIGATION, AnalyticsEventActionType.CLICK, "checkout completed ok", newGaCartData);
    //spostato qui per poter tracciare il cart su GA
    clearCart();

  }, []);

  const onFacebookShareBtnClick = () => {
    // TODO: Da testare!!!
    // shareOnFacebook("Testo di esempio")
  }
  const onInstagramShareBtnClick = () => {
    // TODO: Da testare!!!
    // shareOnInstagram("Testo di esempio")
  }


  return <GenericLayout containerCustomClasses="">
    {loading ? <Spinner page={true} /> :
      <div className="container">
        <div className="row gy-5 align-items-lg-center flex-row-reverse justify-content-between">
          <div className="col-12 col-lg-7">
            <div className="text-center">
              <picture>
                <source srcSet={getPublicImageUrl("payment-confirmed.webp")} type="image/webp" />
                <img src={getPublicImageUrl("payment-confirmed.png")} className="img-fluid" width="100%" height="auto" />

              </picture>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="vstack gap-3 gap-lg-4">
              <div className="px-3 px-lg-0">
                <h1 className="mb-3">Evviva, acquisto confermato!</h1>
                {/* <p>Nunc semper velit ut orci rhoncus sodales. Nam commodo tincidunt justo, convallis
                pellentesque nisl malesuada in.</p> */}
              </div>

              <div className="mt-5">
                <p className="mb-0">Ti piace il nostro servizio?</p>
                <p className="text-primary h3">Fallo sapere ai tuoi amici!</p>
              </div>

              <div className="mt-5">
                <div className="d-flex flex-column gap-2">
                  <button onClick={onFacebookShareBtnClick} className="btn btn-full btn-login">
                    <img src={getPublicImageUrl("facebook-logo.svg")} className="login-icon" />
                    Condividi su Facebook
                  </button>
                  <button onClick={onInstagramShareBtnClick} className="btn btn-full btn-login">
                    <img src={getPublicImageUrl("instagram-logo.svg")} className="login-icon" />
                    Condividi su Instagram
                  </button>
                </div>
              </div>

              <div className="my-3">
                <div className="line-separator">
                  oppure condividi via email
                </div>
              </div>

              <div className="mt-3">
                <form className="d-flex flex-column gap-2">
                  <div className="form-floating form-icon">
                    <input type="text" className="form-control" id="input-id" placeholder="Inserisci la email del tuo amico" value="" />
                    <div className="icon">
                      <i className="driink-email"></i>
                    </div>
                    <label htmlFor="input-id">Inserisci la email del tuo amico</label>
                  </div>

                  <a href="#" className="btn btn-primary">
                    Condividi con email
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </GenericLayout>;
}
