import { ChangePasswordBody } from "api/user/types";
import ProfileLayout from "components/layout/ProfileLayout";
import { debounce } from "utilities/funcUtils";
import { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import api from "api";
import { Form, Formik } from "formik";
import FormField from "components/common/form/FormField";
import { FormikObserver } from "components/common/form/FormikObserver";
import { useAppStore } from "store/appStore";
import { MessageBoxButtonsType, MessageBoxIcon } from "components/common/MessageBox";

export default () => {
  const [loading, setLoading] = useState(false);

  const getInitFormData = () => {
    return {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    };
  };

  const [formData, setFormData] = useState<any>(
    getInitFormData()
  );

  useEffect(() => {
    setFormData(getInitFormData());
  }, []);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Vecchia password richiesta"),
    newPassword: Yup.string().required("Nuova password richiesta"),
    newPassword2: Yup.string().required("Conferma nuova password richiesta")
      .test('check-new-passwords', '', function (value, ctx) {
        if (value && value !== ctx.parent.newPassword) {
          return this.createError({ message: `Le nuove password non coincidono` })
        }
        return true
      }),
    // phone: Yup.string().required("Telefono richiesto"),
  });

  const showMessageBox = useAppStore(state => state.showMessageBox);

  return <ProfileLayout showBack title="Cambia password">
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        showMessageBox({
          icon: MessageBoxIcon.SUCCESS,
          title: 'Operazione effettuata con successo',
          type: MessageBoxButtonsType.YESNO,
          onOk() {
            console.log("Submit change password")
            setLoading(true);
            api.user.changePassword(values.oldPassword, values.newPassword).then((user) => {
              // dispatch({
              //   type: AuthContext.ACTIONS.SET_USER_PROFILE,
              //   value: user,
              // });
              // TODO: Aggiungere messaggio di conferma cambio password
            }).finally(() => setLoading(false));
          },
        })
      }}
    >
      {(formik) => {
        const {
          errors,
          touched,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit
        } = formik;
        return (
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column gap-custom mb-3 gSpace-1875">
                  <div className="shadow-box p-3">
                    <div className="d-flex flex-column gap-2">
                      <FormField
                        name="oldPassword"
                        label="Vecchia password"
                        type="password"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormField
                        name="newPassword"
                        label="Nuova password"
                        type="password"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormField
                        /* icon="driink-email" */
                        name="newPassword2"
                        label="Conferma nuova password"
                        type="password"
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-auto">
                  <button disabled={!(dirty && isValid) || loading} className="btn btn-primary btn-full" onChange={() => handleSubmit()}>
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  </ProfileLayout>;
}
