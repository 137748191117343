import api from "api";
import { ShopCategoryEnum } from "constants/shop";
import { useContext, useEffect, useState } from "react";
import ShopFilterForm from "./ShopFilterForm";
import { useShopStore } from "store/shopStore";
import { useAppStore } from "store/appStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsEventActionType } from "utilities/analyticsUtils/types";

type PropsType = {
    type: ShopCategoryEnum;
};

export default ({ type }: PropsType) => {
    const [showInfo, setShowInfo] = useState(false);

    const filters = useShopStore(state => state.filters);
    const selectedFilters = useShopStore(state => state.selectedFilters);
    const setFilterData = useShopStore(state => state.setFilterData);

    const confirmedLocation = useAppStore(state => state.confirmedLocation)

    useEffect(() => {
        if (type != null && confirmedLocation != null) {
            if (filters[type] == null) {
                api.shop.getFiltersByCategory(type).then((res) => {
                    setFilterData({ type, value: res });
                });
            }
        }
        console.log("AAA", type, filters)
    }, [type, confirmedLocation]);

    const isFilterActive = () => {
        return (
            type != null &&
            (selectedFilters[type]?.brands != null ||
                selectedFilters[type]?.types != null)
        );
    };

    return <>
        <button
            className={`btn btn-gradient btn-action filter ${isFilterActive() ? "filter-active" : ""
                }`}
            onClick={() => setShowInfo(true)}
        >
            <span className="driink-filter"></span>
        </button>

        {type != null && filters[type] != null ? (
            <ShopFilterForm
                type={type}
                data={filters[type]!}
                show={showInfo}
                setShowValue={setShowInfo}
            />
        ) : null}
    </>
}