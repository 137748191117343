import { useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "utilities/imageUtils";

type PropsType = {
  image?: string;
  title: string;
  text?: string;
}
export default ({ image = 'no-subs.svg', title, text }: PropsType) => {

  return (
    <div className="row flex-bottom-container gy-5 align-items-lg-center flex-row-reverse justify-content-between">
      <div className="col-12 col-lg-7">
        <div className="text-center">
          <img src={getPublicImageUrl(`${image}`)} className="img-fluid" width="100%" height="auto" />
        </div>
      </div>

      <div className="col-12 col-lg-4 flex-bottom-item">
        <div className="mb-3">
          <h1 className="mb-3 text-primary fw-bold">{title}</h1>
          {text != null ? <p>{text}</p> : null}
        </div>
      </div>
    </div>
  );
};
