import api from "api";
import { ShopItemDetailType } from "api/shop/types";
import { PRODUCT_ENUM } from "constants/common";
import { BoxCartElement, CartType, ICartElement, SimpleCartElement } from "models/cart";
import { CouponType } from "models/coupon";
import { ShippingMethod } from "models/shippingMethods";
import { BoxType } from "models/shop";
import { CartSummaryType } from "models/summary";
import { TimeSlotWithDateType } from "models/timeslots";
import { UserAddress, UserCartOrder, UserCartOrderBox, UserCartOrderItemDetail } from "models/user";
import { useState } from "react";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType, GaCartData } from "utilities/analyticsUtils/types";
import { randomIntFromInterval } from "utilities/generateUtils";
import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';

// const CART_KEY = "cart";
// const initialCartStr = localStorage.getItem(CART_KEY);
// 
// const initialCart: ICartElement[] =
//     initialCartStr == null || initialCartStr == ""
//         ? []
//         : JSON.parse(initialCartStr ?? "") ?? [];


const getUpdatedCountCartData = (state: PartialStateType) => {
    let countElems: Record<string, number> = {};

    let totalBoxesInProgress = 0;
    state.boxesInProgress.forEach((d) => {
        const bElem = d;
        bElem.elems.forEach((e) => {
            if (countElems[e.id] == undefined) {
                countElems[e.id] = 1;
            } else {
                countElems[e.id] += 1;
            }
            totalBoxesInProgress += 1;
        });
    });
    let totalConfirmed = 0;
    state.data.forEach((d) => {
        if (d.type === PRODUCT_ENUM.BOX) {
            const bElem = d as BoxCartElement;
            let count = 0;
            bElem.elems.forEach((e) => {
                if (countElems[e.id] == undefined) {
                    countElems[e.id] = 1;
                } else {
                    countElems[e.id] += 1;
                }
                totalConfirmed += 1;
            });
            return count;
        } else if (d.type === PRODUCT_ENUM.SIMPLE) {
            const sElem = d as SimpleCartElement;
            if (countElems[sElem.elem.id] == undefined) {
                countElems[sElem.elem.id] = sElem.count;
            } else {
                countElems[sElem.elem.id] += sElem.count;
            }
            totalConfirmed += sElem.count;
        }
    });
    return {
        elems: countElems,
        totalConfirmed: totalConfirmed,
        totalInProgress: totalConfirmed + totalConfirmed,
    };
};

const getCartElemTypes = (elems: ICartElement[]): string[] => {
    let allElems: ShopItemDetailType[] = [];
    elems.forEach((d) => {
        if (d.type === PRODUCT_ENUM.BOX) {
            const elem = d as BoxCartElement;
            allElems = [...allElems, ...elem.elems];
        } else if (d.type === PRODUCT_ENUM.SIMPLE) {
            const elem = d as SimpleCartElement;
            allElems = [...allElems, elem.elem];
        }
    });

    let types: string[] = [];
    allElems.forEach((elem) => {
        if (!types.includes(elem.type)) {
            types.push(elem.type);
        }
    });
    return types;
};

export const convertUserCartOrderBoxToICartElement = (boxes: UserCartOrderBox[]): ICartElement[] => {
    let data: ICartElement[] = [];
    boxes.forEach((b, idx) => {
        if (b.box != null) {
            const elems: ShopItemDetailType[] = [];
            b.itemDetails.forEach(id => {
                for (let i = 0; i < id.count; i++) {
                    if (id.itemDetail != null) {
                        elems.push(id.itemDetail);
                    }
                }
            })
            if (elems.length > 0) {
                data.push({
                    key: `BOX-${idx}-${randomIntFromInterval(
                        1,
                        9999999
                    )}`,
                    type: PRODUCT_ENUM.BOX,
                    elems: elems,
                    elemMaxCount: b.box.maxCount,
                    box: b.box,
                    count: 1,
                } as BoxCartElement);
            }
        }
    })
    return data;
}

export const convertUserCartOrderItemDetailToICartElement = (itemDetails: UserCartOrderItemDetail[]): ICartElement[] => {
    let data: ICartElement[] = [];
    itemDetails.forEach((id, idx) => {
        if (id.itemDetail != null) {
            data.push({
                key: `ITM-${idx}-${randomIntFromInterval(
                    1,
                    9999999
                )}`,
                type: PRODUCT_ENUM.SIMPLE,
                elem: id.itemDetail,
                count: id.count,
            } as SimpleCartElement);
        }
    });
    return data;
}


type PartialStateType = {
    data: ICartElement[];
    selectedShippingAddress: UserAddress | undefined;
    selectedBillingAddress: UserAddress | undefined;
    selectedTimeSlot: TimeSlotWithDateType | undefined;
    selectedType: CartType | undefined;
    selectedShippingMethod: ShippingMethod | undefined;
    selectedCoupon: CouponType | undefined;
    boxesInProgress: BoxCartElement[];
    types: string[];
    count: {
        elems: Record<string, number>;
        totalConfirmed: number;
        totalInProgress: number;
    };
    cartTotal: CartSummaryType | undefined,
    cartTotalLoading: boolean,
};

const initialState: PartialStateType = {
    data: [], //initialCart,
    selectedShippingAddress: undefined,
    selectedBillingAddress: undefined,
    selectedTimeSlot: undefined,
    selectedType: undefined,
    selectedShippingMethod: undefined,
    selectedCoupon: undefined,
    boxesInProgress: [],
    types: [], // getCartElemTypes(initialCart),
    count: {
        elems: {},
        totalConfirmed: 0,
        totalInProgress: 0,
    },
    cartTotal: undefined,
    cartTotalLoading: false,
};
initialState.count = getUpdatedCountCartData(initialState);

type StateType = PartialStateType & {
    addToCart: ({ item, selectedBox, boxKey }: { item: any, selectedBox: any, boxKey: any }) => void;
    removeToCart: ({ item, boxKey }: { item: any, boxKey: any }) => void;
    confirmBox: (boxKey: any) => void;
    unconfirmBox: (boxKey: any) => void;
    increaseBoxCountToCart: (boxKey: any) => void;
    decreaseBoxCountToCart: (boxKey: any) => void;
    selectShippingAddress: (shippingAddress: UserAddress | undefined) => void;
    selectBillingAddress: (billingAddress: UserAddress | undefined) => void;
    selectTimeslot: (timeslot: TimeSlotWithDateType | undefined) => void;
    selectType: (type: CartType) => void;
    selectShippingMethod: (sm: ShippingMethod | undefined) => void;
    selectCoupon: (sm: CouponType) => void;
    removeCoupon: () => void;
    clearCart: () => void;
    updateCount: () => void;
    addCartFromOrder: (value: any) => void;
    calcCartTotal: () => Promise<void>;
};

export const sortBoxesByCount = (a: BoxCartElement, b: BoxCartElement) => a.elems.length > b.elems.length ? 1 : -1;
export const sortBoxesByCountDescending = (a: BoxCartElement, b: BoxCartElement) => a.elems.length > b.elems.length ? -1 : 1;

export const useCartStore = create(
    persist<StateType>(
        (set, get) => ({
            ...initialState,
            addToCart: ({ item, selectedBox, boxKey }: { item: any, selectedBox: any, boxKey: any }) => set((oldState) => {
                let elemData: ShopItemDetailType = item;
                let { data, boxesInProgress } = oldState;
                if (elemData.enabledBoxes != null && elemData.enabledBoxes.length > 0) {
                    // TODO: Check box
                    const foundIdx = boxesInProgress.findIndex((o) =>
                        boxKey != null // MEMO: Se ho selezionato da quale box rimuovere i dati li rimuovo da lì, altrimenti dal primo disponibile
                            ? o.key === boxKey
                            : o.box.id === (selectedBox as BoxType).id &&
                            o.elems.length < o.elemMaxCount
                    );

                    if (foundIdx > -1) {
                        (boxesInProgress[foundIdx] as BoxCartElement).elems.push(elemData);
                    } else {
                        // MEMO: Box completata o non presente, aggiungo un'altra box in progress
                        boxesInProgress.push({
                            key: `BOX-${boxesInProgress.length}-${randomIntFromInterval(
                                1,
                                9999999
                            )}`,
                            type: PRODUCT_ENUM.BOX,
                            elems: [elemData],
                            elemMaxCount: (selectedBox as BoxType).maxCount,
                            box: selectedBox as BoxType,
                            count: 1,
                        } as BoxCartElement);
                    }

                    // data.push({
                    //   type: PRODUCT_ENUM.BOX,
                    //   elems: [elemData],
                    //   elemMaxCount: (selectedBox as BoxType).maxCount,
                    //   box: selectedBox as BoxType,
                    //   count: 1,
                    // } as BoxCartElement);
                } else {
                    console.log("addToCart", data)
                    const foundIdx = data.findIndex(
                        (o) =>
                            o.type === PRODUCT_ENUM.SIMPLE &&
                            (o as SimpleCartElement).elem.id == elemData.id
                    );
                    if (foundIdx > -1) {
                        (data[foundIdx] as SimpleCartElement).count++;
                    } else {
                        data.push({
                            type: PRODUCT_ENUM.SIMPLE,
                            elem: elemData,
                            count: 1,
                        } as SimpleCartElement);
                    }
                }

                // const idx = getDataIdxByType(oldState, type);
                // console.log("ACTIONS.SET_FILTER_DATA", value);
                // oldState.types[idx].filters = value;
                const newState = {
                    ...oldState,
                    data: [...data],
                    boxesInProgress: [...boxesInProgress],
                    types: getCartElemTypes(data),
                } as StateType;
                newState.count = getUpdatedCountCartData(newState);

                const newGaCartData: GaCartData = {
                    cartData: newState.data,
                    cartTotal: newState.cartTotal?.total + item.price,
                    addedItem: item,
                    count: newState.count.totalConfirmed
                };

                analyticsUtils.sendEvent(AnalyticsActionCategory.BUTTON, AnalyticsEventActionType.CLICK, "add to cart", newGaCartData)

                return {
                    data: newState.data,
                    count: newState.count,
                    boxesInProgress: newState.boxesInProgress,
                    types: newState.types
                };
            }),
            removeToCart: ({ item, boxKey }: { item: any, boxKey: any }) => set((oldState) => {
                let elemData: ShopItemDetailType = item;
                let { data, boxesInProgress } = oldState;
                if (elemData.enabledBoxes != null && elemData.enabledBoxes.length > 0) {
                    const bip = [...boxesInProgress].sort(sortBoxesByCount).find((b) =>
                        boxKey != null // MEMO: Se ho selezionato da quale box rimuovere i dati li rimuovo da lì, altrimenti dal primo disponibile
                            ? b.key === boxKey
                            : b.elems.findIndex((elem) => elem.id == elemData.id) > -1
                    );

                    if (bip != null) {
                        // MEMO: Trovata una box non confermata, tolgo l'elemento da lì.
                        const fIdx = bip.elems.findIndex((elem) => elem.id == elemData.id);
                        bip.elems.splice(fIdx, 1);

                        if (bip.elems.length === 0) {
                            // MEMO: Rimuovo la box perché è vuota.
                            const bipIdx = boxesInProgress.findIndex(o => o.key === bip.key);
                            boxesInProgress.splice(bipIdx, 1);
                        }
                    } else {
                        // MEMO: Nelle box non confermate non è presente, provo a vedere se è in una box confermata (nel caso non potrebbe più essere confermata)
                        // altrimenti è un errore, non rimuovo nulla.
                        //
                        const foundIdx = data.findIndex(
                            (o) =>
                                o.type === PRODUCT_ENUM.BOX &&
                                (o as BoxCartElement).elems.findIndex(
                                    (elem) => elem.id == elemData.id
                                ) > -1
                        );

                        if (foundIdx > -1) {
                            const box = { ...data[foundIdx] } as BoxCartElement;

                            // MEMO: Rimuovo dai confermati.
                            data.splice(foundIdx, 1);

                            const fIdx = box.elems.findIndex((elem) => elem.id == elemData.id);
                            box.elems.splice(fIdx, 1);

                            boxesInProgress.push(box);
                        }
                    }
                } else {
                    const foundIdx = data.findIndex(
                        (o) =>
                            o.type === PRODUCT_ENUM.SIMPLE &&
                            (o as SimpleCartElement).elem.id == elemData.id
                    );
                    if (foundIdx > -1) {
                        (data[foundIdx] as SimpleCartElement).count--;

                        if ((data[foundIdx] as SimpleCartElement).count === 0) {
                            data.splice(foundIdx, 1);
                        }
                    }
                }



                // const idx = getDataIdxByType(oldState, type);
                // console.log("ACTIONS.SET_FILTER_DATA", value);
                // oldState.types[idx].filters = value;
                const newState = {
                    ...oldState,
                    data: [...data],
                    boxesInProgress: [...boxesInProgress],
                    types: getCartElemTypes(data),
                } as StateType;
                newState.count = getUpdatedCountCartData(newState);

                const newGaCartData: GaCartData = {
                    cartData: newState.data,
                    cartTotal: (newState.cartTotal?.total ?? 0) - item.price,
                    removedItem: item,
                    count: newState.count.totalConfirmed
                };

                analyticsUtils.sendEvent(AnalyticsActionCategory.BUTTON, AnalyticsEventActionType.CLICK, "remove from cart", newGaCartData)


                // updateCartOnLocalStorage(newState);
                // newState.calcCartTotal();

                // console.log("removeToCart", {
                //     data: newState.data,
                //     count: newState.count,
                //     boxesInProgress: newState.boxesInProgress,
                //     types: newState.types
                // });
                return {
                    data: newState.data,
                    count: newState.count,
                    boxesInProgress: newState.boxesInProgress,
                    types: newState.types
                };
            }),
            confirmBox: ({ boxKey }: { boxKey: string }) => set((oldState) => {
                let { data, boxesInProgress } = oldState;
                console.log("confirmBox 1", boxKey, boxesInProgress);
                const foundIdx = boxesInProgress.findIndex((o) => o.key === boxKey);

                const box = { ...boxesInProgress[foundIdx] } as BoxCartElement;
                boxesInProgress.splice(foundIdx, 1);
                data.push(box);

                console.log("confirmBox 2", box);
                const newState = {
                    ...oldState,
                    data: [...data],
                    boxesInProgress: [...boxesInProgress],
                    types: getCartElemTypes(data),
                } as StateType;
                // updateCartOnLocalStorage(newState);
                newState.count = getUpdatedCountCartData(newState);
                // newState.calcCartTotal();

                return {
                    data: newState.data,
                    count: newState.count,
                    boxesInProgress: newState.boxesInProgress,
                    types: newState.types
                };
            }),
            unconfirmBox: ({ boxKey }: { boxKey: string }) => set((oldState) => {
                let { data, boxesInProgress } = oldState;
                const foundIdx = data.findIndex(
                    (o) =>
                        o.type === PRODUCT_ENUM.BOX && (o as BoxCartElement).key === boxKey
                );

                const box = { ...data[foundIdx] } as BoxCartElement;
                data.splice(foundIdx, 1);
                boxesInProgress.push(box);

                const newState = {
                    ...oldState,
                    data: [...data],
                    boxesInProgress: [...boxesInProgress],
                    types: getCartElemTypes(data),
                } as StateType;
                // updateCartOnLocalStorage(newState);
                // newState.calcCartTotal();
                return {
                    data: newState.data,
                    boxesInProgress: newState.boxesInProgress,
                    types: newState.types
                };
            }),
            increaseBoxCountToCart: ({ boxKey }: { boxKey: string }) => set((oldState) => {
                let { data } = oldState;
                const foundIdx = data.findIndex(
                    (o) =>
                        o.type === PRODUCT_ENUM.BOX && (o as BoxCartElement).key === boxKey
                );
                (data[foundIdx] as BoxCartElement).count++;

                const newState = {
                    ...oldState,
                    data: [...data],
                    types: getCartElemTypes(data),
                } as StateType;
                // updateCartOnLocalStorage(newState);
                // newState.calcCartTotal();
                return {
                    data: newState.data,
                    types: newState.types
                };
            }),
            decreaseBoxCountToCart: ({ boxKey }: { boxKey: string }) => set((oldState) => {
                let { data } = oldState;
                const foundIdx = data.findIndex(
                    (o) =>
                        o.type === PRODUCT_ENUM.BOX && (o as BoxCartElement).key === boxKey
                );
                if ((data[foundIdx] as BoxCartElement).count == 1) {
                    // MEMO: Rimuovo dal carrello
                    data.splice(foundIdx, 1);
                } else {
                    (data[foundIdx] as BoxCartElement).count--;
                }

                const newState = {
                    ...oldState,
                    data: [...data],
                    types: getCartElemTypes(data),
                } as StateType;
                // updateCartOnLocalStorage(newState);
                // newState.calcCartTotal();
                return {
                    data: newState.data,
                    types: newState.types
                };
            }),
            selectShippingAddress: (shippingAddress: UserAddress | undefined) => set((oldState) => {
                return {
                    selectedShippingAddress: shippingAddress
                };
            }),
            selectBillingAddress: (billingAddress: UserAddress | undefined) => set((oldState) => {
                return {
                    selectedBillingAddress: billingAddress
                };
            }),
            selectTimeslot: (timeslot: TimeSlotWithDateType | undefined) => set((oldState) => {
                const newState = {
                    ...oldState,
                    selectedTimeSlot: timeslot,
                } as StateType;
                // newState.calcCartTotal();
                return {
                    selectedTimeSlot: newState.selectedTimeSlot
                };
            }),
            selectType: (type: CartType) => set((oldState) => {
                const newState = {
                    ...oldState,
                    selectedType: type,
                } as StateType;
                // newState.calcCartTotal();
                return {
                    selectedType: newState.selectedType
                };
            }),
            selectShippingMethod: (sm: ShippingMethod | undefined) => set((oldState) => {
                const newState = {
                    ...oldState,
                    selectedShippingMethod: sm,
                } as StateType;
                // newState.calcCartTotal();
                return {
                    selectedShippingMethod: newState.selectedShippingMethod
                };
            }),
            selectCoupon: (sm: CouponType) => set((oldState) => {
                const newState = {
                    ...oldState,
                    selectedCoupon: sm,
                } as StateType;
                // newState.calcCartTotal();
                return {
                    selectedCoupon: newState.selectedCoupon
                };
            }),
            removeCoupon: () => set((oldState) => {
                const newState = {
                    ...oldState,
                    selectedCoupon: undefined,
                } as StateType;
                // newState.calcCartTotal();
                return {
                    selectedCoupon: newState.selectedCoupon
                };
            }),
            clearCart: () => set((oldState) => {
                let { data } = oldState;
                const newState = {
                    ...oldState,
                    data: [],
                    types: getCartElemTypes(data),
                    selectedCoupon: undefined,
                    cartTotal: undefined,
                    selectedShippingAddress: undefined,
                    selectedBillingAddress: undefined,
                } as StateType;
                // updateCartOnLocalStorage(newState);
                return {
                    data: newState.data,
                    types: newState.types,
                    selectedCoupon: newState.selectedCoupon,
                    cartTotal: newState.cartTotal,
                    selectedShippingAddress: newState.selectedShippingAddress,
                    selectedBillingAddress: newState.selectedBillingAddress,
                };
            }),
            updateCount: () => set((oldState) => {
                const newState = {
                    ...oldState,
                    count: getUpdatedCountCartData(oldState),
                } as StateType;
                return {
                    count: newState.count
                };
            }),
            addCartFromOrder: (value: any) => set((oldState) => {
                const sm: UserCartOrder = value.order;
                let { data } = oldState;

                let newData: ICartElement[] = [];
                sm.itemDetails.forEach((id, idx) => {
                    if (id.itemDetail != null) {
                        newData.push({
                            key: `ITM-${idx}-${randomIntFromInterval(
                                1,
                                9999999
                            )}`,
                            type: PRODUCT_ENUM.SIMPLE,
                            elem: id.itemDetail,
                            count: id.count,
                        } as SimpleCartElement);
                    }
                });
                sm.boxes.forEach((b, idx) => {
                    if (b.box != null) {
                        const elems: ShopItemDetailType[] = [];
                        b.itemDetails.forEach(id => {
                            for (let i = 0; i < id.count; i++) {
                                if (id.itemDetail != null) {
                                    elems.push(id.itemDetail);
                                }
                            }
                        })
                        if (elems.length > 0) {
                            data.push({
                                key: `BOX-${idx}-${randomIntFromInterval(
                                    1,
                                    9999999
                                )}`,
                                type: PRODUCT_ENUM.BOX,
                                elems: elems,
                                elemMaxCount: b.box.maxCount,
                                box: b.box,
                                count: 1,
                            } as BoxCartElement);
                        }
                    }
                })

                console.log("new cart content", newData)

                const newState = {
                    ...oldState,
                    data: newData,
                    types: getCartElemTypes(newData),
                } as StateType;
                // updateCartOnLocalStorage(newState);
                // TODO: Ha senso fare questa chiamata o è meglio avere già i dati nella repsonse?
                // newState.calcCartTotal();
                return {
                    data: newState.data,
                    types: newState.types
                };
            }),
            calcCartTotal: () => {
                const oldState = get();
                set(() => ({ ...oldState, cartTotalLoading: true }));
                return api.summary.get(
                    oldState.data,
                    oldState.selectedType,
                    oldState.selectedTimeSlot?.discountPerc,
                    false,
                    oldState.selectedShippingMethod?.price,
                    oldState.selectedCoupon?.code,
                    oldState.selectedCoupon?.discounts
                ).then(cartSummary => {
                    // TODO: Vedere come gestire la response
                    set(() => ({ cartTotal: cartSummary, cartTotalLoading: false }));


                    const newGaCartData: GaCartData = {
                        cartData: oldState.data,
                        cartTotal: cartSummary.total
                    };

                    let label = "addToCart"
                    if (cartSummary.total != undefined && oldState.cartTotal?.total != undefined) {
                        if (cartSummary.total < oldState.cartTotal?.total) {
                            label = "removeToCart";
                        }
                    }
                    /* analyticsUtils.sendEvent("event", AnalyticsEventActionType.CLICK, label, newGaCartData); */

                })
                    .catch(() => set(() => ({ ...oldState, cartTotalLoading: false })));

            },

        }),
        {
            name: "cart",
            partialize(state) {
                return { data: state.data, types: getCartElemTypes(state.data) } as StateType;
            }
        }
    )
)