import { useEffect, useMemo, useState, useContext } from "react";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import PaymentPageCmp from "components/cart/PaymentPageCmp";
import { useCartStore } from "store/cartStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType } from "utilities/analyticsUtils/types";
import { CartType } from "models/cart";
import Spinner from "components/common/Spinner";

export default () => {
  const data = useCartStore(state => state.data);
  const shippingAddress = useCartStore(state => state.selectedShippingAddress);
  const billingAddress = useCartStore(state => state.selectedBillingAddress);
  const timeSlot = useCartStore(state => state.selectedTimeSlot);
  const type = useCartStore(state => state.selectedType);
  const shippingMethod = useCartStore(state => state.selectedShippingMethod);
  const coupon = useCartStore(state => state.selectedCoupon);
  const selectShippingMethod = useCartStore(state => state.selectShippingMethod);
  const clearCart = useCartStore(state => state.clearCart);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);

  const cartTotal = useCartStore(state => state.cartTotal);

  useEffect(() => {
    setProcessingPayment(false);

    console.log("tipo: ", CartType.RECURRING_DELIVERY);

    api.shippingMethods.get().then(res => {
      if (res.length > 0) {
        /* console.log(.price == 0) */
        selectShippingMethod(res[0]);
      }
    });

    if (type === CartType.RECURRING_DELIVERY) {
      analyticsUtils.sendEvent(AnalyticsActionCategory.NAVIGATION, AnalyticsEventActionType.CLICK, "recurring delivery", CartType.RECURRING_DELIVERY)
    }
  }, [])

  const onPayPalClick = async () => {
    if (timeSlot != null && shippingAddress != null && billingAddress != null && shippingMethod != null && type != null) {
      console.log("shippingAddress?.id", shippingAddress?.id)
      if (creatingOrder != true) {
        setCreatingOrder(true);
        const { id } = await api.orders
          .add(
            timeSlot?.id,
            timeSlot.day,
            timeSlot.month,
            timeSlot.year,
            shippingAddress?.id,
            billingAddress?.id,
            shippingMethod?.id,
            type,
            data,
            coupon
          );
        if (id != null) {
          await payOrder(id);
        }
        setCreatingOrder(false);
      }
    }
  };

  const payOrder = (orderId: string) => {
    if (orderId != null) {
      return api.orders.pay(orderId).then((res) => {
        // navigate(res.href);

        // MEMO: Per il momento cancello la pagina per non mostrare il carrello vuoto.
        setProcessingPayment(true);
        /* clearCart(); */
        window.location.href = res.href;
      });
    }
  }

  // console.log("canBePayed", )

  return <>
    {creatingOrder && <Spinner page={true} />}
    <PaymentPageCmp
      processingPayment={processingPayment}
      canBePayed={timeSlot != null && shippingAddress != null && billingAddress != null && shippingMethod != null && type != null}
      onPayPalClick={() => onPayPalClick()}
      cartTotal={cartTotal}
      couponCanBeAdded={true}
      couponCode={coupon?.code}
      couponDiscounts={coupon?.discounts}
    /></>;
}
