import { ShopCategoryEnum } from "constants/shop";
import client from "../client";
import { FilterResultType, ShopItemType, ShopPagingQueryType } from "./types";
import { Paginated } from "models/common";
import { BoxType } from "models/shop";

export function getBoxes(): Promise<BoxType[]> {
  return client.get(`shop/boxes`).then((res) => res.data);
}

export function getCategories(): Promise<ShopCategoryEnum[]> {
  return client.get(`shop/categories`).then((res) => res.data);
}

export function getFiltersByCategory(
  category: string
): Promise<FilterResultType> {
  return client.get(`shop/${category}/filters`).then((res) => res.data);
}

export function get(
  qry: ShopPagingQueryType
): Promise<Paginated<ShopItemType>> {
  return client
    .post(`shop`, {
      page: qry.page,
      pageSize: qry.pageSize,
      sortBy: qry.sortBy,
      sortDirection: qry.sortDirection,
      getAll: qry.getAll,
      filters: {
        brands: qry?.filters?.brands,
        types: qry?.filters?.types,
      },
      category: qry.category,
      box: qry.box,
    })
    .then((res) => res.data);
}
