import { UserProfile } from "models/user";
import client from "../client";
import { TokenResponse } from "./types";
import { AppRegistrationBody, GoogleRegistrationBody, UserRegistrationBody } from "api/user/types";
import { useAppStore } from "store/appStore";
import { useAuthStore } from "store/authStore";
import { LocationType } from "models/location";

export function appLogin(email: string, password: string): Promise<TokenResponse> {
  console.log("client", client);
  return client.post("auth/sign-in", { email, password }).then((res) => {
    console.log("login res", res.data);
    return res.data;
  });
}

export function appRegistration(
  body: AppRegistrationBody
): Promise<TokenResponse> {
  return client.post("auth/sign-up", body).then((res) => {
    const { token, warehouseToken, defaultUserLocation } = (res.data as TokenResponse);
    manageToken(token, warehouseToken, defaultUserLocation);
    return res.data;
  });
}

export function googleLogin(body: any): Promise<TokenResponse> {
  return client.post("auth/google/sign-in", body).then((res) => {
    const { token, warehouseToken, defaultUserLocation } = (res.data as TokenResponse);
    manageToken(token, warehouseToken, defaultUserLocation);
    return res.data;
  });
}

export function googleRegistration(
  body: GoogleRegistrationBody
): Promise<TokenResponse> {
  return client.post("auth/google/sign-up", body).then((res) => {
    const { token, warehouseToken, defaultUserLocation } = (res.data as TokenResponse);
    manageToken(token, warehouseToken, defaultUserLocation);
    return res.data;
  });
}

export function logout(
): Promise<undefined> {
  return new Promise((resolve, reject) => {
    resolve(undefined);
  });
}

export function forgotPassword(email: string): Promise<void> {
  return client.post("auth/forgot-password", { email });
}

export function checkForgotPasswordToken(token: string): Promise<void> {
  return client.post("auth/forgot-password/check-tkn", { token });
}

export function resetPassword(token: string, password: string): Promise<void> {
  return client.post("auth/reset-password", { token, password });
}

function manageToken(tkn: string | undefined, warehouseToken: string | undefined, location: LocationType) {
  console.log("manageToken", tkn);
  if (tkn != null) {
    useAuthStore.getState().setFromToken(tkn);
    useAppStore.getState().setAvailablePostalcodeLocation({ token: warehouseToken!, location })
  } else {
    useAuthStore.getState().clearUser();
  }
}
