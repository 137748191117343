import { Outlet } from "react-router-dom";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import LocationInput from "../location/LocationInput";
import { useAppStore } from "store/appStore";
import MessageBox from "components/common/MessageBox";

type PropsType = {
};
export default function AppLayout({
}: PropsType) {

    const messageBox = useAppStore(state => state.messageBox);
    return (
        <div className="d-flex flex-column">
            {messageBox != null
                ? <MessageBox {...messageBox} />
                : null
            }
            <AppHeader />
            <main className="d-flex flex-column">
                <Outlet />
            </main >
            {/* <AppFooter /> */}
        </div>
    );
}
