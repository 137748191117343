import settings from "settings"

export default () => {
    return <footer className="footer d-none d-lg-block">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-column gap-4 gap-lg-5">
                        <div className="text-md-center">
                            <img src="/img/driink-logo-white.svg" className="img-fluid logo-footer" width="129px" height="auto" />
                        </div>

                        <div className="d-flex flex-column flex-md-row justify-content-center gap-custom gap-md-4 gSpace-075">

                            <div className="d-flex align-items-center gap-2">
                                <span className="material-symbols-outlined fill-1 opacity-60">store</span>
                                <span>P.IVA 12793830964</span>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                                <span className="material-symbols-outlined fill-1 opacity-60">call</span>
                                <a className="text-white fw-normal" href="tel:+393939258802">393 9258802</a>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                                <span className="material-symbols-outlined fill-1 opacity-60">alternate_email</span>
                                <a className="text-white fw-normal" href="mailto:info@driink.it">info@driink.it</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <hr className="mt-4 mb-custom mbSpace-0875" />

                    <div className="d-flex align-items-center justify-content-between gap-3">
                        <small>© 2023 · All rights reserved</small>
                        <div className="d-flex align-items-center gap-1">
                            <small>
                                <a href="https://www.iubenda.com/privacy-policy/62365046" className="text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>

                            </small>

                            <span>-</span>

                            <small>
                                <a href="https://www.iubenda.com/privacy-policy/62365046/cookie-policy" className="text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>

                            </small>

                            <span>-</span>

                            <small>
                                <a href={settings.privacyUrl} className="text-white iubenda-cs-preferences-link">Impostazioni privacy</a>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}