import { LabelType } from "./common";

export enum SUBSCRIPTION_STATUS_ENUM {
    UNKNOWN = "unknown",
    ACTIVE = "active",
    PAUSED = "paused",
    STOPPED = "stopped",
}

export const SUBSCRIPTION_STATUS_LABEL: LabelType<SUBSCRIPTION_STATUS_ENUM>[] = [
    {
        value: SUBSCRIPTION_STATUS_ENUM.UNKNOWN,
        label: "",
    },
    {
        value: SUBSCRIPTION_STATUS_ENUM.ACTIVE,
        label: "Attive",
    },
    {
        value: SUBSCRIPTION_STATUS_ENUM.PAUSED,
        label: "Non attive",
    },
    {
        value: SUBSCRIPTION_STATUS_ENUM.STOPPED,
        label: "Non attive",
    }
];
