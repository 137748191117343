const apiTimeoutMs = parseInt(process.env.REACT_APP_API_TIMEOUT_MS ?? '');

let publicUrl = process.env.REACT_APP_PUBLIC_URL;
if (publicUrl != null && !publicUrl.endsWith('/')) {
  publicUrl += '/';
}

const getFormattedUrl = (url: string | undefined) => {
  let frmUrl = url;
  if (frmUrl != null && frmUrl.endsWith('/')) {
    frmUrl = frmUrl.substring(
      0,
      frmUrl.length - 1
    )
  }


  return frmUrl;
}

export default {
  env: process.env.NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL_API,
  appBaseUrl: getFormattedUrl(process.env.REACT_APP_BASE_URL),
  apiTimeoutMs: apiTimeoutMs > 0 ? apiTimeoutMs : undefined,
  loginUsername: process.env.REACT_APP_USERNAME,
  loginPassword: process.env.REACT_APP_PASSWORD,
  googleApiToken: process.env.REACT_APP_GOOGLE_API_TOKEN,
  googleAnalyticsTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  facebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
  defaultPostalCode: process.env.REACT_APP_DEFAULT_POSTAL_CODE,
  publicUrl: publicUrl,
  domain: process.env.REACT_APP_DOMAIN,
  privacyUrl: process.env.REACT_APP_PRIVACY_URL,
};
