export enum SORT_DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PRODUCT_ENUM {
  SIMPLE,
  BOX,
}

export type LabelType<T = string> = {
  value: T;
  label: string;
};

export const ShortWorkWeekDays: LabelType[] = [
  {
    value: "monday",
    label: "Lun",
  },
  {
    value: "tuesday",
    label: "Mar",
  },
  {
    value: "wednesday",
    label: "Mer",
  },
  {
    value: "thursday",
    label: "Gio",
  },
  {
    value: "friday",
    label: "Ven",
  },
  {
    value: "saturday",
    label: "Sab",
  },
  // {
  //   value: 'monday',
  //   label: 'Dom'
  // }
];

export const ShortWeekDays: LabelType[] = [
  ...ShortWorkWeekDays,
  {
    value: "sunday",
    label: "Dom",
  },
];



export const WorkWeekDays: LabelType[] = [
  {
    value: "monday",
    label: "lunedì",
  },
  {
    value: "tuesday",
    label: "martedì",
  },
  {
    value: "wednesday",
    label: "mercoledì",
  },
  {
    value: "thursday",
    label: "giovedì",
  },
  {
    value: "friday",
    label: "venerdì",
  },
  {
    value: "saturday",
    label: "sabato",
  },
  // {
  //   value: 'monday',
  //   label: 'Dom'
  // }
];

export const WeekDays: LabelType[] = [
  ...WorkWeekDays,
  {
    value: "sunday",
    label: "domenica",
  },
];
