import api from "api";
import AppLayout from "components/layout/AppLayout";
import AddressForm from "components/user/AddressForm";
import Address from "components/user/Address";
import { UserAddress, UserAddressType } from "models/user";
import { useState, useEffect } from "react";
import { UserAddressTypeLabel } from "constants/user";
import { useAppStore } from "store/appStore";
import { MessageBoxButtonsType, MessageBoxIcon } from "components/common/MessageBox";

type PropsType = {
  type: UserAddressType;
};
export default ({ type }: PropsType) => {
  const [addresses, setAddresses] = useState<UserAddress[]>([]);
  const [title, setTitle] = useState("");

  const refreshData = () => {
    let prom: Promise<UserAddress[]>;
    switch (type) {
      case UserAddressType.SHIPPING: prom = api.user.getShippingAddresses(); break;
      case UserAddressType.BILLING: prom = api.user.getBillingAddresses(); break;
    }
    prom.then((res) => setAddresses(res));
  };

  useEffect(() => {
    refreshData();
  }, []);

  const [showInfo, setShowInfo] = useState(false);

  const showMessageBox = useAppStore(state => state.showMessageBox);

  const [formAddressData, setFormAddressData] = useState<
    UserAddress | undefined
  >();
  const addOrEditAddress = (data: UserAddress | undefined = undefined) => {
    setFormAddressData(data);
    setShowInfo(true);
  };
  const deleteAddress = (data: UserAddress | undefined = undefined) => {
    if (data?.id != null) {
      showMessageBox({
        icon: MessageBoxIcon.ALERT,
        title: 'Conferma l’eliminazione',
        text: 'Sei sicuro/a di voler eliminare definitivamente tra i tuoi indirizzi?',
        type: MessageBoxButtonsType.YESNO,
        onOk() {
          api.user.deleteAddress(data.id).then((res) => refreshData());
        },
      })
    }
  };

  return (
    <>
      <div className="shadow-box p-4">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-4 ">
            <h4>Indirizzo di {UserAddressTypeLabel[type]}</h4>
            <div className="d-flex flex-column gap-3">
              {addresses.map((a: UserAddress) => (
                <>
                  <Address
                    data={a}
                    onEditClick={() => addOrEditAddress(a)}
                    onDeleteClick={() => deleteAddress(a)}
                    key={a.id}
                  />
                  <hr key={"hr-" + a.id} className="my-0" />
                </>
              ))}

              <a
                className="d-flex align-items-center gap-custom profile-new-address"
                onClick={() => addOrEditAddress()}
              /* style="--g-space: 0.75rem;" */
              >
                <span className="material-symbols-outlined fill-1">
                  add
                </span>
                <span className="fw-extrabold cursor-pointer">
                  Aggiungi nuovo indirizzo
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <AddressForm
        hideBackButton
        type={type}
        data={formAddressData}
        show={showInfo}
        setShowValue={setShowInfo}
        refreshData={refreshData}
      />
    </>
  );
};
