import * as auth from "./auth";
import * as user from "./user";
import * as shop from "./shop";
import * as location from "./location";
import * as timeSlots from "./timeSlots";
import * as orders from "./orders";
import * as shippingMethods from "./shippingMethods";
import * as images from "./images";
import * as summary from "./summary";

export default {
  auth,
  user,
  shop,
  location,
  timeSlots,
  shippingMethods,
  orders,
  images,
  summary
};
