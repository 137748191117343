import { ShopCategoryEnum } from "constants/shop";
import { useContext } from "react";
import { useCartStore } from "store/cartStore";
import { useShopStore } from "store/shopStore";

type PropsType = {
    active: boolean;
    value: any | undefined;
    onClick: (val: ShopCategoryEnum | undefined) => void;
};

export default ({ active, value, onClick }: PropsType) => {

    const types = useCartStore(state => state.types);

    const enabledCategories = useShopStore(state => state.enabledCategories);


    const isSubMenuDisabled = (val: ShopCategoryEnum) => {
        return (
            enabledCategories == null || !enabledCategories.includes(val)
        );
    };
    return <div
        key={value.value}
        className={`category-select-item ${isSubMenuDisabled(value.value) ? "disabled" : ""
            } filter ${active ? 'checked' : ''} ${types.includes(value.value) ? "filter-active" : ""
            }`}
    >
        <div className={`category-select-placeholder`}>
            <input
                className="category-select-input"
                type="checkbox"
                checked={active}
                id={value.value}
                onChange={(e) => {
                    if (e.currentTarget.checked) {
                        onClick(value.value);
                    }
                }}
            />
            <div className="category-select-image">
                <img src={value.imgSrc} />
            </div>
        </div>

        <label className="category-select-label" /* for={sm.value} */>
            {" " + value.label + " "}
        </label>
    </div>
}