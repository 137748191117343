import StoreCartLayout from "components/layout/StoreCartLayout";
import { useNavigate } from "react-router-dom";
import urlUtils from "utilities/urlUtils";
import { useContext, useState } from "react";
import { REGISTRATION_TYPE } from "constants/auth";
import { UserRegistrationBody } from "api/user/types";
import { UserProfile } from "models/user";
import FacebookLoginBtn from "components/auth/FacebookLoginBtn";
import GoogleLoginBtn from "components/auth/GoogleLoginBtn";
import LoginForm from "components/auth/LoginForm";
import GenericLayout from "components/layout/GenericLayout";
import ProfileLayout from "components/layout/ProfileLayout";
import ForgotPswForm from "components/auth/ForgotPswForm";

export default () => {
  const navigate = useNavigate();

  const onLoginCompletedInternal = () => {
    navigate(-1);
  };

  return <>
    <ProfileLayout title="Password dimenticata" containerCustomClasses="container-close-2 mt-custom" titleContainerCustomClasses="container-close-2 mt-custom mb-3">
      <ForgotPswForm />
    </ProfileLayout>
    {/* <StoreCartLayout
      containerClosed
      showLocation={false}
      title="I tuoi dati"
      subtitle="Procedi con la registrazione o il login."
      showBack
      backLabel="Torna al tuo carrello"
      labelNextStep="Procedi" // TODO: Dove deve portare???
      urlNextStep={urlUtils.cartSummary()}
    >
      <LoginForm onLoginCompleted={onLoginCompletedInternal} />
    </StoreCartLayout> */}
  </>;
}
