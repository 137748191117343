import { Link, Outlet, useNavigate } from "react-router-dom"
import LocationInput from "../location/LocationInput"
import Cart from "components/cart/Cart";
import CartBoxBuilderContainer from "components/cart/CartBoxBuilderContainer";
import urlUtils from "utilities/urlUtils";
import AppFooter from "./AppFooter";

type PropsType = {
    children: React.ReactNode;
    containerClosed?: boolean;
    containerCustomClasses?: string;
    title?: string;
    subtitle?: string;
    showBack?: boolean;
    backUrl?: string;
    backLabel?: string;
};
export default ({ children, containerClosed, containerCustomClasses = "mt-custom mtSpace-125", title, showBack, backUrl, backLabel = "Indietro", ...rest }: PropsType) => {
    const navigate = useNavigate();
    const handleBackButtonPress = () => {
        if (backUrl != null && backUrl != "") {
            navigate(backUrl);
        }
        else {
            navigate(-1);
        }
    }
    return <div className="flex-grow-1 d-flex flex-column overflow-auto">
        <div className="main-container flex-grow-1">
            {showBack || title != null && title != "" ? <div className={`container ${containerClosed ? 'container-close' : ''} mt-custom mb-custom mtSpace-125`}>

                <div className="row">
                    <div className="col-12">

                        <div className="arrow-back">
                            {showBack ? <>
                                <a onClick={handleBackButtonPress} className="btn btn-action p-0">

                                    <span className="material-symbols-outlined">arrow_back</span>
                                </a>
                                <a onClick={handleBackButtonPress} className="btn btn-action p-0" style={{ display: "flex" }}>
                                    <span className="arrow-back__back-label">{backLabel}</span>
                                </a>


                            </> : null}
                            <h4 className="arrow-back__title">{title}</h4>
                        </div>

                    </div>
                </div>
            </div> : null
            }

            <div className={`container ${containerClosed ? 'container-close' : ''} ${containerCustomClasses}`}>
                {children}
            </div>

        </div>
        <div className="d-none d-lg-block">
            <AppFooter />
        </div>
    </div>;
}