import { UserOrderBox } from "models/user";
import { convertToCurrency } from "utilities/convertUtils";
import { getPublicImageUrl } from "utilities/imageUtils";
import { getUserOrderBoxPrice } from "utilities/shopUtils";

type PropsType = {
  data: UserOrderBox;
};

export default ({ data }: PropsType) => {
  return (

    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column">

        <div className="box-item-body box-item-body--three-columns">
          <div className="box-item-body-info d-flex align-items-center gap-3">
            <div className="product-placeholder">
              <img src={getPublicImageUrl("item-icon/pack.svg")} />
            </div>

            <div className="d-flex flex-column">
              <small>Mix</small>

              <div className="text-primary d-flex align-items-center gap-2">
                <p className="fw-extrabold">Box personalizzato</p>

                <a className="btn btn-action p-0" data-bs-toggle="collapse" href="#collapseComponent" type="button" aria-expanded="false" aria-controls="collapseComponent">
                  <i className="material-symbols-outlined">expand_less</i>
                </a>

              </div>

              <small className="mt-1">cassa da 12 bottiglie</small>
            </div>
          </div>

          <div className="box-item-body-number-item">
            <p className="mb-0">x <span className="fw-extrabold">{data.count}</span></p>
          </div>

          <div className="box-item-body-price fw-bold">
            <p className="mb-0">{convertToCurrency(getUserOrderBoxPrice(data))}</p>
          </div>
        </div>


        <div className="collapse" id="collapseComponent">
          <div className="d-flex flex-column gap-3">
            <div className="mt-custom d-flex flex-column gap-2 mtSpace-075">
              {data.itemDetails?.map((id) => (
                <div className="d-flex align-items-center justify-content-between gap-1">
                  <div className="d-flex flex-column">
                    <small>{id.brand}</small>
                    <p className="fw-extrabold">{id.info}</p>
                  </div>

                  <div className="d-flex flex-column">
                    <p>{id.count}</p>
                  </div>
                </div>
              ))
              }

            </div>

            <a href="#" className="d-inline-flex align-items-center gap-custom gSpace-075">
              <span className="material-symbols-outlined  fill-1">edit</span>
              <span className="fw-extrabold">Modifica Box</span>
            </a>

          </div>
        </div>

      </div>

    </div>
  );
}