import { ShopItemDetailType } from "api/shop/types";
import { PRODUCT_ENUM } from "../constants/common";
import { BoxType } from "models/shop";

export type SimpleCartElement = ICartElement & {
  type: PRODUCT_ENUM.SIMPLE;
  elem: ShopItemDetailType;
  count: number;
};

export type BoxCartElement = ICartElement & {
  type: PRODUCT_ENUM.BOX;
  elems: ShopItemDetailType[];
  box: BoxType;
  elemMaxCount: number;
  count: number;
};

export interface ICartElement {
  [x: string]: any;
  key: string;
  type: PRODUCT_ENUM;
}

export enum CartType {
  SINGLE_DELIVERY = "single-delivery",
  RECURRING_DELIVERY = "recurring-delivery"
}