import { FilterResultType, FilterSelectedType } from "api/shop/types";
import SheetCard from "components/common/SheetCard";
import { ShopCategoryEnum } from "constants/shop";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useShopStore } from "store/shopStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType } from "utilities/analyticsUtils/types";

type PropsType = {
    type: ShopCategoryEnum;
    data: FilterResultType;
    show: boolean;
    setShowValue: (val: boolean) => void;
};

type GaCustomData = {
    selectedBrands?: object,
    selectedTypes?: object;
    tab?: ShopCategoryEnum;
}

export default ({ type, data, show, setShowValue }: PropsType) => {
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

    const filters = useShopStore(state => state.filters);
    const selectedFilters = useShopStore(state => state.selectedFilters);
    const setSelectedFilterData = useShopStore(state => state.setSelectedFilterData);

    useEffect(() => {
        let storeSelFilters = selectedFilters[type];

        let defSelBrands =
            storeSelFilters?.brands ?? (data?.brands?.map((b) => b.value ?? ''));
        let defSelTypes = storeSelFilters?.types ?? (data?.types?.map((b) => b.value ?? ''));

        setSelectedBrands(defSelBrands ?? []);
        setSelectedTypes(defSelTypes ?? []);
    }, [type]);

    const onToggleBrand = (value: string) =>
        onToggleElem(value, selectedBrands, setSelectedBrands);
    const onToggleType = (value: string) =>
        onToggleElem(value, selectedTypes, setSelectedTypes);
    const onToggleElem = (value: string, list: string[], setList: any) => {
        const fIdx = list.indexOf(value);
        if (fIdx > -1) {
            list.splice(fIdx, 1);
        } else {
            list.push(value);
        }
        console.log("onToggleElem", list);
        setList([...list]);
    };

    const saveSelectedFilters = () => {
        const allBrandsSelected = selectedBrands.length === data?.brands?.length;
        const allTypesSelected = selectedTypes.length === data?.types?.length;

        setSelectedFilterData({
            type,
            value: {
                brands: allBrandsSelected ? undefined : selectedBrands,
                types: allTypesSelected ? undefined : selectedTypes,
            },
        });
        setShowValue(false);


        const gaData: GaCustomData = {
            selectedBrands: selectedBrands,
            selectedTypes: selectedTypes,
            tab: type,
        };
        analyticsUtils.sendEvent(AnalyticsActionCategory.FILTER, AnalyticsEventActionType.CLICK, "Apply shop filters", gaData)
    };

    return (
        <SheetCard
            show={show}
            setShowValue={setShowValue}
            title="Filtra i prodotti"
            headerStart={
                <span className="material-symbols-outlined"> filter_alt </span>
            }
            body={
                <div className="container gutter-md">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex flex-column gap-4">
                                <div className="d-flex flex-column gap-2">
                                    <h6 className="fw-medium text-secondary txtOpacty05">Brand</h6>

                                    <div className="d-flex gap-2 flex-wrap">

                                        {data?.brands?.map((b) => (
                                            <div className="chips chips-primary">
                                                <input
                                                    type="checkbox"
                                                    id={b.value ?? ''}
                                                    name={b.value ?? ''}
                                                    checked={selectedBrands.includes(b.value ?? '')}
                                                    onChange={() => onToggleBrand(b.value ?? '')}
                                                />
                                                <label htmlFor={b.value ?? ''}>{b.label}</label>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                                <div className="d-flex flex-column gap-2">
                                    <h6 className="fw-medium text-secondary txtOpacty05">Tipologia</h6>

                                    <div className="d-flex flex-column gap-2">

                                        {data?.types?.map((t) => (
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={t.value ?? ''}
                                                    id={t.value ?? ''}
                                                    checked={selectedTypes.includes(t.value ?? '')}
                                                    onChange={() => onToggleType(t.value ?? '')}
                                                />
                                                <label className="form-check-label" /* for={t.value} */>
                                                    {t.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer={
                <a
                    onClick={() => saveSelectedFilters()}
                    className="btn btn-primary btn-md btn-full"
                >
                    Conferma
                </a>
            }
        />
    );
};
