import { getPublicImageUrl } from "utilities/imageUtils"

type PropsType = {
  disabled?: boolean,
  onPayPalClick: () => void,
}

export default ({ disabled = false, onPayPalClick }: PropsType) => {
  return <>
    <button
      className="btn btn-full btn-login"
      onClick={() => onPayPalClick()}
      disabled={disabled}
    >
      <img src={getPublicImageUrl(`paypal-logo.svg`)} className="login-icon" />
      Paga con Paypal
    </button>
  </>
}