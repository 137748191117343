import ProfileLayout from "components/layout/ProfileLayout";
import AddressesBox from "components/user/AddressesBox";
import { UserAddressType } from "models/user";

export default () => {
  return <ProfileLayout showBack title="I miei indirizzi">
    <div className="row">
      <div className="col-12">
        <div className="d-flex flex-column gap-3">
          <AddressesBox type={UserAddressType.SHIPPING} />
          <AddressesBox type={UserAddressType.BILLING} />
        </div>
      </div>
    </div>
  </ProfileLayout>;
}
