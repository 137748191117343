export enum COUPON_DISCOUNT_PROPERTY {
    Unknown = 'unknown',
    Subtotal = 'subtotal',
    Shipping = 'shipping',
    Total = 'total',
}
export const COUPON_DISCOUNT_PROPERTY_LABEL = {
    [COUPON_DISCOUNT_PROPERTY.Unknown]: '-',
    [COUPON_DISCOUNT_PROPERTY.Subtotal]: 'Subtotale',
    [COUPON_DISCOUNT_PROPERTY.Shipping]: 'Spedizione',
    [COUPON_DISCOUNT_PROPERTY.Total]: 'Totale',
};