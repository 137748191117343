import ShopItem from "components/shop/ShopItem";
import { useContext, useEffect, useState, useCallback } from "react";
import api from "api";
import {
    FilterResultType,
    ShopItemType,
    ShopPagingQueryType,
} from "api/shop/types";
import { ShopCategoryEnum } from "constants/shop";
import { useShopStore } from "store/shopStore";
import Spinner from "components/common/Spinner";
import { useAppStore } from "store/appStore";
import { generateRandomString } from "utilities/funcUtils";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType } from "utilities/analyticsUtils/types";

type PropsType = {
    type: ShopCategoryEnum;
};

let lastCallId: string | undefined = undefined;
export default ({ type }: PropsType) => {
    const selectedFilters = useShopStore((state) => state.selectedFilters);

    const [elems, setElems] = useState<ShopItemType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // const [currentCallback, setCurrentCallback] = useState<((callback: (bottomReached: boolean) => void) => void) | null>();
    const [currentCallback, setCurrentCallback] = useState<((bottomReached: boolean) => void) | null>();
    const itemsPerPage = 6;

    const [hasMoreContentToLoad, setHasMoreContentToLoad] = useState<boolean>(false);
    useEffect(() => {
        setHasMoreContentToLoad(!loading && totalItems > elems.length)
    }, [loading, totalItems, elems?.length])


    // --------------------------------------------------------------------------------------------------------
    // MEMO: Gestione dello scroll in basso, probabilmente ha senso renderlo più generico o riutilizzabile
    // TODO: C'è un problema di cambio contesto, bisogna capire come gestirlo (a volte sembra si ricrei il
    // componente in modo casuale)
    // --------------------------------------------------------------------------------------------------------
    //
    const trackScrolling = (isBottom: boolean) => {
        // console.log(randomId + ": trackScrolling", totalItems, elems, isBottom);
        if (!loading && hasMoreContentToLoad) {
            if (isBottom) {
                // console.log(randomId + ": trackScrolling 4");
                // console.log(randomId + ": header bottom reached");
                loadMoreItems();
            }
        }
    };

    const { addScrollCallback, removeScrollCallback, clearAllScrollCallback } = useAppStore();

    const addScrollEventListeners = () => {
        removeScrollEventListeners();
        addScrollCallback(trackScrolling);
        setCurrentCallback(trackScrolling);
    }
    const removeScrollEventListeners = () => {
        clearAllScrollCallback();
        if (currentCallback != null) {
            removeScrollCallback(currentCallback);
        }
    }

    useEffect(() => {
        if (hasMoreContentToLoad) {
            addScrollEventListeners();
        } else {
            removeScrollEventListeners();
        }
    }, [hasMoreContentToLoad])

    //
    // --------------------------------------------------------------------------------------------------------

    const confirmedLocation = useAppStore(state => state.confirmedLocation)

    const loadMoreItems = () => {
        analyticsUtils.sendEvent(AnalyticsActionCategory.LOAD, AnalyticsEventActionType.SCROLL, 'load more on products list', elems)
        fetchData(currentPage + 1);
    };

    const fetchData = (page: number, clearData: boolean = false) => {
        const rndId = generateRandomString();
        lastCallId = rndId;
        if (confirmedLocation != null) {
            setLoading(true);
            if (page != currentPage) {
                setCurrentPage(page);
            }

            const currType = type;
            const qry: ShopPagingQueryType = {
                category: type,
                box: undefined,
                filters: {
                    brands: selectedFilters[type]?.brands,
                    types: selectedFilters[type]?.types,
                },
                page: page,
                pageSize: itemsPerPage,
            };

            api.shop
                .get(qry)
                .then((res) => {
                    if (lastCallId == rndId) {
                        // if (currType === type) {
                        setLoading(false);
                        if (clearData) {
                            setElems(res.content);
                        }
                        else {
                            setElems((prevElems) => [...prevElems, ...res.content]);
                        }
                        setTotalItems(res.totalCount);
                        // }
                    }
                })
                .catch(() => setLoading(false));
        }
    }

    useEffect(() => {
        //setCurrentPage(0);
        fetchData(0, true);
    }, [type, selectedFilters[type], confirmedLocation]);

    // TODO: Gestire loading a livello grafico
    return (
        <div className="row row-gap-4">
            {
                elems.map((elm) => (
                    <div className="col-12 col-lg-6" key={elm.id}>
                        <ShopItem type={type} data={elm} />
                    </div>
                ))}
            {loading ? <Spinner /> :
                <>
                    {
                        hasMoreContentToLoad && (
                            <div className="col-12 text-center mt-3">
                                <button className="btn btn-primary" onClick={loadMoreItems}>
                                    Carica altro
                                </button>
                            </div>
                        )
                    }
                </>
            }
        </div>
    );
};