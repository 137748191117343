import { UserAddress, UserCartOrder, UserOrder, UserOrderCmp, UserProfile, UserSubscription, UserSubscriptionCmp } from "models/user";
import client from "../client";
import { ChangePasswordBody, UserAddressBody, UserProfileBody } from "./types";

export function changePassword(oldPsw: string, newPsw: string): Promise<any> {
  return client
    .post('user/change-password', {
      oldPassword: oldPsw,
      newPassword: newPsw
    })
    .then((res) => res.data);
}

export function getProfile(): Promise<UserProfile> {
  return client.get("user/profile").then((res) => res.data as UserProfile);
}

export function editProfile(p: UserProfileBody): Promise<UserProfile> {
  return client.put("user/profile", p).then((res) => res.data as UserProfile);
}


export function getOrders(): Promise<UserOrderCmp[]> {
  return client.get("user/orders").then((res) => res.data as UserOrderCmp[]);
}

export function getOrderDetail(id: string): Promise<UserOrder> {
  return client.get(`user/orders/${id}`).then((res) => res.data as UserOrder);
}

export function deleteOrder(id: string): Promise<void> {
  return client.delete(`user/orders/${id}`);
}


export function getOrderDetailForCart(id: string): Promise<UserCartOrder> {
  return client.get(`user/orders/${id}/cart`).then((res) => res.data as UserCartOrder);
}

export function getSubscriptions(): Promise<UserSubscriptionCmp[]> {
  return client.get("user/subscriptions").then((res) => res.data as UserSubscriptionCmp[]);
}

export function getSubscriptionDetail(id: string): Promise<UserSubscription> {
  return client.get(`user/subscriptions/${id}`).then((res) => res.data as UserSubscription);
}

export function restartSubscription(id: string): Promise<UserSubscription> {
  return client.post(`user/subscriptions/${id}/restart`).then((res) => res.data as UserSubscription);
}
export function pauseSubscription(id: string): Promise<UserSubscription> {
  return client.post(`user/subscriptions/${id}/pause`).then((res) => res.data as UserSubscription);
}
export function stopSubscription(id: string): Promise<UserSubscription> {
  return client.post(`user/subscriptions/${id}/stop`).then((res) => res.data as UserSubscription);
}

export function getDefaultShippingAddress(): Promise<UserAddress | undefined> {
  return client
    .get("user/addresses/shipping/default")
    .then((res) => {
      // MEMO: Fix valore di ritorno da backend
      if (res.data == '') return undefined;
      return res.data as UserAddress
    });
}
export function getShippingAddresses(): Promise<UserAddress[]> {
  return client
    .get("user/addresses/shipping")
    .then((res) => res.data as UserAddress[]);
}

export function addShippingAddress(ua: UserAddressBody): Promise<UserAddress | undefined> {
  return client
    .post("user/addresses/shipping", ua)
    .then((res) => {
      // MEMO: Fix valore di ritorno da backend
      if (res.data == '') return undefined;
      return res.data as UserAddress
    });
}

export function getDefaultBillingAddress(): Promise<UserAddress | undefined> {
  return client
    .get("user/addresses/billing/default")
    .then((res) => {
      // MEMO: Fix valore di ritorno da backend
      if (res.data == '') return undefined;
      return res.data as UserAddress
    });
}

export function getBillingAddresses(): Promise<UserAddress[]> {
  return client
    .get("user/addresses/billing")
    .then((res) => res.data as UserAddress[]);
}

export function addBillingAddress(ua: UserAddressBody): Promise<UserAddress | undefined> {
  return client
    .post("user/addresses/billing", ua)
    .then((res) => {
      // MEMO: Fix valore di ritorno da backend
      if (res.data == '') return undefined;
      return res.data as UserAddress
    });
}

export function editAddress(
  uaId: string,
  ua: UserAddressBody
): Promise<UserAddress | undefined> {
  return client
    .put(`user/addresses/${uaId}`, ua)
    .then((res) => {
      // MEMO: Fix valore di ritorno da backend
      if (res.data == '') return undefined;
      return res.data as UserAddress
    });
}

export function deleteAddress(
  uaId: string
): Promise<void> {
  return client.delete(`user/addresses/${uaId}`);
}
