import { useContext, useMemo, useState } from "react";
import CartElement from "./CartElement";
import api from "api";
import { CouponType } from "models/coupon";
import { useCartStore } from "store/cartStore";

type PropsType = {
};

export default ({ }: PropsType) => {

    const data = useCartStore(state => state.data);
    const selectedShippingAddress = useCartStore(state => state.selectedShippingAddress);
    const selectedBillingAddress = useCartStore(state => state.selectedBillingAddress);
    const selectedTimeSlot = useCartStore(state => state.selectedTimeSlot);
    const selectedType = useCartStore(state => state.selectedType);
    const selectedShippingMethod = useCartStore(state => state.selectedShippingMethod);
    const selectedCoupon = useCartStore(state => state.selectedCoupon);
    const selectCoupon = useCartStore(state => state.selectCoupon);
    const removeCoupon = useCartStore(state => state.removeCoupon);
    const calcCartTotal = useCartStore(state => state.calcCartTotal);

    // TODO: Inserire come elemento del carrello il codice promozionale
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const [couponCodeInserted, setCouponInserted] = useState(selectedCoupon != null);
    const [couponCode, setCouponCode] = useState(selectedCoupon?.code);

    const handleConfirmCoupon = () => {
        if (couponCode != null && selectedTimeSlot != null && selectedShippingAddress != null && selectedBillingAddress != null && selectedShippingMethod != null && selectedType != null) {
            api.orders.checkCouponCode(
                couponCode,
                selectedTimeSlot?.id,
                selectedTimeSlot.day,
                selectedTimeSlot.month,
                selectedTimeSlot.year,
                selectedShippingAddress?.id,
                selectedBillingAddress?.id,
                selectedShippingMethod?.id,
                selectedType,
                data).then(res => {
                    selectCoupon(res);
                    setCouponInserted(true);
                    calcCartTotal();

                }).catch(err => {
                    setCouponInserted(true);
                    removeCoupon();
                    setErrorMsg(err?.response?.data?.message ?? err.message)
                })
        }
    }
    const handleRemoveCoupon = () => {
        setCouponCode('');
        setCouponInserted(false);
        setErrorMsg(undefined);
        removeCoupon();
        calcCartTotal();
    }

    return <>
        <div className="input-overlap">
            <input className="form-control form-dotted" value={couponCode} onChange={(e) => setCouponCode(e.currentTarget.value)} disabled={couponCodeInserted} type="text" placeholder="Codice promozionale" />
            <div className="input-overlap-container">
                {
                    couponCodeInserted ?
                        <button className="btn btn-icon btn-primary" onClick={() => handleRemoveCoupon()}><span className="material-symbols-outlined fill-1">cancel</span></button> :
                        <button className="btn btn-primary btn-sm" onClick={() => handleConfirmCoupon()} disabled={couponCode == ""}>Applica</button>
                }
            </div>
        </div>
        {
            errorMsg != null ?
                <div className="form-message error-message">
                    <i className="material-symbols-outlined">cancel</i>
                    <span className="form-message-label">{errorMsg}</span>
                </div> :
                null
        }
    </>
}