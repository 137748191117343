import { ICartElement, SimpleCartElement } from "models/cart";

export enum AnalyticsActionType {
    PAGE_VIEW = 'pageview'
}

export enum AnalyticsEventActionType {
    CLICK = 'click',
    SCROLL = 'scroll'
}

export enum AnalyticsActionCategory {
    BUTTON = 'button clicks',
    SUBMIT = 'form sumbissions',
    FILTER = 'filter submissions',
    LOAD = 'load data',
    NAVIGATION= 'page landing'
}


export type GaCartData = {

    cartData?: ICartElement[];
    cartTotal?: number;
    addedItem?: object;
    removedItem?: object;
    count?: number;

}