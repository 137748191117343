import { useContext, useEffect } from "react";
import api from "api";
import { Link } from "react-router-dom";
import { getPublicImageUrl } from "utilities/imageUtils";
import urlUtils from "utilities/urlUtils";
import AppFooter from "components/layout/AppFooter";
import { UserLoginTypeEnum } from "models/user";
import { useAuthStore } from "store/authStore";
import analyticsUtils from "utilities/analyticsUtils";

type MenuElemPropsType = {
  label: string;
  imagePath: string;
  url: string;
};

const MenuElement = ({ label, imagePath, url }: MenuElemPropsType) => {
  return <Link to={url} className="shadow-box d-flex align-items-center justify-content-between text-secondary py-3 ps-3 pe-2">
    <div className="d-flex align-items-center gap-3">
      <img src={getPublicImageUrl(imagePath)} width="54px" height="auto" className="img-fluid" />
      <h4>{label}</h4>
    </div>

    <span className="material-symbols-outlined">chevron_right</span>

  </Link>;
}

export default () => {
  const user = useAuthStore(state => state.user);
  const clearUser = useAuthStore(state => state.clearUser);

  const onLogoutHandle = () => {
    api.auth.logout().then(() => {
      clearUser();
    })
  }

  if (user == null) {
    return null;
  }
  return <div className="flex-grow-1 overflow-auto d-flex flex-column">
    <div className="main-container d-flex flex-column flex-grow-1">
      <div className="container container-close gutter-lg">
        <div className="row">
          <div className="col-12">
            <span className="h1">Ciao {user.name}</span>
          </div>
        </div>
      </div>

      <div className="container container-close mt-custom page-full-child flex-grow-1 mtSpace-1875">
        <div className="row row-gap-3">
          <div className="col-12 col-lg-6">
            <MenuElement label="Il mio profilo" imagePath={"my-profile.svg"} url={urlUtils.user.profile()} />
          </div>

          <div className="col-12 col-lg-6">
            <MenuElement label="I miei indirizzi" imagePath={"my-addresses.svg"} url={urlUtils.user.addresses.main()} />
          </div>

          <div className="col-12 col-lg-6">
            <MenuElement label="I miei ordini" imagePath={"my-orders.svg"} url={urlUtils.user.orders.main()} />
          </div>

          <div className="col-12 col-lg-6">
            <MenuElement label="Le mie sottoscrizioni" imagePath={"my-subscrittion.svg"} url={urlUtils.user.subscriptions.main()} />
          </div>

          {user.type === UserLoginTypeEnum.APP ? <div className="col-12 col-lg-6">
            <MenuElement label="Cambia password" imagePath={"change-password.svg"} url={urlUtils.user.changePassword()} />
          </div> : null}
        </div>
      </div>

      <div className="container py-4">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <div className="d-inline-flex align-items-center gap-2 text-primary">
                <button onClick={onLogoutHandle} className="btn btn-link btn-primary">Log out</button>
                <i className="driink-logout"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><div className="d-none d-lg-block">
      <AppFooter />
    </div>
  </div>;
}
