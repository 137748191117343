import { OrderCartItemDetailBodyType } from "api/orders/types";
import { ShopItemDetailType } from "api/shop/types";
import {
  BoxCartElement,
  ICartElement,
  SimpleCartElement,
} from "models/cart";
import { PRODUCT_ENUM } from "constants/common";
import _ from "lodash";
import { UserOrderBox, UserOrderItemDetail } from "models/user";

export const getGroupedByData = (elems: ShopItemDetailType[]) => {
  const gb = _.groupBy(elems, (elem) => elem.id);
  return Object.keys(gb).map((elemId) => {
    const found = elems.find((elem) => elem.id === elemId);

    return {
      elem: found!,
      count: gb[elemId].length,
    };
  });
};


// const getPricePerc = (price: number, perc: number) => {
//   return price * (perc / 100);
// };

// const getPriceDiscount = (
//   price: number,
//   discount: number | null,
//   isDiscountAbsolute: boolean
// ) => {
//   if (!discount) {
//     return 0;
//   }
// 
//   if (!isDiscountAbsolute) {
//     return getPricePerc(price, discount);
//   } else {
//     return discount;
//   }
// };

const getPrice = (
  price: number,
  priceWithoutDiscount: number,
  count: number,
  discount: number | null,
  isDiscountAbsolute: boolean,
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {
  let singleElemPrice = price;

  if (withoutDiscount) {
    singleElemPrice = priceWithoutDiscount;
  } else if (onlyDiscount) {
    singleElemPrice = price - priceWithoutDiscount;
  }

  let totPrice = singleElemPrice * count;

  return totPrice;
};

export const getShopItemDetailPrice = (
  data: ShopItemDetailType,
  count: number,
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {

  return getPrice(
    data.price,
    data.priceWithoutDiscount,
    count,
    data.discount,
    data.discountAbsolute,
    withoutDiscount,
    onlyDiscount
  );
};

export const getUserOrderItemDetailPrice = (
  data: UserOrderItemDetail,
  count: number,
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {
  return getPrice(
    data.price,
    data.priceWithoutDiscount,
    count,
    data.discount,
    data.discountAbsolute,
    withoutDiscount,
    onlyDiscount
  );
};

export const getCartElementPrice = (
  data: ICartElement,
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {
  if (data.type === PRODUCT_ENUM.SIMPLE) {
    const elemData: SimpleCartElement = data as SimpleCartElement;

    return getShopItemDetailPrice(
      elemData.elem,
      elemData.count,
      withoutDiscount,
      onlyDiscount
    );
  } else if (data.type === PRODUCT_ENUM.BOX) {
    const elemData: BoxCartElement = data as BoxCartElement;

    let totalCount = 0;
    elemData.elems.forEach(
      (e) =>
      (totalCount += getShopItemDetailPrice(
        e,
        1,
        withoutDiscount,
        onlyDiscount
      ))
    );
    totalCount *= elemData.count;
    return totalCount;
  } else {
    return 0;
  }
};

export const getUserOrderBoxPrice = (
  elemData: UserOrderBox,
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {
  let totalCount = 0;
  elemData.itemDetails.forEach(
    (e) =>
    (totalCount += getUserOrderItemDetailPrice(
      e,
      e.count,
      withoutDiscount,
      onlyDiscount
    ))
  );
  totalCount *= elemData.count;
  return totalCount;
};

/*
export const getCartPrice = (
  data: ICartElement[],
  withoutDiscount: boolean = false,
  onlyDiscount: boolean = false
) => {
  let total = 0;
  data.forEach((d) => {
    total += getCartElementPrice(d, withoutDiscount, onlyDiscount);
  });
  return total;
};
 */