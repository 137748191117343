import { PRODUCT_ENUM } from "constants/common";
import { BoxCartElement, ICartElement, SimpleCartElement } from "models/cart";
import { useContext } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import { convertToCurrency } from "utilities/convertUtils";
import { useCartStore } from "store/cartStore";
import { getCartElementPrice, getGroupedByData } from "utilities/shopUtils";
import urlUtils from "utilities/urlUtils";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useShopStore } from "store/shopStore";

type PropsType = {
    data: ICartElement;
    showProductIcon?: boolean;
};

export default ({ data, showProductIcon }: PropsType) => {
    const showIcon = showProductIcon;
    if (data.type === PRODUCT_ENUM.SIMPLE) {
        const elemData: SimpleCartElement = data as SimpleCartElement;

        return <SimpleElemCmp data={elemData} showProductIcon={showIcon} />;
    } else if (data.type === PRODUCT_ENUM.BOX) {
        const elemData: BoxCartElement = data as BoxCartElement;
        return <BoxElemCmp data={elemData} showProductIcon={showIcon} />;
    }
    return null;


};



type SimpleElemCmpPropsType = {
    data: SimpleCartElement;
    showProductIcon?: boolean;
};
const SimpleElemCmp = ({ data, showProductIcon }: SimpleElemCmpPropsType) => {

    const addToCart = useCartStore(state => state.addToCart);
    const removeToCart = useCartStore(state => state.removeToCart);

    const { brand, name, info } = data.elem;
    const count = data.count;

    const handleAddToCart = () => {
        let selectedBox = null;
        // MEMO: Se aggiungo in questo punto non è in un box
        // if (data.elem.enabledBoxes != null && data.elem.enabledBoxes.length > 0) {
        //   const selBoxId = data.elem.enabledBoxes[0];
        //   // TODO: Gestire casi più box abilitati
        //   selectedBox = shopState.boxes?.find((b) => b.id == selBoxId);
        // }
        // console.log("addToCart", data.elem, selectedBox);
        addToCart({ item: data.elem, selectedBox: selectedBox, boxKey: undefined });
    };

    const handleRemoveToCart = () => {
        removeToCart({ item: data.elem, boxKey: undefined });
    };
    return (
        <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column">
                {/* <!-- item - left right section container  --> */}
                <div className="d-flex align-items-center justify-content-between">
                    {/* <!-- item - left section --> */}
                    <div className="d-flex align-items-center gap-3">
                        {/* <!-- item image placeholder --> */}
                        {showProductIcon ?
                            <div className="product-placeholder">
                                <img
                                    src={
                                        data.elem.isMultiple
                                            ? getPublicImageUrl(`item-icon/multiple-bottle.svg`)
                                            : getPublicImageUrl(`item-icon/bottle.svg`)
                                    }
                                />
                            </div>
                            : null}
                        {/* <!-- item info --> */}
                        <div className="d-flex flex-column">
                            <small>{brand}</small>

                            <div className="text-primary d-flex align-items-center gap-2">
                                <p className="fw-extrabold">{name}</p>
                            </div>

                            <small className="mt-1">{info}</small>
                        </div>
                    </div>

                    {/* <!-- item - right section --> */}
                    <div className="d-flex align-items-end flex-column gap-2">
                        {/* <!-- item - items selection --> */}

                        <div className="number-selector">
                            <button
                                className="btn btn-action"
                                data-number-selector="remove"
                                onClick={() => handleRemoveToCart()}
                                disabled={count === 0}
                            >
                                <span className="material-symbols-outlined fill-1 text-primary">
                                    do_not_disturb_on
                                </span>
                            </button>

                            <span className="number-selector-items">{count}</span>

                            <button
                                className="btn btn-action"
                                data-number-selector="add"
                                disabled={false}
                                onClick={() => handleAddToCart()}
                            >
                                <span className="material-symbols-outlined text-primary">
                                    add_circle
                                </span>
                            </button>
                        </div>

                        {/* <!-- item - price - discount --> */}
                        <div className="d-flex align-items-center gap-2">
                            <p className="text-secondary fw-bold">
                                {convertToCurrency(getCartElementPrice(data))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type BoxCartElementPropsType = {
    data: BoxCartElement;
    showProductIcon?: boolean;
};
const BoxElemCmp = ({ data, showProductIcon }: BoxCartElementPropsType) => {
    const navigate = useNavigate();

    const increaseBoxCountToCart = useCartStore(state => state.increaseBoxCountToCart);
    const decreaseBoxCountToCart = useCartStore(state => state.decreaseBoxCountToCart);
    const unconfirmBox = useCartStore(state => state.unconfirmBox);
    const setExpandedBoxKey = useShopStore(state => state.setExpandedBoxKey);

    const addBoxToCart = () => {
        console.log("addBoxToCart", data)
        increaseBoxCountToCart({ boxKey: data.key });
    };
    const removeBoxToCart = () => {
        decreaseBoxCountToCart({ boxKey: data.key })
    };
    const handleRemoveToCart = () => {
        console.log("box nel click RemoveCart", data);
        unconfirmBox({ boxKey: data.key });
        setExpandedBoxKey(data.key);

        navigate(urlUtils.main());
    };
    return (
        <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column">
                {/* <!-- item - left right section container  --> */}
                <div className="d-flex align-items-center justify-content-between">
                    {/* <!-- item - left section --> */}
                    <div className="d-flex align-items-center gap-3">
                        {/* <!-- item image placeholder --> */}
                        {showProductIcon ?
                            <div className="product-placeholder">
                                <img src={getPublicImageUrl(`item-icon/pack.svg`)} />
                            </div>
                            : null}
                        {/* <!-- item info --> */}
                        <div className="d-flex flex-column">

                            <div className="text-primary d-flex align-items-center gap-2">
                                <p className="fw-extrabold">Cassa</p>

                                <a
                                    className="btn btn-action p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    href={`#collapseComponent-${data.key}`}
                                    aria-expanded="false"
                                    aria-controls="collapseComponent"
                                >
                                    <i className="material-symbols-outlined">expand_less</i>
                                </a>
                            </div>

                        </div>
                    </div>

                    {/* <!-- item - right section --> */}
                    <div className="d-flex align-items-end flex-column gap-2">
                        {/* <!-- item - items selection --> */}

                        <div className="number-selector">
                            {data.count == 1 ? (
                                <button
                                    className="btn btn-action"
                                    data-number-selector="delete"
                                    onClick={() => removeBoxToCart()}
                                >
                                    <span className="driink-trash-circle text-danger"></span>
                                </button>
                            ) : (
                                <button
                                    className="btn btn-action"
                                    data-number-selector="remove"
                                    onClick={() => removeBoxToCart()}
                                >
                                    <span className="material-symbols-outlined fill-1 text-primary">
                                        do_not_disturb_on
                                    </span>
                                </button>
                            )}

                            <span className="number-selector-items">{data.count}</span>

                            <button
                                className="btn btn-action"
                                data-number-selector="add"
                                onClick={() => addBoxToCart()}
                            >
                                <span className="material-symbols-outlined text-primary">
                                    add_circle
                                </span>
                            </button>
                        </div>

                        {/* <!-- item - price - discount --> */}
                        <div className="d-flex align-items-center gap-2">
                            <p className="text-secondary fw-bold">
                                {convertToCurrency(getCartElementPrice(data))}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="collapse"
                    id={`collapseComponent-${data.key}`}
                >
                    <div className="d-flex flex-column gap-3">
                        <div
                            className="cart-box-container mt-custom d-flex flex-column gap-2"
                        /* style="--mt-space: 0.75rem;" */
                        >
                            {getGroupedByData(data.elems).map((o) => (
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                    <div className="d-flex flex-column">
                                        <small>{o.elem.brand}</small>

                                        <p className="fw-extrabold" style={{ color: "#26267D" }}>{o.elem.name}</p>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <p>{o.count}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <a
                            onClick={() => handleRemoveToCart()}
                            className="cart-box-edit d-inline-flex align-items-center gap-custom"
                        /* style="--g-space: 0.75rem;" */
                        >
                            <span className="material-symbols-outlined  fill-1">edit</span>

                            <span className="fw-extrabold">Modifica Box</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
