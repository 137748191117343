import { useContext } from "react";
import { LocationType } from "models/location";

type PropsType = {
  location: LocationType | undefined | null;
  token: string | undefined | null;
  onConfirm: () => void;
};

export default ({ location, token, onConfirm }: PropsType) => {
  return (
    <>
      <div className="container my-3 gutter-lg">
        <h1 className="mb-3">
          Evviva, arriviamo a {location?.city}, {location?.province}!
        </h1>
        <p>
          Conferma per aggiungerla alla lista dei tuoi indirizzi di spedizione.
        </p>
      </div>

      <div className="container my-3">
        <div className="row">
          <div className="col-12">
            <a
              onClick={() => onConfirm()}
              className="btn btn-primary btn-md btn-full"
            >
              Conferma la nuova località
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
