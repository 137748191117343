import LoginForm from "components/auth/LoginForm";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "store/appStore";
import { useAuthStore } from "store/authStore";
import urlUtils from "utilities/urlUtils";

type PropsType = {
};

export default ({ }: PropsType) => {
    const navigate = useNavigate();

    const user = useAuthStore(state => state.user);
    const confirmedLocation = useAppStore(state => state.confirmedLocation);

    const isUserLogged = () => user != null && user.id != null
    const [userIsLogged, setUserIsLogger] = useState(isUserLogged());
    useEffect(() => {
        setUserIsLogger(isUserLogged())
    }, [user?.id])

    if (userIsLogged) {
        navigate(urlUtils.main());
    }

    const onLoginCompleted = () => {
        navigate(urlUtils.main());
    }
    return <main className="main-container lg-center-container overflow-auto">
        <div className="container">
            <div className="row gy-5 align-items-lg-center flex-row-reverse justify-content-between">
                <div className="col-12 col-lg-7">
                    <div className="text-center">
                        <img src="/img/login/home-login-ok.svg" className="img-fluid" width="100%" height="auto" />
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div className="vstack gap-3 gap-lg-4">
                        <div className="px-3 px-lg-0">
                            <h1 className="mb-3">Evviva, arriviamo a {confirmedLocation?.city}!</h1>
                            <p>Procedi con la registrazione o il login.</p>
                        </div>

                        <LoginForm onLoginCompleted={onLoginCompleted} />

                        <div className="text-center">

                            <Link to={urlUtils.main()} className="btn btn-link btn-primary">
                                Procedi come ospite
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
}