
import api from "api";
import { useEffect, useState, useContext } from "react";
import LocationCheckOK from "./LocationCheckOK";
import LocationCheckKO from "./LocationCheckKO";
import { LocationType } from "models/location";
import { convertToLocationString } from "utilities/convertUtils";
import { useAppStore } from "store/appStore";
import LocationSelectionCombobox from "./LocationSelectionCombobox";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType } from "utilities/analyticsUtils/types";


type PropsType = {
  useAppLocationAsDefault?: boolean,
  onChange: (location: LocationType, token: string) => void,
  endWizardAtConfirm?: boolean;
  onEndWizard: () => void
};

export default ({ onChange, onEndWizard, endWizardAtConfirm = true, useAppLocationAsDefault = true }: PropsType) => {
  const [locationSearch, setLocationSearch] = useState<LocationType | undefined>();

  const confirmedToken = useAppStore(state => state.confirmedToken);
  const confirmedLocation = useAppStore(state => state.confirmedLocation);

  const [locationToConfirm, setLocationToConfirm] = useState<
    LocationType | undefined | null
  >(undefined);
  const [tokenToConfirm, setTokenToConfirm] = useState<string | undefined | null>(
    undefined
  );
  const [checkStatus, setCheckStatus] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    // MEMO: All'apertura resetto lo stato
    setCheckStatus(undefined);
  }, []);


  const [enabledLocations, setEnabledLocations] = useState<
    LocationType[]
  >([]);
  useEffect(() => {
    api.location.get({
      page: 0,
      pageSize: 10
    }).then(res => setEnabledLocations(res.content));
  }, []);

  useEffect(() => {
    if (useAppLocationAsDefault) {
      setLocationToConfirm(confirmedLocation);
      setTokenToConfirm(confirmedToken);
    } else {
      setLocationToConfirm(undefined);
      setTokenToConfirm(undefined);
    }
  }, [useAppLocationAsDefault]);

  const onCheckLocation = () => {
    if (locationSearch?.key != null) {
      api.location
        .checkLocationByLocationKey(locationSearch.key)
        .then((res) => {
          setLocationToConfirm(res.location);
          setTokenToConfirm(res.token);
          setCheckStatus(true);
        })
        .catch(() => {
          setCheckStatus(false);
        });
    }
  };

  const onConfirmLocation = () => {
    console.log("onConfirmLocation", locationToConfirm, tokenToConfirm)
    if (locationToConfirm != null && tokenToConfirm != null) {
      onChange(locationToConfirm, tokenToConfirm);

      if (endWizardAtConfirm) {
        onEndWizard();
      }
      analyticsUtils.sendEvent(AnalyticsActionCategory.SUBMIT, AnalyticsEventActionType.CLICK, "confirm new location", locationToConfirm)
    }
  }

  if (checkStatus === true) {
    return <LocationCheckOK
      location={locationToConfirm}
      token={tokenToConfirm}
      onConfirm={() => onConfirmLocation()}
    />
  } else if (checkStatus === false) {
    // TODO: Vedere come gestire!
    return <LocationCheckKO postalCode={locationSearch!.postalCode!} forceClose={() => onEndWizard()} />
  }
  else {
    return <>
      <div className="container gutter-lg">
        {confirmedLocation != null ?
          <>
            <p className="mb-3 fw-extrabold">
              Vuoi inviare in un’altra località diversa da{" "}
              {convertToLocationString(confirmedLocation, false)}?
            </p>
            <p>Scopri se il servizio è disponibile inserendo il nome della nuova località</p>
          </>
          : <p>Per prima cosa, inserisci il CAP e scopri se il servizio è disponibile.</p>}
      </div>

      <div className="container my-4">
        <div className="row">
          <div className="col-12">
            <div className="input-group chk-location-input-group">

              <LocationSelectionCombobox value={locationSearch} onChange={(e) => setLocationSearch(e)} />

              {/* <input
                type="text"
                className="form-control"
                placeholder="es. 23459"
                value={cap}
                onChange={(e) => setLocationSearch(e.target.value)}
                id=""
              /> */}

              <button disabled={locationSearch == null} onClick={() => onCheckLocation()} className="btn btn-primary">
                Verifica copertura
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}