import api from "api";
import { useState } from "react";
import { validateEmail } from "utilities/funcUtils";
import { getPublicImageUrl } from "utilities/imageUtils";

type PropsType = {
  postalCode: string;
  forceClose: () => void;
};

export default ({ postalCode, forceClose }: PropsType) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const sendEmail = () => {
    if (validateEmail(email)) {
      api.location.notification(email, postalCode).then(() => forceClose());
    } else {
      setEmailError("Inserire una email valida");
    }
  };
  return (
    <>
      <div className="container my-3 gutter-lg">
        <div className="text-center mb-3">
          <img src={getPublicImageUrl(`sad-bottle.svg`)} width="60px" height="auto" />
        </div>

        <h1 className="mb-3">
          Noooooo!
          <br />
          Sei a troppi litri di distanza..
        </h1>
        <p>
          Purtroppo non effettuiamo ancora consegne nella tua zona, ma siamo
          certi che presto le cose cambieranno!
        </p>
        <p>Lasciaci la tua e-mail, ti avviseremo non appena sarà possibile.</p>
      </div>

      <div className="container my-3">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column gap-2">
              <div className="form-floating form-icon">
                <input
                  type="email"
                  className={`form-control ${emailError != null ? 'is-invalid' : ''}`}
                  id="input-id"
                  placeholder="Inserisci la tua email"
                  value={email}
                  onChange={(e) => {
                    setEmailError(null);
                    setEmail(e.target.value);
                  }}
                />

                <div /* for="input-id" */ className="icon">
                  <i className="driink-email"></i>
                </div>

                <label /* for="input-id" */>Inserisci la tua email</label>

              </div>
              {emailError != null && <div className="text-danger">{emailError}</div>}


              <a
                onClick={() => {
                  setEmailError(null);
                  sendEmail();
                }}
                className="btn btn-primary btn-full"
              >
                Invia
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
