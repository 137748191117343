import api from "api";
import { ShopItemDetailType, ShopItemType } from "api/shop/types";
import { ShopCategoryEnum } from "constants/shop";
import { useContext, useEffect, useState } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import { convertToCurrency } from "utilities/convertUtils";
import { useShopStore } from "store/shopStore";
import { useCartStore } from "store/cartStore";

type PropsType = {
    data: ShopItemDetailType;
};

export default ({ data }: PropsType) => {
    const { count, addToCart, removeToCart } = useCartStore();

    const boxes = useShopStore(state => state.boxes);

    const handleAddToCart = () => {
        let selectedBox = null;
        if (data.enabledBoxes != null && data.enabledBoxes.length > 0) {
            const selBoxId = data.enabledBoxes[0];
            // TODO: Gestire casi più box abilitati
            selectedBox = boxes?.find((b) => b.id == selBoxId);
        }
        addToCart({ item: data, selectedBox: selectedBox, boxKey: undefined });
    };

    const handleRemoveToCart = () => {
        removeToCart({ item: data, boxKey: undefined });
    };

    return <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column">
            <div className="box-item-body">
                <div className="box-item-body-info d-flex align-items-center gap-3">
                    <div className="product-placeholder">

                        <img
                            src={
                                data.isMultiple
                                    ? getPublicImageUrl(`item-icon/multiple-bottle.svg`)
                                    : getPublicImageUrl(`item-icon/bottle.svg`)
                            }
                        />

                    </div>

                    <div className="d-flex flex-column">
                        <small>{data.brand}</small>

                        <div className="text-primary d-flex align-items-center gap-2">
                            <p className="fw-extrabold">{data.name}</p>

                        </div>

                        <small className="mt-1">{data.info}</small>
                    </div>
                </div>

                <div className="box-item-body-price d-flex align-items-end flex-column gap-2">



                    <div className="number-selector">

                        <button
                            className="btn btn-action"
                            disabled={count.elems?.[data.id] === 0 ?? false}
                            onClick={() => handleRemoveToCart()}
                        >
                            <span className="material-symbols-outlined text-primary">do_not_disturb_on</span>
                        </button>

                        <span className="number-selector-items">{count.elems?.[data.id] ?? 0}</span>

                        <button
                            className="btn btn-action"
                            disabled={false}
                            onClick={() => handleAddToCart()}
                        >
                            <span className="material-symbols-outlined text-primary">add_circle</span>
                        </button>

                    </div>



                    <div className="d-flex align-items-center gap-2">


                        {data.discount != null && data.discount > 0 ? (
                            <span className="badge badge-sm bg-third">
                                Sconto{" "}
                                {data.discountAbsolute
                                    ? `${data.discount}€`
                                    : `${data.discount}%`}
                            </span>
                        ) : null}


                        <p className="text-secondary fw-bold">{convertToCurrency(data.price)}</p>
                    </div>
                </div>
            </div>


        </div>
    </div>
}