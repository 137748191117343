import StoreCartLayout from "components/layout/StoreCartLayout";
import CalendarCard from "components/timeslots/CalendarCard";
import { DaySlotType, TimeSlotWithDateType } from "models/timeslots";
import moment from "moment";
import { useState, useEffect, useContext } from 'react';
import { getPublicImageUrl } from "utilities/imageUtils";
import urlUtils from "utilities/urlUtils";
import api from "api";
import SelectShippingAddressCard from "components/user/SelectShippingAddressCard";
import { UserAddress } from "models/user";
import { useCartStore } from "store/cartStore";
import SelectBillingAddressCard from "components/user/SelectBillingAddressCard";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsEventActionType, GaCartData } from "utilities/analyticsUtils/types";

export default () => {

  const selectedShippingAddress = useCartStore(state => state.selectedShippingAddress);
  const selectedBillingAddress = useCartStore(state => state.selectedBillingAddress);
  const selectedTimeSlot = useCartStore(state => state.selectedTimeSlot);
  const selectShippingAddress = useCartStore(state => state.selectShippingAddress);
  const selectBillingAddress = useCartStore(state => state.selectBillingAddress);
  const selectTimeslot = useCartStore(state => state.selectTimeslot);
  const cartTotal = useCartStore(state => state.cartTotal?.total);
  const cartdata = useCartStore(state => state.data);


  const [daysZones, setDaysZones] = useState<DaySlotType[] | undefined>();
  useEffect(() => {
    const start = moment().add(1, 'weeks').startOf('isoWeek');
    const end = moment().add(1, 'weeks').endOf('isoWeek').add(-1, 'days');

    if (selectedShippingAddress?.locationId != null) {
      api.timeSlots.get(start, end, selectedShippingAddress?.locationId).then((res) => setDaysZones(res));
    }
  }, [selectedShippingAddress?.locationId]);

  const setSelectedTimeSlot = (sa: TimeSlotWithDateType | undefined) => {
    selectTimeslot(sa);
  };

  useEffect(() => {

    const newGaCartData: GaCartData = {
      cartData: cartdata,
      cartTotal: cartTotal
    };

    analyticsUtils.sendEvent(AnalyticsActionCategory.NAVIGATION, AnalyticsEventActionType.CLICK, "start checkout process", newGaCartData)
  }, []);

  useEffect(() => {
    if (selectedShippingAddress == null) {
      // TODO: Imposto l'indirizzo di default
      api.user.getDefaultShippingAddress().then((res) => {
        if (res != null) {
          console.log("setSelectedShippingAddress", res)
          setSelectedShippingAddress(res);
        }
      });
    }
    if (selectedBillingAddress == null) {
      // TODO: Imposto l'indirizzo di default
      api.user.getDefaultBillingAddress().then((res) => {
        if (res != null) {
          console.log("setSelectedBillingAddress", res)
          setSelectedBillingAddress(res);
        }
      });
    }
  }, []);

  const setSelectedShippingAddress = (sa: UserAddress | undefined) => {
    selectShippingAddress(sa);
  };
  const setSelectedBillingAddress = (sa: UserAddress | undefined) => {
    selectBillingAddress(sa);
  };

  console.log("selectedShippingAddress, selectedBillingAddress, selectedTimeSlot", selectedShippingAddress, selectedBillingAddress, selectedTimeSlot)

  return <StoreCartLayout
    containerClosed
    title="Concorda la consegna"
    showBack
    backLabel="Indietro"
    labelNextStep="Al pagamento"
    urlNextStep={urlUtils.cartType()}
    urlNextStepDisabled={selectedShippingAddress == null || selectedBillingAddress == null || selectedTimeSlot == null}
  >
    <>
      <SelectShippingAddressCard onChange={setSelectedShippingAddress} />
      <SelectBillingAddressCard onChange={setSelectedBillingAddress} />

      <CalendarCard locationId={selectedShippingAddress?.locationId} data={daysZones} onChange={setSelectedTimeSlot} />
    </>
  </StoreCartLayout>;
}
