import { UserOrderItemDetail } from "models/user";
import { convertToCurrency } from "utilities/convertUtils";
import { getPublicImageUrl } from "utilities/imageUtils";

type PropsType = {
  data: UserOrderItemDetail;
};

export default ({ data }: PropsType) => {
  return (

    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column">

        <div className="box-item-body box-item-body--three-columns">
          <div className="box-item-body-info d-flex align-items-center gap-3">
            <div className="product-placeholder">
              <img src={getPublicImageUrl("item-icon/multiple-bottle.svg")} />
            </div>

            <div className="d-flex flex-column">
              <small>{data.name}</small>

              <div className="text-primary d-flex align-items-center gap-2">
                <p className="fw-extrabold">{data.detailName}</p>

              </div>

              <small className="mt-1">{data.detailInfo}</small>
            </div>
          </div>

          <div className="box-item-body-number-item">
            <p className="mb-0">x <span className="fw-extrabold">{data.count}</span></p>
          </div>

          <div className="box-item-body-price fw-bold">
            <p className="mb-0">{convertToCurrency(data.price)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
