import FormField from "components/common/form/FormField";
import LocationInput from "components/location/LocationInput";
import LocationSelectionCombobox from "components/location/LocationSelectionCombobox";
import { useFormikContext } from "formik";
import { LocationType } from "models/location";
import { useEffect, useMemo, useState } from "react";
import { useAppStore } from "store/appStore";

type PropsType = {
  prefix?: string;
  errors?: any;
  touched?: any;
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur?: any;
  hideDefaultCheck?: boolean;
  disabled?: boolean;
  locationFieldDisabled?: boolean;
  locationFieldFromCB?: boolean;
  defaultLocation?: LocationType | null;
  changeAppLocationSelection?: boolean;
};

export default ({
  prefix,
  errors,
  touched,
  onChange,
  onBlur,
  disabled = false,
  hideDefaultCheck = false,
  locationFieldDisabled = false,
  locationFieldFromCB = false,
  defaultLocation
}: PropsType) => {
  const getFullFieldName = (fName: string) =>
    prefix != null && prefix != "" ? `${prefix}.${fName}` : fName;


  console.log("defaultLocation", defaultLocation);
  console.log("locationFieldFromCB ", locationFieldFromCB);


  const formik = useFormikContext();

  const [location, setLocation] = useState<LocationType | undefined>(defaultLocation ?? undefined);

  const locationChanged = (l: LocationType | undefined) => {
    console.log("locationChanged", l)
    setLocation(l);

    console.log("defaultLocation - l", l)

    formik.setFieldValue(getFullFieldName('city'), l?.city ?? '');
    formik.setFieldValue(getFullFieldName('province'), l?.province ?? '');
    formik.setFieldValue(getFullFieldName('postalCode'), l?.postalCode ?? '');
    formik.setFieldValue(getFullFieldName('locationId'), l?.id ?? '');
  }

  useEffect(() => {
    if (locationFieldFromCB) {
      locationChanged(location);
    }
  }, [locationFieldFromCB, location])

  const isLocationSelectionComboxVisible = useMemo(() => {
    return locationFieldFromCB || defaultLocation != null;
  }, [locationFieldFromCB, defaultLocation])

  const areLocationFidleDisabled = useMemo(() => {
    return locationFieldDisabled || locationFieldFromCB;
  }, [locationFieldDisabled, locationFieldFromCB])
  return (
    <>
      <div className="d-flex flex-column gap-2">
        <FormField
          name={getFullFieldName("name")}
          label="Nome"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />

        <FormField
          name={getFullFieldName("surname")}
          label="Cognome"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />

        <FormField
          name={getFullFieldName("address")}
          label="Indirizzo"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />

        {isLocationSelectionComboxVisible && <LocationSelectionCombobox value={location} onChange={l => locationChanged(l)} fullOpacity maxHeight={200} />}

        <FormField
          name={getFullFieldName("city")}
          label="Città"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled || areLocationFidleDisabled}
        />
        <FormField
          name={getFullFieldName("province")}
          label="Provincia"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled || areLocationFidleDisabled}
        />
        <FormField
          name={getFullFieldName("postalCode")}
          label="CAP"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled || areLocationFidleDisabled}
        />
        <FormField
          name={getFullFieldName("phone")}
          label="Telefono"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />

        <FormField
          name={getFullFieldName("notes")}
          label="Note aggiuntive"
          type="textarea"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>

      {hideDefaultCheck ? null : (
        <FormField
          name={getFullFieldName("isDefault")}
          label="Imposta come indirizzo predefinito"
          type="checkbox"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}
    </>
  );
};
