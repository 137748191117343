
import api from "api";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import LocationCheckOK from "./LocationCheckOK";
import LocationCheckKO from "./LocationCheckKO";
import { LocationType } from "models/location";
import { convertToLocationString } from "utilities/convertUtils";
import { useAppStore } from "store/appStore";
import { LocationPagingQueryType } from "api/location/types";
import { debounce } from "lodash";
import { generateRandomString } from "utilities/funcUtils";



type LocationSelectionComboboxProps = {
  value: LocationType | undefined;
  fullOpacity?: boolean;
  maxHeight?: number;
  onChange: (newValue: LocationType | undefined) => void;
};

const LocationSelectionCombobox: React.FC<LocationSelectionComboboxProps> = ({ value, fullOpacity, maxHeight, onChange }) => {
  const [id, setId] = useState(generateRandomString());
  const [options, setOptions] = useState<LocationType[]>([]);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleSearch = (inputValue: string) => {
    setInputValue(inputValue);
    if (inputValue != null && inputValue.trim()) {
      setLoading(true);
      try {

        api.location.get({ search: inputValue, page: 0, pageSize: 10 }).then((res) => { setOptions(res.content) });

      } catch (error) {
        console.error('Failed to fetch locations:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  }

  const debouncedSearch = debounce(handleSearch, 30);

  const handleSelect = (selectedLocation: LocationType) => {
    onChange(selectedLocation);
    setInputValue(convertToLocationString(selectedLocation, true));
    setOptions([]);
  };

  const label = useMemo(() => "Località", []);

  return (
    <div className={'form-floating'} >
      <input
        type="text"
        id={id}
        value={inputValue}
        onChange={(e) => debouncedSearch(e.target.value)}
        // placeholder="es. Bergamo"
        // placeholder={label}
        placeholder={undefined}
        className="form-control location-form"
        style={{ position: 'relative' }}
      />
      {loading && <div>Loading...</div>}
      {options.length > 0 && (
        <ul
          className="list-group position-absolute w-100 mobile-list-group"
          style={{
            borderBottomStyle: "inset",
            zIndex: 3,
            overflowY: "auto",
            maxHeight: maxHeight != null ? `${maxHeight}%` : undefined
          }}
        >
          {options.map((option) => (
            <li
              key={option.id}
              className={`list-group-item list-group-item-action ${fullOpacity ? 'full-opacity' : ''}`}
              style={{ borderBottom: 0 }}
              onClick={() => handleSelect(option)}
            >
              {convertToLocationString(option, true)}

            </li>

          ))}
        </ul>

      )}
      <label htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default LocationSelectionCombobox;