import { ShopCategoryEnum } from "constants/shop";
import ShopMenuElem from "./ShopMenuElem"
import api from "api";
import { useContext, useEffect } from "react";
import { ShopCmpType, useShopStore } from "store/shopStore";

type PropsType = {
    value: ShopCategoryEnum;
    setValue: (val: ShopCategoryEnum) => void;
};

export default ({ value, setValue }: PropsType) => {
    const categories = useShopStore(state => state.categories);
    const enabledCategories = useShopStore(state => state.enabledCategories);
    const setEnabledCategories = useShopStore(state => state.setEnabledCategories);

    const subMenuData = categories;

    useEffect(() => {
        if (enabledCategories == null) {
            api.shop.getCategories().then((cList) => {
                setEnabledCategories(cList);
            });
        }
    }, []);

    useEffect(() => {
        if (value == null && subMenuData?.length > 0) {
            setValue(subMenuData[0].value);
        }
    }, [value, subMenuData?.length]);


    return (
        <div className="category-select-wrapper">
            <div className="category-select">
                {subMenuData?.map((sm: ShopCmpType) => (
                    <ShopMenuElem active={value === sm.value} value={sm} onClick={() => setValue(sm.value)} />
                ))}
            </div>
        </div>
    );
};










