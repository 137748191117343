import { UserProfile } from "models/user";
import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

// const TOKEN_KEY = "token";
// 
// const CLAIMS = {
//     ID: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
//     NAME: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
//     SURNAME: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
//     EMAIL: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
//     AUTH_TYPE: "http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod",
// };

type StateType = {
    token: string | null;
    user: UserProfile | null;
    setFromToken: (token: string) => void;
    setUserProfile: (user: UserProfile) => void;
    clearUser: () => void;
};


// const initialToken = localStorage.getItem(TOKEN_KEY);
// 
// if (initialToken != null) {
//     setToken(initialToken);
// }

// export const useAuthStore = create<StateType>(
//     (set) => ({
//         user: null,
//         token: null,
//         setFromToken: (token: string) => set((state) => {
//             localStorage.setItem(TOKEN_KEY, token);
//             setToken(token);
//             return { token };
//         }),
//         setUserProfile: (user: UserProfile) => set((state) => {
//             return { user };
//         }),
//         clearUser: () => set((state) => {
//             // tokenService.clearToken();
//             localStorage.removeItem(TOKEN_KEY);
//             removeToken();
//             return {
//                 user: null,
//                 token: null,
//             };
//         }),
//     })
// )

export const useAuthStore = create(
    persist<StateType>(
        (set) => ({
            user: null,
            token: null,
            setFromToken: (token: string) => set(() => {
                // localStorage.setItem(TOKEN_KEY, token);
                // setToken(token);
                return { token };
            }),
            setUserProfile: (user: UserProfile) => set(() => {
                return { user };
            }),
            clearUser: () => set((state) => {
                // tokenService.clearToken();
                // localStorage.removeItem(TOKEN_KEY);
                // removeToken();
                return {
                    user: null,
                    token: null,
                };
            }),
        }),
        {
            name: 'auth'
        }
    )
)