import ReactPixel from 'react-facebook-pixel'
import { AnalyticsActionType, AnalyticsEventActionType } from './types';
import settings from 'settings';

let enabled = false;

const advancedMatching = {
    em: 'adv@driink.it',
    ct: "",
    country: "",
    db: "",
    fn: "",
    ge: "",
    ln: "",
    ph: "",
    st: "",
    zp: "",
}
const options = {
    autoConfig: true,
    debug: false,
}

export default {
    init() {

        if (settings.facebookPixelId != null && settings.facebookPixelId != "") {
            enabled = true;
            console.log("Meta Pixel ENABLED");

            ReactPixel.init(settings.facebookPixelId, advancedMatching, options)
            ReactPixel.pageView()
            // if (hasMarketingPreference) {
            //     console.log("I've just viewed the page!")
            //     ReactPixel.pageView()
            // } else {
            //     ReactPixel.revokeConsent()
            // }
            // ReactPixel.pageView()
            // ReactPixel.revokeConsent();
            //<button onClick={ReactPixel.grantConsent}>Accept cookies</button>.

            // ReactPixel.pageView(); // For tracking page view
            // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
            // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
            // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
            // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
        } else {
            console.warn("Meta Pixel DISABLED");
            ReactPixel.revokeConsent();
        }
    },
    sendPageChanged(url: string, title: string) {
        console.log("MP sendPageChanged", enabled)
        if (!enabled) {
            return;
        }

        ReactPixel.pageView();
        // ReactPixel.trackCustom(title, {
        //     action: AnalyticsActionType.PAGE_VIEW,
        //     page: url,
        // })
    },
    sendEvent(category: string, action: AnalyticsEventActionType, label: string, customData: any) {
        console.log("MP sendEvent", enabled)
        if (!enabled) {
            return;
        }

        ReactPixel.trackCustom(category, {
            category: category,
            action: action,
            label: label,
            ...customData,
        })
    }
}