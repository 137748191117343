import api from "api";
import EmptyListCmp from "components/common/EmptyListCmp";
import ProfileLayout from "components/layout/ProfileLayout";
import OrderGroup from "components/user/OrderGroup";
import { ORDER_STATUS_LABEL } from "constants/order";
import { UserOrderCmp } from "models/user";
import { useEffect, useState } from "react";
import { sortByDescending } from "utilities/funcUtils";

export default () => {
  const [loading, setloading] = useState<boolean>(true);
  const [orders, setOrders] = useState<UserOrderCmp[]>([]);

  useEffect(() => {
    setloading(true);
    api.user.getOrders().then((orderList) => {
      const sortedOrderList = [...orderList].sort(
        (a: UserOrderCmp, b: UserOrderCmp) =>
          sortByDescending(
            new Date(a.createdAt ?? ""),
            new Date(b.createdAt ?? "")
          )
      );
      setOrders(sortedOrderList);
    }).finally(() => setloading(false));
  }, []);

  let contentCmp = null;

  if (!loading) {
    if (orders?.length > 0) {
      contentCmp = <div className="row">
        <div className="col-12">
          <div className="d-flex flex-column gap-custom mtSpace-1875 gap-4">
            {ORDER_STATUS_LABEL.map((v) => (
              <>
                <OrderGroup
                  title={v.label}
                  data={orders.filter((o) => o.status === v.value)}
                /></>
            ))}
          </div>
        </div>
      </div>;
    } else {
      contentCmp = <EmptyListCmp title="Non ci sono ancora ordini…" />;
    }
  }

  return <ProfileLayout showBack title="I miei ordini">
    {contentCmp}
  </ProfileLayout>;
}
