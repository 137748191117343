import {
    CredentialResponse,
    GoogleLogin,
    NonOAuthError,
    useGoogleLogin,
} from "@react-oauth/google";
import api from "api";
import { UserRegistrationBody } from "api/user/types";
import axios from "axios";
import { REGISTRATION_TYPE } from "constants/auth";
import { LocationType } from "models/location";
import { UserProfile } from "models/user";
import settings from "settings";

type PropsType = {
    onLoginCompleted: (tkn: string, u: UserProfile, wTkn: string, l: LocationType) => void;
    onRegistrationRequired: (
        type: REGISTRATION_TYPE,
        oauthData: any,
        defaultData: UserRegistrationBody | undefined
    ) => void;
};

export default ({ onLoginCompleted, onRegistrationRequired }: PropsType) => {
    const login = useGoogleLogin({
        flow: "implicit",
        // flow: "auth-code",

        onSuccess: (tokenResponse) => {
            console.log(tokenResponse);
            // fetching userinfo can be done on the client or the server
            // const userInfo = axios
            //   .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            //   })
            //   .then((res) => console.log("oauth2/v3/userinfo", res.data));
            api.auth.googleLogin(tokenResponse).then((res) => {
                if (res.signInOk) {
                    onLoginCompleted(res.token, res.user, res.warehouseToken, res.defaultUserLocation);
                } else {
                    const u: UserRegistrationBody = {
                        email: res.user.email,
                        name: res.user.name,
                        surname: res.user.surname,
                        phone: res.user.phone,
                    } as UserRegistrationBody;
                    onRegistrationRequired(REGISTRATION_TYPE.GOOGLE, tokenResponse, u);
                }
            });
        },
        onError: (errorResponse) => console.error(errorResponse),
        // onNonOAuthError: (nonOAuthError: NonOAuthError) =>
        //   console.error(nonOAuthError),

        // useOneTap: true,
        // scope?: TokenClientConfig['scope'];
        // redirect_uri: settings.baseUrl + "user/google/sign-in",
    });

    /* return (
      <GoogleLogin
        onSuccess={(tokenResponse) => api.auth.googleLogin(tokenResponse)}
      ></GoogleLogin>
    ); */
    return (
        <button className="btn btn-full btn-login" onClick={() => login()}>
            <img src={`${process.env.PUBLIC_URL}/img/google-logo.svg`} className="login-icon" />
            Continua con Google
        </button>
    );
};
