import { discountColorClass as discountColorClasses } from "constants/timeslots";
import { TimeSlotType } from "models/timeslots";

type PropsType = {
  data: TimeSlotType;
  selectedValue: string | undefined;
  onClick: () => void;
};

export default ({ data, selectedValue, onClick }: PropsType) => {
  return (
    <div
      className={`calendar-item  ${data.discountPerc != null ? discountColorClasses[data.discountPerc] : ""
        } ${!data.enabled ? "disabled" : "enabled"
        }`}
    >
      <input
        type="radio"
        name="calendar-item"
        value={data.id}
        disabled={!data.enabled}
        checked={selectedValue === data.id}
        onChange={(e) => onClick()}
      />
      <label>
        <span>ore</span>
        <span className={`calendar-item-hour`}>
          {data.fromHour}-{data.toHour}
        </span>
      </label>
    </div>
  );
};