import StoreCartLayout from "components/layout/StoreCartLayout";
import LocationInput from "components/location/LocationInput";
import ShopItem from "components/shop/ShopItem";
import ShopItemList from "components/shop/ShopItemList";
import ShopFilterBtn from "components/shop/ShopFilterBtn";
import ShopMenu from "components/shop/ShopMenu";
import { ShopCategoryEnum } from "constants/shop";
import { useContext, useEffect, useState } from "react";
import api from "api";
import { useShopStore } from "store/shopStore";
import { useCartStore } from "store/cartStore";
import { useParams, useSearchParams } from "react-router-dom";


export default function HomePage() {

  const cartTotal = useCartStore(state => state.cartTotal);
  const boxes = useShopStore(state => state.boxes);
  const setBoxes = useShopStore(state => state.setBoxes);


  useEffect(() => {
    if (boxes == null) {
      api.shop.getBoxes().then((res) => {
        setBoxes(res);
      });
    }
  }, []);

  const [type, setType] = useState<ShopCategoryEnum>(ShopCategoryEnum.WATER);
  return <>
    <StoreCartLayout urlNextStepDisabled={cartTotal?.total === 0} showExpandCartRecup={true} >
      <div className="mb-4">
        <h3>Crea la tua sottoscrizione</h3>
      </div>
      <ShopMenu value={type} setValue={setType} />
      <div className="my-3 my-lg-4">
        <div className="d-flex justify-content-end">

          <ShopFilterBtn type={type} />

        </div>
      </div>
      <ShopItemList type={type} />
    </StoreCartLayout>
  </>;
}
