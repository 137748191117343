import React, { useContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import urlUtils from "./utilities/urlUtils";
import { Navigate, Route, Routes } from "react-router-dom";
import { auth, unauth, app, open, notConfirmedCap } from "./router";
import AppLayout from "components/layout/AppLayout";
import api from "api";
import settings from "settings";
import { useAppStore } from "store/appStore";
import { useAuthStore } from "store/authStore";
import { useRouteStore } from "store/routeStore";
import { useCartStore } from "store/cartStore";
import Spinner from "components/common/Spinner";
import analyticsUtils from "utilities/analyticsUtils";
import RouteTracker from "components/common/RouteTracker";

type ProtectedRoutePropsType = {
  children: any;
  checkUser?: boolean;
  userIsLogged?: boolean;
  userIsLoggedValue?: boolean;
  redirectUrlUserCheckFailed?: string;
  checkPostalCode?: boolean;
  postalCodeIsConfirmed?: boolean;
  postalCodeIsConfirmedValue?: boolean;
  redirectUrlPostalCodeCheckFailed?: string;
};

const ProtectedRoute = ({
  children,
  checkUser = false,
  userIsLogged = false,
  userIsLoggedValue = true,
  redirectUrlUserCheckFailed = "",
  checkPostalCode = false,
  postalCodeIsConfirmed = false,
  postalCodeIsConfirmedValue = true,
  redirectUrlPostalCodeCheckFailed = "",
}: ProtectedRoutePropsType) => {
  /* console.log("ProtectedRoute", checkPostalCode, postalCodeIsConfirmed, postalCodeIsConfirmedValue) */
  if (checkPostalCode && postalCodeIsConfirmed != postalCodeIsConfirmedValue) {
    if (redirectUrlPostalCodeCheckFailed.startsWith('/')) {
      return <Navigate to={redirectUrlPostalCodeCheckFailed} replace={true} />;
    } else {
      window.location.replace(redirectUrlPostalCodeCheckFailed);
    }
  }
  if (checkUser && userIsLogged != userIsLoggedValue) {
    return <Navigate to={redirectUrlUserCheckFailed} replace={true} />;
  }

  return children;
};

function App() {
  const [loading, setLoading] = useState(true);
  const confirmedToken = useAppStore(state => state.confirmedToken);
  const locKey = useAppStore(state => state.locationKey);
  // const currentRoute = useRouteStore(state => state.currentRoute);
  // const setCurrentRouteData = useRouteStore(state => state.setCurrentRouteData);
  const confirmedLocation = useAppStore(state => state.confirmedLocation);
  const setAvailablePostalcodeLocation = useAppStore(state => state.setAvailablePostalcodeLocation);

  const { calcCartTotal, updateCount } = useCartStore();

  const token = useAuthStore(state => state.token);
  const user = useAuthStore(state => state.user);
  const setUserProfile = useAuthStore(state => state.setUserProfile);

  useEffect(() => {
    analyticsUtils.init()
  }, []);


  const isUserLogged = () => user != null && user.id != null
  const [userIsLogged, setUserIsLogger] = useState(isUserLogged());
  useEffect(() => {
    setUserIsLogger(isUserLogged())
  }, [user?.id])

  const isPostalCodeConfirmed = () => confirmedToken != null
  const [postalCodeIsConfirmed, setPostalCodeIsConfirmed] = useState(isPostalCodeConfirmed());
  useEffect(() => {
    setPostalCodeIsConfirmed(isPostalCodeConfirmed())
  }, [confirmedToken])


  useEffect(() => {
    if (
      locKey != null &&
      locKey != confirmedLocation?.key
    ) {
      setLoading(true);
      api.location.checkLocationByLocationKey(locKey).then((res) => {
        console.log("checkLocationByLocationKey", res);
        setAvailablePostalcodeLocation({ token: res.token, location: res.location });

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [confirmedLocation?.postalCode]);


  useEffect(() => {
    if (
      token != null
    ) {
      api.user.getProfile().then((res) => {
        setUserProfile(res);
      });
    }
  }, [token]);

  useEffect(() => {
    if (useAppStore.getState().confirmedToken != null) {
      calcCartTotal();
      updateCount();
    }
  }, []);

  /* const location = useLocation();
  useEffect(() => {
    let title = 'MechaPark';
    const found = allRoutes.find((o) => o.path === location.pathname);
    if (found != null) {
      title = `${title} - ${found.title}`;
    }
    routeDispatch({ type: RouteContext.ACTIONS.SET_CURRENT_ROUTE_DATA, value: found });
    document.title = title;
  }, [location]); */

  const getCheckUrl = () => {
    if (settings.defaultPostalCode != null) {
      return urlUtils.checkLocation.main(settings.defaultPostalCode);
    }
    // MEMO: Nel caso mando al login utente.
    return urlUtils.login();
    // return urlUtils.public.main();
  }

  if (loading) {
    return <Spinner page={true} />;
  }
  return (
    <>
      <RouteTracker />
      <Routes>
        <Route element={<AppLayout />}>
          {notConfirmedCap.map((r) => (
            <Route key={r.name} path={r.path} element={r.element} />
          ))}
          {open.map((r) => (
            <Route key={r.name} path={r.path} element={r.element} />
          ))}
          {auth.map((r) => (
            <Route
              key={r.name}
              path={r.path}
              element={
                <ProtectedRoute
                  checkPostalCode
                  postalCodeIsConfirmed={postalCodeIsConfirmed}
                  redirectUrlPostalCodeCheckFailed={getCheckUrl()}
                  checkUser
                  userIsLogged={userIsLogged}
                  redirectUrlUserCheckFailed={urlUtils.login()}
                >
                  {r.element}
                </ProtectedRoute>
              }
            />
          ))}
          {unauth.map((r) => (
            <Route
              key={r.name}
              path={r.path}
              element={
                <ProtectedRoute
                  // checkPostalCode
                  // postalCodeIsConfirmed={postalCodeIsConfirmed}
                  // redirectUrlPostalCodeCheckFailed={getCheckUrl()}
                  checkUser
                  userIsLogged={userIsLogged}
                  userIsLoggedValue={false}
                  redirectUrlUserCheckFailed={urlUtils.main()}
                >
                  {r.element}
                </ProtectedRoute>
              }
            />
          ))}
          {app.map((r) => (
            <Route
              key={r.name}
              path={r.path}
              element={
                <ProtectedRoute
                  checkPostalCode
                  postalCodeIsConfirmed={postalCodeIsConfirmed}
                  redirectUrlPostalCodeCheckFailed={getCheckUrl()}
                >
                  {r.element}
                </ProtectedRoute>
              }
            />
          ))}
          {open.map((r) => (
            <Route key={r.name} path={r.path} element={r.element} />
          ))}
          <Route
            path="*"
            element={<Navigate to={urlUtils.main()} replace={true} />}
          /></Route>
      </Routes>
    </>
  );
}

export default App;
