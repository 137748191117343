import api from "api";
import EmptyListCmp from "components/common/EmptyListCmp";
import ProfileLayout from "components/layout/ProfileLayout";
import SubscriptionGroup from "components/user/SubscriptionGroup";
import { SUBSCRIPTION_STATUS_LABEL } from "constants/subscriptions";
import { UserSubscriptionCmp } from "models/user";
import { useEffect, useState } from "react";
import { sortByDescending } from "utilities/funcUtils";

export default () => {
  const [loading, setloading] = useState<boolean>(true);
  const [subscriptions, setSubscriptions] = useState<UserSubscriptionCmp[]>([]);

  useEffect(() => {
    setloading(true);
    api.user.getSubscriptions().then((subscriptionList) => {
      const sortedSubscriptionList = [...subscriptionList].sort(
        (a: UserSubscriptionCmp, b: UserSubscriptionCmp) =>
          sortByDescending(
            new Date(a.createdAt ?? ""),
            new Date(b.createdAt ?? "")
          )
      );
      setSubscriptions(sortedSubscriptionList);
    }).finally(() => setloading(false));
  }, []);

  let contentCmp = null;

  if (!loading) {
    if (subscriptions?.length > 0) {
      contentCmp = <div className="row">
        <div className="col-12">
          <div className="vstack gap-4">
            {SUBSCRIPTION_STATUS_LABEL.map((v) => (
              <>
                <SubscriptionGroup
                  title={v.label}
                  data={subscriptions.filter((o) => o.status === v.value)}
                /></>
            ))}
          </div>
        </div>
      </div>;
    } else {
      contentCmp = <EmptyListCmp title="Non ci sono ancora sottoscrizioni…" />;
    }
  }

  return <ProfileLayout showBack title="Le mie sottoscrizioni">
    {contentCmp}
  </ProfileLayout>;
}
