import { LocationType } from "models/location";
import { useContext, useEffect, useState } from "react";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import urlUtils from "utilities/urlUtils";
import { useAppStore } from "store/appStore";
import Spinner from "components/common/Spinner";

export default () => {
  const navigate = useNavigate();
  let { locationKey } = useParams();

  const { setAvailablePostalcodeLocation, clearPostalcodeLocation } = useAppStore(state => state);


  useEffect(() => {
    if (locationKey != null) {
      // clearPostalcodeLocation();
      api.location
        .checkLocationByLocationKey(locationKey)
        .then((res) => {
          navigate(urlUtils.checkLocation.ok(locationKey));
          setAvailablePostalcodeLocation({ token: res.token, location: res.location });
        })
        .catch(() => {
          // MEMO: Non deve poter succedere
          navigate(urlUtils.checkLocation.ko(locationKey));
        });
    }
  }, [locationKey])

  return <Spinner page={true} />;
}
