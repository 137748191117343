import {
  DaySlotType,
  TimeSlotType,
  TimeSlotWithDateType,
} from "models/timeslots";
import TimeSlot from "./TimeSlot";
import { ShortWorkWeekDays } from "constants/common";
import { useState } from "react";

type PropsType = {
  data?: DaySlotType[];
  onChange: (data: TimeSlotWithDateType | undefined) => void;
};
export default ({ data, onChange }: PropsType) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState<
    TimeSlotWithDateType | undefined
  >();

  const onChangeSelectedTimeZone = (elem: TimeSlotWithDateType | undefined) => {
    setSelectedTimeZone(elem);
    onChange(elem);
  };
  return (
    <div className="calendar">
      <div className="calendar-grid">
        <div className="calendar-days">
          {ShortWorkWeekDays.map((o) => (
            <span>{o.label}</span>
          ))}
        </div>

        {data != null && data.length > 0 ? (
          <div className="calendar-hours">
            {data[0].slots.map((s, slotIdx) => (
              <>
                {ShortWorkWeekDays.map((o, dayIdx) => (
                  <TimeSlot
                    data={data[dayIdx].slots[slotIdx]}
                    selectedValue={selectedTimeZone?.id}
                    onClick={() =>
                      onChangeSelectedTimeZone({
                        ...data[dayIdx].slots[slotIdx],
                        year: data[dayIdx].year,
                        month: data[dayIdx].month,
                        day: data[dayIdx].day,
                      })
                    }
                  />
                ))}
              </>
            ))}
          </div>
        ) : null}

      </div>
    </div>
  );
};
