import SheetCard from "components/common/SheetCard";
import { LocationType } from "models/location";
import { useAppStore } from "store/appStore";
import LocationCheckWizard from "./LocationCheckWizard";
import { useState } from "react"

type PropsType = {
    show: boolean;
    setShowValue: (val: boolean) => void;
};

export default ({ show, setShowValue }: PropsType) => {
    const [confirmedLocation, setConfirmedLocation] = useState<LocationType | undefined>();

    const setAvailablePostalcodeLocation = useAppStore(state => state.setAvailablePostalcodeLocation);
    const onLocationSelected = (l: LocationType, token: string) => {
        console.log("onLocationSelected", l)
        setAvailablePostalcodeLocation({ location: l, token: token })
        setConfirmedLocation(l);
        setShowValue(false);
    }

    const onEndConfirmLocationWizard = () => {
        console.log("onEndConfirmLocationWizard", confirmedLocation)
        setShowValue(false);
    }

    const onShowChanged = (val: boolean) => {
        if (!val) {
            setConfirmedLocation(undefined);
        }
        setShowValue(val);
    }

    return <SheetCard
        show={show}
        setShowValue={onShowChanged}
        title={`Cambio località`}
        body={<>
            {show &&
                <LocationCheckWizard
                    onChange={onLocationSelected}
                    onEndWizard={onEndConfirmLocationWizard}
                    endWizardAtConfirm={false}
                    useAppLocationAsDefault={false}
                />}
        </>} />
}

