import { getPublicImageUrl } from "utilities/imageUtils"

type PropsType = {
    page?: boolean;
}

export default ({ page = false }: PropsType) => {
    return <div className={`loader ${page ? 'fullscreen' : ''}`}>
        <img src={getPublicImageUrl("loader.svg")} width="100%" height="80px" />
    </div>
}