import { LocationType } from "models/location";
import { useState, useEffect } from "react"
import LocationCheckWizard from "./LocationCheckWizard";
import { useAppStore } from "store/appStore";
import LocationCheckWizardSheet from "./LocationCheckWizardSheet";
import { convertToLocationString } from "utilities/convertUtils";

type PropsType = {
    containerClosed?: boolean;
};

export default ({ containerClosed }: PropsType) => {
    const [showWizard, setShowWizard] = useState<boolean>(false);

    const confirmedLocation = useAppStore(state => state.confirmedLocation);

    const getInputLabel = () => {
        return convertToLocationString(confirmedLocation, false);
    }
    const [locationStr, setLocationStr] = useState(getInputLabel());

    useEffect(() => {
        setLocationStr(getInputLabel());
    }, [confirmedLocation])


    const onLocationClick = () => {
        // MEMO: Seleziono o cambio cap.
        //
        setShowWizard(true);
    }

    return <>
        {<LocationCheckWizardSheet show={showWizard} setShowValue={setShowWizard} />}
        <div className={`container ${containerClosed ? 'container-close' : ''} gutter-sm my-4`} onClick={() => onLocationClick()}>
            <div className="form-icon form-search-street">
                <div className={`form-control ${locationStr != null && locationStr != "" ? '' : 'placeholder'}`} id="input-search-street-id" >{locationStr != null && locationStr != "" ? locationStr : "Seleziona"}</div>

                <div className="icon">
                    <i className="driink-pin"></i>
                </div>

                <div className="icon right-0" >
                    <span className="material-symbols-outlined fill-1 text-primary">edit</span>
                </div>
            </div>
        </div></>
}