import { UserOrder, UserOrderBox, UserOrderItemDetail } from "models/user";
import { useEffect, useState } from "react";
import { convertToCurrency, convertToCurrencyOrPerc } from "utilities/convertUtils";
import { getPublicImageUrl } from "utilities/imageUtils";
import OrderItem from "./OrderItem";
import OrderBox from "./OrderBox";

type PropsType = {
  order: UserOrder;
};

export default ({ order }: PropsType) => {
  const { id: orderId, itemDetails: items, boxes } = order;
  const [orderElems, setOrderElems] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    const elems: React.ReactNode[] = [];
    items?.forEach((id) =>
      elems.push(<OrderItem key={`id-${id}`} data={id} />)
    );
    boxes?.forEach((id) =>
      elems.push(<OrderBox key={`box-${id}`} data={id} />)
    );
    setOrderElems(elems);
  }, [orderId]);

  const showSalesRow = (order.couponCode != null) || (order.timeSlotDiscount ?? 0 > 0);
  return (
    <div className="shadow-box p-0">
      <div className="px-2 py-3 d-flex flex-column gap-custom gSpace-0625">
        {orderElems?.map((elem, idx) => (
          <>
            {idx > 0 ? (
              <hr key={`s-${idx}`} className="my-0" />
            ) : null}
            {elem}
          </>
        ))}
        {showSalesRow ?
          <>
            <hr className="my-0" />
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column">

                <div className="box-item-body">
                  <div className="box-item-body-info d-flex align-items-center gap-3">
                    <div className="product-placeholder">
                      <img src={getPublicImageUrl("item-icon/discount.svg")} />
                    </div>

                    <div className="d-flex flex-column">
                      <div className="text-primary d-flex align-items-center gap-2">
                        <p className="fw-extrabold">Sconti applicati</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 mt-2">
                  {
                    order.timeSlotDiscount ?? 0 > 0 ?
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0">Sconto giorno di spedizione</p>
                        <p className="mb-0 text-success">-{convertToCurrencyOrPerc(order?.timeSlotDiscount ?? 0, order?.timeSlotDiscountAbsolute ?? false)}</p>
                      </div>
                      : null
                  }

                  {
                    order.couponCode != null ?
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0">Sconto {order.couponCode}</p>
                        <p className="mb-0 text-success">-{convertToCurrencyOrPerc(order.couponTotalDiscount ?? 0, order.couponTotalDiscountAbsolute ?? false)}</p>
                      </div>
                      : null
                  }
                </div>
              </div>
            </div>
          </> : null}
      </div>
      <div className="bg-fourth p-3">
        <h3 className="text-end fw-bold lh-1">{convertToCurrency(order.totalWithDiscount ?? 0)}</h3>
      </div>
    </div>
  );
}