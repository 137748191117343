import PaymentButtons from "components/cart/PaymentButtons";
import StoreCartLayout from "components/layout/StoreCartLayout";
import { useEffect, useMemo, useState, useContext } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import GenericLayout from "components/layout/GenericLayout";
import { convertToCurrency, convertToCurrencyOrPerc } from "utilities/convertUtils";
import CouponCodeInput from "components/cart/CouponCodeInput";
import { TimeSlotWithDateType } from "models/timeslots";
import { UserAddress } from "models/user";
import { CartType, ICartElement } from "models/cart";
import { ShippingMethod } from "models/shippingMethods";
import { CouponDiscountType, CouponType } from "models/coupon";
import { convertUserCartOrderBoxToICartElement, convertUserCartOrderItemDetailToICartElement, useCartStore } from "store/cartStore";
import { CartSummaryType } from "models/summary";
import { COUPON_DISCOUNT_PROPERTY } from "constants/coupons";
import Spinner from "components/common/Spinner";

const SummaryVoiceCmp = ({ label, value, valueClass }: { label: string, value: any | undefined, valueClass?: string }) => {
  return <div className="d-flex align-items-center justify-content-between">
    <p className="mb-0">{label}</p>
    <p className={`mb-0 ${valueClass ?? ''}`}>{value}</p>
  </div>
}

type PropsType = {
  processingPayment: boolean;
  canBePayed: boolean;
  onPayPalClick: () => void;
  cartTotal: CartSummaryType | undefined;
  couponCanBeAdded: boolean;
  couponCode: string | undefined;
  couponDiscounts: CouponDiscountType[] | undefined;
};




export default ({ processingPayment, canBePayed, onPayPalClick, cartTotal, couponCanBeAdded, couponCode, couponDiscounts }: PropsType) => {
  const getDiscountLabel = (property: COUPON_DISCOUNT_PROPERTY) => {
    switch (property) {
      case COUPON_DISCOUNT_PROPERTY.Subtotal: return "Sconto subtotale";
      case COUPON_DISCOUNT_PROPERTY.Shipping: return "Sconto spedizione";
      case COUPON_DISCOUNT_PROPERTY.Total: return "Sconto totale";
    }
    return "";
  }


  return <GenericLayout
    containerClosed
    title={processingPayment ? "" : "Concorda la consegna"}
    showBack={!processingPayment}
    backLabel={processingPayment ? "" : "Indietro"}
  >
    {processingPayment ? <Spinner page={true} /> : <div className="row gy-4">
      <div className="col-12 col-lg-8">
        <div className="vstack gap-4">
          <div className="shadow-box">
            <div className="d-flex flex-column gap-3">

              <h5>Riepilogo acquisto</h5>

              <div className="d-flex flex-column gap-custom gSpace-075">
                <div className="d-flex flex-column gap-custom gSpace-075">

                  <SummaryVoiceCmp label="Subtotale" value={convertToCurrency(cartTotal?.subtotal ?? 0)} />

                  <SummaryVoiceCmp label="Consegna" value={cartTotal?.shippingCost ? convertToCurrency(cartTotal?.shippingCost ?? 0) : "Gratis"} />

                  {cartTotal?.timeslotDiscount != null && cartTotal.timeslotDiscount > 0 ?
                    <SummaryVoiceCmp label="Sconto giorno di spedizione" value={`-${convertToCurrencyOrPerc(cartTotal?.timeslotDiscount ?? 0, cartTotal?.timeslotDiscountAbsolute ?? false)}`} /> : null}

                  {/* <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0"></p>
                    <p className="mb-0 text-success"></p>
                  </div> */}

                  {couponCanBeAdded ? <div className="mt-custom mtSpace-075">
                    <div className="d-flex flex-column gap-2">
                      <CouponCodeInput />
                    </div>
                  </div> : null}

                  {
                    couponCode != null && couponDiscounts != null ?
                      <>
                        {
                          couponDiscounts.map(d =>
                            <SummaryVoiceCmp
                              key={d.property}
                              label={`${couponCode.toUpperCase()}: ` + getDiscountLabel(d.property)}
                              value={convertToCurrencyOrPerc(-(d.discount ?? 0), d.discountAbsolute ?? false)}
                              valueClass={"text-success"}
                            />
                          )
                        }
                      </> :
                      null
                  }


                </div>

                <hr className="my-0" />

                <div className="d-flex align-items-center justify-content-between text-primary">
                  <p className="mb-0 fw-extrabold">Totale</p>
                  <p className="mb-0 fw-extrabold">{convertToCurrency((cartTotal?.total ?? 0) - (cartTotal?.totalDiscount ?? 0))}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="shadow-box">
            <div className="d-flex align-items-center gap-2">

              <img src={getPublicImageUrl("item-icon/idea.svg")} className="img-fluid" width="68px" height="auto" />

              <p className="text-primary fw-bold">Il costo della sottoscrizione sarà addebitato nei giorni successivi alla consegna effettuata!</p>
            </div>
          </div>

        </div>
      </div>

      <div className="col-12 col-lg-4">
        <PaymentButtons disabled={!canBePayed} onPayPalClick={onPayPalClick} />
      </div>
    </div>}
  </GenericLayout>;
}
