import settings from "settings";

export default {
  login: () => "/login",
  forgotPassword: () => "/forgot-password",
  resetPassword: () => "/reset-password",
  main: () => "/",
  cart: () => "/cart",
  cartLogin: () => "cart/login",
  cartSummary: () => "/cart/summary",
  cartType: () => "/cart/summary/type",
  payment: () => `/payment`,
  paymentOrder: (orderId = ":id") => `/order/${orderId}/payment`,
  paymentOk: (orderId = ":id") => `/order/${orderId}/payment/ok`,
  paymentKo: (orderId = ":id") => `/order/${orderId}/payment/ko`,
  checkLocation: {
    main: (locationKey = ":locationKey") => `/location/check/${locationKey}`,
    ok: (locationKey = ":locationKey") => `/location/check/${locationKey}/ok`,
    ko: (locationKey = ":locationKey") => `/location/check/${locationKey}/ko`,
  },
  user: {
    main: () => "/user",
    profile: () => "/user/profile",
    changePassword: () => "/user/change-password",
    addresses: {
      main: () => "/user/addresses",
      detail: (id = ":id") => `/user/addresses/${id}`,
    },
    orders: {
      main: () => "/user/orders",
      detail: (id = ":id") => `/user/orders/${id}`,
    },
    subscriptions: {
      main: () => "/user/subscriptions",
      detail: (id = ":id") => `/user/subscriptions/${id}`,
    },
  },
  public: {
    main: () => `${settings.publicUrl}`,
    comeFunziona: () => `${settings.publicUrl}come-funziona.html`,
    etichetta: () => `${settings.publicUrl}la-nostra-etichetta.html`,
    diventaUnDriinker: () => `${settings.publicUrl}diventa-un-driinker.html`,
  }
};
