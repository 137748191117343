import { Modal, ModalBody, ModalHeader } from "react-bootstrap"
import LocationInput from "./LocationInput"
import LocationSelectionCombobox from "./LocationSelectionCombobox"
import { LocationType } from "models/location";
import { useState } from "react";
import urlUtils from "utilities/urlUtils";
import { Navigate, useNavigate } from "react-router-dom";

export type Props = {
    show: boolean;
    setShow: (val: boolean) => void;
}

export default ({ show, setShow }: Props) => {
    const [locationSearch, setLocationSearch] = useState<LocationType | undefined>();
    const [confirmedLocationKey, setConfirmedLocationKey] = useState<string>();

    const navigate = useNavigate();

    const onCheckLocation = () => {
        if (locationSearch?.key != null) {
            setConfirmedLocationKey(locationSearch.key);
            setShow(false);
            // api.location
            //   .checkLocationByLocationKey(locationSearch.key)
            //   .then((res) => {
            //     setLocationToConfirm(res.location);
            //     setTokenToConfirm(res.token);
            //     setCheckStatus(true);
            //   })
            //   .catch(() => {
            //     setCheckStatus(false);
            //   });
        }
    };

    if (confirmedLocationKey != null) {
        return <Navigate to={urlUtils.checkLocation.main(confirmedLocationKey)}></Navigate>
    }

    return <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader>
            <h1 className="modal-title fs-5" id="capModalLabel">Verifica se arriviamo nella tua zona inserendo il tuo <span className="text-fifth fw-extrabold">comune</span></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} />

        </ModalHeader>
        <ModalBody>
            {/* <div className="form-autocomplete autocomplete location-yellow input-group">
                <input type="text" className="form-control" name="Comune" placeholder="es. Bergamo" />
                <a href="#" className="btn btn-fifth">Verifica copertura</a>
            </div> */}

            <div className="autocomplete location-yellow input-group chk-location-input-group">

                <LocationSelectionCombobox value={locationSearch} onChange={(e) => setLocationSearch(e)} />

                {/* <input
                type="text"
                className="form-control"
                placeholder="es. 23459"
                value={cap}
                onChange={(e) => setLocationSearch(e.target.value)}
                id=""
              /> */}

                <button disabled={locationSearch == null} onClick={() => onCheckLocation()} className="btn btn-fifth">
                    Verifica copertura
                </button>
            </div>
        </ModalBody>
    </Modal>
}