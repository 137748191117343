import { MessageBoxType } from 'components/common/MessageBox';
import { LocationType } from 'models/location';
import { parseJwt } from 'utilities/funcUtils';
import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

type StateType = {
    messageBox: MessageBoxType | undefined | null;
    // postalCode: string | undefined | null;
    locationKey: string | undefined | null;
    confirmedToken: string | undefined | null;
    confirmedLocation: LocationType | undefined | null;
    setAvailablePostalcodeLocation: ({ token, location }: { token: string, location: LocationType }) => void;
    clearPostalcodeLocation: () => void;
    showMessageBox: (mb: MessageBoxType) => void;
    closeCurrentMessageBox: () => void;
    changeScroll: (bottomReached: boolean) => void;
    addScrollCallback: (callback: (bottomReached: boolean) => void) => void;
    removeScrollCallback: (callback: (bottomReached: boolean) => void) => void;
    clearAllScrollCallback: () => void;
    scrollCallbackArray: ((bottomReached: boolean) => void)[];
};

// const CLAIMS = {
//     ID: "w_id",
//     NAME: "l_nm",
//     CITY: "l_c",
//     PROVINCE: "l_p",
//     POSTALCODE: "l_pc",
// };

// function getPostalCodeFromToken(token: string): LocationType | undefined {
//     const tmpData = parseJwt(token);
//     if (tmpData == null) {
//         return undefined;
//     }
//     return {
//         name: tmpData[CLAIMS.NAME],
//         city: tmpData[CLAIMS.CITY],
//         province: tmpData[CLAIMS.PROVINCE],
//         postalCode: tmpData[CLAIMS.POSTALCODE],
//     } as LocationType;
// }

// const POSTAL_CODE = "postal_code";
// const initialPostalCode = localStorage.getItem(POSTAL_CODE);
// const PC_TOKEN = "pc_t";
// const initialPostalCodeToken = localStorage.getItem(PC_TOKEN);
// let initialPostalCodeLocation: LocationType | undefined | null = null;

// if (initialPostalCodeToken != null) {
//     setLocationToken(initialPostalCodeToken);
//     initialPostalCodeLocation = getPostalCodeFromToken(initialPostalCodeToken);
// }

export const useAppStore = create(
    persist<StateType>(
        (set, get) => ({
            messageBox: null,
            locationKey: null,
            confirmedToken: null,
            confirmedLocation: null,
            setAvailablePostalcodeLocation: ({ token, location }: { token: string, location: LocationType }) => set((state) => {
                // setLocationToken(token);
                // localStorage.setItem(POSTAL_CODE, location.postalCode ?? '');
                // localStorage.setItem(PC_TOKEN, token);
                return {
                    locationKey: location.key,
                    confirmedToken: token,
                    confirmedLocation: location,
                };
            }),
            clearPostalcodeLocation: () => set((state) => {
                return {
                    locationKey: null,
                    confirmedToken: null,
                    confirmedLocation: null,
                };
            }),
            showMessageBox: (mb: MessageBoxType) => set((state) => {
                return { messageBox: mb };
            }),
            closeCurrentMessageBox: () => set((state) => ({ messageBox: null })),
            changeScroll: (bottomReached: boolean) => {
                get().scrollCallbackArray.forEach(cb => cb(bottomReached));
            },
            addScrollCallback: (callback: (bottomReached: boolean) => void) => set((state) => {
                return { scrollCallbackArray: [...state.scrollCallbackArray, callback] };
            }),
            removeScrollCallback: (callback: (bottomReached: boolean) => void) => set((state) => {
                const cbIdx = state.scrollCallbackArray.findIndex(o => o === callback);
                const cbArray = [...state.scrollCallbackArray];
                if (cbIdx > -1) {
                    cbArray.splice(cbIdx, 1);
                }
                return { scrollCallbackArray: cbArray };
            }),
            clearAllScrollCallback: () => set((state) => {
                return { scrollCallbackArray: [] };
            }),
            scrollCallbackArray: [],
        }),
        {
            name: 'pc',
            partialize(state) {
                return {
                    confirmedLocation: state.confirmedLocation,
                    confirmedToken: state.confirmedToken,
                    locationKey: state.locationKey,
                } as StateType;
            }
        }
    )
)