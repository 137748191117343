import { UserAddressBody } from "api/user/types";
import { UserAddress, UserAddressType } from "models/user";
import { useState, useEffect, useContext, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormField from "components/common/form/FormField";
import api from "api";
import SheetCard from "components/common/SheetCard";
import BackArrowButton from "components/common/BackArrowButton";
import AddressFormBody from "./AddressFormBody";
import { UserAddressTypeLabel } from "constants/user";
import LocationCheckWizard from "components/location/LocationCheckWizard";
import { LocationType } from "models/location";
import { useAppStore } from "store/appStore";

type PropsType = {
  show: boolean;
  type: UserAddressType;
  setShowValue: (val: boolean) => void;
  data: UserAddress | undefined;
  refreshData: () => void;
  hideBackButton?: boolean;
};

export default ({
  data,
  type,
  show,
  setShowValue,
  refreshData,
  hideBackButton = false,
}: PropsType) => {
  const stateConfirmedLocation = useAppStore(state => state.confirmedLocation);

  const getInitFormData = () => {
    return {
      name: data?.name ?? "",
      surname: data?.surname ?? "",
      address: data?.address ?? "",
      city: data?.city ?? stateConfirmedLocation?.city,
      province: data?.province ?? stateConfirmedLocation?.province,
      postalCode: data?.postalCode ?? "",
      phone: data?.phone ?? "",
      notes: data?.notes ?? "",
      isDefault: data?.isDefault ?? false,
      locationId: data?.locationId,
    };
  };

  const [isNew, setIsNew] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserAddressBody>(getInitFormData());
  const [locationFieldFromCB, setIsFieldFromCB] = useState<boolean>(false);

  useEffect(() => {
    setIsNew(data == null);
    setFormData(getInitFormData());
    setIsFieldFromCB
  }, [data]);

  const signInSchema = Yup.object().shape({
    name: Yup.string().required("Nome richiesto"),
    address: Yup.string().required("Indirizzo richiesto"),
    city: Yup.string().required("Città richiesta"),
    province: Yup.string().required("Provincia richiesta"),
    postalCode: Yup.string().required("CAP richiesto"),
    phone: Yup.string() as any, //.phone("IT", "Telefono non valido"),
  });

  const [confirmedLocation, setConfirmedLocation] = useState<LocationType | undefined>();

  useEffect(() => {
    setConfirmedLocation(undefined);
  }, [isNew, type])

  useEffect(() => {
    if (!show) {
      console.log("resetForm", isNew, type, confirmedLocation, locationNeedsToBeConfirmed)
      // MEMO: Faccio il reset del form.
      setConfirmedLocation(undefined);
    }
  }, [show])


  const [showForRefresh, setShowForRefresh] = useState<boolean>(show);

  useEffect(() => {
    // MEMO: Metodo non bello per resettare il contenuto della card
    if (show) {
      setShowForRefresh(true);
    } else {
      setTimeout(() => setShowForRefresh(false), 300);
    }
  }, [show])

  const locationNeedsToBeConfirmed = useMemo(() => isNew && type === UserAddressType.SHIPPING && confirmedLocation == null, [isNew, type, confirmedLocation]);

  const changeShowValue = (val: boolean) => {
    console.log("changeShowValue", val)
    setShowValue(val);
  }

  useEffect(() => console.log("locationNeedsToBeConfirmed", locationNeedsToBeConfirmed), [locationNeedsToBeConfirmed])

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={signInSchema}
      onSubmit={(values) => {
        let newValues = {
          ...formData,
          ...values,
        };

        let prom: Promise<UserAddress | undefined>;

        switch (type) {
          case UserAddressType.SHIPPING: prom = isNew ? api.user.addShippingAddress(newValues) : api.user.editAddress(data!.id!, newValues); break;
          case UserAddressType.BILLING: prom = isNew ? api.user.addBillingAddress(newValues) : api.user.editAddress(data!.id!, newValues); break;
        }

        console.log("Submitted address")

        if (prom != null) {
          prom.then((res) => {
            refreshData();
            changeShowValue(false);
          });
        }
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit } =
          formik;

        const onEndConfirmLocationWizard = () => {
          // TODO: Che fare?
          if (confirmedLocation == null) {
            console.log("onEndConfirmLocationWizard", confirmedLocation)
            changeShowValue(false);
          }
        }


        const onLocationSelected = (l: LocationType) => {
          console.log("onLocationSelected", l)
          setConfirmedLocation(l);
          setIsFieldFromCB(true);
          formik.setFieldValue('locationId', l.id);
        }
        return (
          <Form>
            <SheetCard
              show={show}
              setShowValue={changeShowValue}
              title={`Indirizzo di ${UserAddressTypeLabel[type]}`}
              body={
                <div className="container gutter-md">
                  <div className="row">
                    <div className="col-12">
                      <div className="arrow-back">
                        {hideBackButton ? null : <>
                          <a onClick={() => changeShowValue(false)} className="btn btn-action p-0">
                            <span className="material-symbols-outlined">arrow_back</span>
                          </a>
                          <span className="arrow-back__back-label">Indietro</span>
                        </>}
                        <h4 className="arrow-back__title">{isNew
                          ? "Aggiungi nuovo indirizzo"
                          : "Modifica indirizzo"}</h4>


                      </div>
                      <div
                        className="address-form d-flex flex-column gap-3 mt-custom mtSpace-075"
                      /* style="--mt-space: 0.75rem;" */
                      >
                        {!showForRefresh ? null : locationNeedsToBeConfirmed ? <LocationCheckWizard onChange={onLocationSelected} onEndWizard={onEndConfirmLocationWizard} endWizardAtConfirm={false} useAppLocationAsDefault={false} /> : <AddressFormBody
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          locationFieldDisabled={type === UserAddressType.SHIPPING}
                          locationFieldFromCB={locationFieldFromCB}
                          defaultLocation={confirmedLocation}
                        />}

                      </div>
                    </div>
                  </div>
                </div>
              }
              footer={
                <button
                  className={`btn btn-primary btn-full ${!(dirty && isValid) ? "disabled-btn" : ""
                    }`}
                  disabled={!(dirty && isValid)}
                  onClick={() => handleSubmit()}
                >
                  Salva modifiche
                </button>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};
