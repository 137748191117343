import StoreCartLayout from "components/layout/StoreCartLayout";
import { CartType } from "models/cart";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "utilities/imageUtils";
import urlUtils from "utilities/urlUtils";
import { getDayOfWeek } from "utilities/convertUtils";
import { useCartStore } from "store/cartStore";
import analyticsUtils from "utilities/analyticsUtils";
import { AnalyticsActionCategory, AnalyticsActionType, AnalyticsEventActionType } from "utilities/analyticsUtils/types";

export default () => {
  const selectedShippingAddress = useCartStore(state => state.selectedShippingAddress);
  const selectedBillingAddress = useCartStore(state => state.selectedBillingAddress);
  const selectedTimeSlot = useCartStore(state => state.selectedTimeSlot);
  const selectedType = useCartStore(state => state.selectedType);
  const selectType = useCartStore(state => state.selectType);

  const navigate = useNavigate();

  const confirmOrder = () => {
    if (selectedTimeSlot != null && selectedShippingAddress != null && selectedBillingAddress != null && selectedType != null) {

      navigate(urlUtils.payment());
    }
  };

  useEffect(() => {
    if (selectedType == null) {
      setSelectedType(false);
    }

    analyticsUtils.sendEvent(AnalyticsActionCategory.NAVIGATION, AnalyticsEventActionType.CLICK, "time-slot selected", selectedTimeSlot);
  }, []);

  const setSelectedType = (checked: boolean) => {
    const type = checked ? CartType.RECURRING_DELIVERY : CartType.SINGLE_DELIVERY;
    console.log("setSelectedType", type)
    selectType(type);
  };

  return <StoreCartLayout
    containerClosed
    title="Concorda la consegna"
    showBack
    backLabel="Indietro"
    labelNextStep="Al pagamento"
    urlNextStep={urlUtils.payment()}
    urlNextStepDisabled={selectedShippingAddress == null || selectedBillingAddress == null || selectedTimeSlot == null || selectedType == null}
  >
    <>
      <div className="mb-4 px-3 px-lg-0">
        <h1>Ci pensiamo noi!</h1>
        <p className="mt-3">Con la consegna periodica puoi stare tranquillo: riceverai a casa <b>ogni 2
          settimane</b> la tua box senza nessuno sforzo! Semplice e veloce come bere un
          bicchier
          d’acqua</p>
      </div>

      <div className="my-4">

        <div className="form-check">
          <input className="form-check-input" type="checkbox" value={selectedType === CartType.RECURRING_DELIVERY ? 1 : 0} id="checkbox-id" onChange={(e) => setSelectedType(e.target.checked)} />
          <label className="form-check-label" htmlFor="checkbox-id">

            <h3 className="text-primary">Voglio essere un Driinker!</h3>


            <p className="fw-medium">
              Consegna
              {` ` + getDayOfWeek(selectedTimeSlot)}
              , fascia
              {` ${selectedTimeSlot?.fromHour}-${selectedTimeSlot?.toHour}`}
            </p>

          </label>
        </div>

      </div>

      <div className="mt-4">
        <div className="shadow-box">
          <h4 className="mb-4">I vantaggi del nostro servizio</h4>

          <div className="d-flex flex-column gap-custom gSpace-075">
            <div className="d-flex align-items-center gap-2">
              <i className="driink-event"></i>
              <p>Ricevi ogni 2 settimane la box nel tuo giorno e orario preferito<sup>*</sup>
              </p>
            </div>

            <div className="d-flex align-items-center gap-2">
              <i className="driink-wallet"></i>
              <p>Paghi dopo aver esaminato i prodotti</p>
            </div>

            <div className="d-flex align-items-center gap-2">
              <i className="driink-star"></i>
              <p>Ottieni premi preziosi con la raccolta punti</p>
            </div>

            <div className="d-flex align-items-center gap-2">
              <i className="driink-handshake"></i>
              <p>Nessun costo aggiuntivo</p>
            </div>
          </div>

          <hr className="plenty my-3" />


          <div className="d-flex align-items-center gap-2">

            <img src={getPublicImageUrl("item-icon/idea.svg")} className="img-fluid" width="68px" height="auto" />

            <p className="text-primary fw-bold">Potrai cambiare il giorno di consegna fino a 48 ore prima e annullare l’iscrizione quando vuoi</p>
          </div>

        </div>
      </div>
    </>
  </StoreCartLayout>;
}
