import {
  DaySlotType,
  TimeSlotType,
  TimeSlotWithDateType,
} from "models/timeslots";
import TimeSlot from "./TimeSlot";
import { ShortWorkWeekDays } from "constants/common";
import { useState } from "react";
import { getPublicImageUrl } from "utilities/imageUtils";
import CalendarSlot from "./CalendarSlot";

type PropsType = {
  locationId: string | undefined,
  data?: DaySlotType[];
  onChange: (data: TimeSlotWithDateType | undefined) => void;
};
export default ({ locationId, data, onChange }: PropsType) => {
  return (

    <div className="mb-4">
      <div className="shadow-box">
        <div className="d-flex flex-column gap-2">
          <h5>Scegli il giorno e la fascia oraria per la prossima consegna</h5>
          <p>Ottieni uno sconto selezionando una fascia speciale!</p>
        </div>

        <div className="d-flex align-items-center gap-2 my-4">

          <span className="badge badge bg-success text-black">Sconto 10%</span>


          <span className="badge badge bg-warning text-black">Sconto 5%</span>

        </div>

        <div className="mt-4 position-relative">
          {/* <div className="position-absolute">Seleziona un indirizzo di spedizione balido</div> */}
          <CalendarSlot data={data} onChange={onChange} />
        </div>

        <hr className="plenty my-3" />


        <div className="d-flex align-items-center gap-2">

          <img src={getPublicImageUrl("item-icon/idea.svg")} className="img-fluid" width="68px" height="auto" />

          <p className="text-primary fw-bold">Non ti preoccupare, potrai cambiare il giorno di consegna fino a 48 ore prima!</p>
        </div>


      </div>
    </div>
  );
};
