import api from "api";
import { MessageBoxButtonsType, MessageBoxIcon } from "components/common/MessageBox";
import ProfileLayout from "components/layout/ProfileLayout";
import OrderRender from "components/order/OrderRender";
import { ORDER_STATUS_ENUM } from "constants/order";
import { SUBSCRIPTION_STATUS_ENUM } from "constants/subscriptions";
import { UserOrder, UserSubscription } from "models/user";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppStore } from "store/appStore";
import { convertToDateString } from "utilities/convertUtils";
import urlUtils from "utilities/urlUtils";

export default () => {
  let { id } = useParams();
  const [subscription, setSubscription] = useState<UserSubscription | undefined>();
  const navigate = useNavigate();
  const showMessageBox = useAppStore(state => state.showMessageBox);

  useEffect(() => {
    if (id != null) {
      api.user.getSubscriptionDetail(id).then((res) => {
        setSubscription(res);
      });
    } else {
      setSubscription(undefined);
    }
  }, [id]);

  const setSubscriptionNewStatus = (newStatus: SUBSCRIPTION_STATUS_ENUM) => {
    let changeStatusPromise: Promise<UserSubscription> | undefined = undefined;

    if (subscription?.id != null) {
      switch (newStatus) {
        case SUBSCRIPTION_STATUS_ENUM.ACTIVE: changeStatusPromise = api.user.restartSubscription(subscription.id); break;
        case SUBSCRIPTION_STATUS_ENUM.PAUSED: changeStatusPromise = api.user.pauseSubscription(subscription.id); break;
        case SUBSCRIPTION_STATUS_ENUM.STOPPED: changeStatusPromise = api.user.stopSubscription(subscription.id); break;
      }
    }
    // TODO: Mostrare form di conferma
    if (changeStatusPromise != null) {
      changeStatusPromise.then(res => {
        setSubscription({ ...subscription, status: res.status } as UserSubscription);

        if (newStatus === SUBSCRIPTION_STATUS_ENUM.STOPPED) {
          navigate(urlUtils.user.subscriptions.main(), { replace: true });
        }
      })
    }
  }

  const editSubscriptionData = () => {
    showMessageBox({
      icon: MessageBoxIcon.ALERT,
      title: 'Conferma la modifica della sottoscrizione',
      text: 'Sei sicuro/a di voler modificare la sottoscrizione?',
      type: MessageBoxButtonsType.YESNO,
      onOk() {
        console.warn("TODO");
      },
    })
  }

  const reactivateSubscription = () => {
    showMessageBox({
      icon: MessageBoxIcon.ALERT,
      title: 'Stai riattivando la sottoscrizione',
      text: <p>Sei sicuro/a di voler riattivare la sottoscrizione?</p>,
      type: MessageBoxButtonsType.YESNO,
      onOk() {
        setSubscriptionNewStatus(SUBSCRIPTION_STATUS_ENUM.ACTIVE);
      },
    })
  }

  const deactivateSubscription = () => {
    showMessageBox({
      icon: MessageBoxIcon.ALERT,
      title: 'Stai disattivando la sottoscrizione',
      text: <><p>Sei sicuro/a di voler disattivare la sottoscrizione?</p><p><strong>NOTA BENE: </strong>In qualisasi momento potrai riattivare la sottoscrizione.</p></>,
      type: MessageBoxButtonsType.YESNO,
      onOk() {
        setSubscriptionNewStatus(SUBSCRIPTION_STATUS_ENUM.PAUSED);
      },
    })
  }

  const deleteSubscription = () => {
    showMessageBox({
      icon: MessageBoxIcon.ALERT,
      title: 'Conferma l&apos;eliminazione',
      text: <><p>Sei sicuro/a di voler eliminare la sottoscrizione?</p><p><strong>NOTA BENE: </strong>L&apos;operazione è irreversibile.</p></>,
      type: MessageBoxButtonsType.YESNO,
      onOk() {
        setSubscriptionNewStatus(SUBSCRIPTION_STATUS_ENUM.STOPPED);
      },
    })
  }

  const getStatusButtons = () => {
    if (subscription != null) {
      switch (subscription.status) {
        case SUBSCRIPTION_STATUS_ENUM.ACTIVE: return (<>
          {/* <button className="btn btn-outline-primary" onClick={() => editSubscriptionData()}>Modifica sottoscrizione</button> */}
          <button className="btn btn-default btn-error" onClick={() => deactivateSubscription()}>Disattiva sottoscrizione</button>
        </>);
        case SUBSCRIPTION_STATUS_ENUM.PAUSED: return (<>
          <button className="btn btn-primary" onClick={() => reactivateSubscription()}>Riattiva sottoscrizione</button>
          <button className="btn btn-default btn-error" onClick={() => deleteSubscription()}>Elimina sottoscrizione</button>
        </>);
      }
    }
    return null;
  }


  return <ProfileLayout
    showBack
    title={
      subscription?.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE ?
        `Sottoscrizioni attive` :
        subscription?.status === SUBSCRIPTION_STATUS_ENUM.PAUSED ?
          `Sottoscrizioni non attive` :
          ''}
    containerCustomClasses={""}
  >
    {subscription != null ? <div className="row">
      <div className="col-12">
        <div className="mb-3">
          <h4 className="text-primary mb-3">Sottoscrizione n°{subscription?.number}</h4>

          <ul className="list-unstyled">
            <li><b>Consegna ricorrente:</b> ogni 2 settimane</li>
            {
              subscription.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE &&
                subscription.nextOrderStatus !== ORDER_STATUS_ENUM.DELIVERED && subscription.nextOrderDeliveryDate != null ?
                <li><b>Prossima consegna:</b> {convertToDateString(subscription.nextOrderDeliveryDate, true)}</li>
                : undefined
            }
            <li><b>Indirizzo di consegna:</b> {subscription?.nextOrderShippingAddress}</li>
          </ul>
        </div>


        {subscription?.defaultOrder != null ?
          <OrderRender
            order={subscription.defaultOrder}
          /> : null}


        <div className="vstack gap-3 mt-5">
          {getStatusButtons()}
        </div>
      </div >
    </div > : null}
  </ProfileLayout >;
}
