import { ORDER_STATUS_ENUM } from "constants/order";
import { UserOrderCmp } from "models/user";
import { useNavigate } from "react-router-dom";
import { convertToDateString } from "utilities/convertUtils";
import urlUtils from "utilities/urlUtils";

type PropsType = {
  title: string;
  data: UserOrderCmp[];
};

export default ({ title, data }: PropsType) => {
  const navigate = useNavigate();
  if (data.length == 0) {
    return null;
  }
  return (
    <div className="shadow-box p-3">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-4 ">
          <h4>{title}</h4>
          <div className="d-flex flex-column gap-3">
            {data.map((d, idx) => (
              <>
                {idx > 0 ? <hr className="my-0" /> : null}
                <a
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => navigate(urlUtils.user.orders.detail(d.id))}
                >
                  <div className="d-flex flex-column justify-content-between gap-1">
                    <p className="mb-0 text-primary fw-extrabold lh-1">
                      Ordine n°{d.number}
                    </p>
                    {d.status === ORDER_STATUS_ENUM.DELIVERED &&
                    d.delivered != null ? (
                      <small className="text-secondary">
                        Consegnato: {convertToDateString(d.delivered)}
                      </small>
                    ) : undefined}
                  </div>

                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
