import { ORDER_STATUS_ENUM } from "constants/order";
import { SUBSCRIPTION_STATUS_ENUM } from "constants/subscriptions";
import { UserSubscriptionCmp } from "models/user";
import { Link, useNavigate } from "react-router-dom";
import { convertToDateString, getDayOfWeek } from "utilities/convertUtils";
import urlUtils from "utilities/urlUtils";

type PropsType = {
  title: string;
  data: UserSubscriptionCmp[];
};

export default ({ title, data }: PropsType) => {
  if (data.length == 0) {
    return null;
  }

  return (
    <div className="shadow-box p-sm-3">
      <h4 className="mb-4">{title}</h4>

      <div className="d-flex flex-column gap-3">
        {data.map((d, idx) => (
          <>
            {idx > 0 ? <hr className="my-0" /> : null}
            <Link className="d-flex gap-3 align-items-center justify-content-between fw-normal text-secondary" to={(urlUtils.user.subscriptions.detail(d.id))}>
              <div className="d-flex flex-column">
                <p className="text-primary fw-extrabold">Sottoscrizione n°{d.number}</p>
                <ul className="h6 list-unstyled text-secondary mt-2">
                  <li className="fw-normal"><b>Consegna ricorrente:</b> ogni 2 settimane</li>
                  {/* d.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE ? <li className="fw-normal"><b>Prossima consegna:</b> giovedì 20/04/2023</li> : undefined */}
                  {
                    d.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE && d.nextOrderStatus !== ORDER_STATUS_ENUM.DELIVERED && d.nextOrderDeliveryDate != null ?
                      <li className="fw-normal"><b>Prossima consegna:</b> {convertToDateString(d.nextOrderDeliveryDate, true)}</li>
                      : undefined
                  }
                  <li className="fw-normal"><b>Indirizzo:</b> {d.nextOrderShippingAddress}</li>
                </ul>
              </div>

              <span className="material-symbols-outlined">chevron_right</span>
            </Link>
          </>
        ))}
      </div>
    </div>
  );
};
