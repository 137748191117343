import SheetCard from "components/common/SheetCard";
import { useEffect, useState } from "react";
import AddressForm from "./AddressForm";
import { UserAddress, UserAddressType } from "models/user";
import api from "api";
import { UserAddressTypeLabel } from "constants/user";

type PropsType = {
  show: boolean;
  type: UserAddressType;
  selectedAddressId?: string;
  setShowValue: (val: boolean) => void;
  onChange: (val: UserAddress | undefined) => void;
};
export default ({
  show,
  type,
  setShowValue,
  selectedAddressId,
  onChange,
}: PropsType) => {
  const [showEditCard, setShowEditCard] = useState(false);
  const [addresses, setAddresses] = useState<UserAddress[]>([]);
  const [editData, setEditData] = useState<UserAddress | undefined>();

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    onAddressChanged(selectedAddressId);
  }, [selectedAddressId]);

  const refreshData = () => {
    let prom: Promise<UserAddress[]>;
    switch (type) {
      case UserAddressType.SHIPPING: prom = api.user.getShippingAddresses(); break;
      case UserAddressType.BILLING: prom = api.user.getBillingAddresses(); break;
    }
    prom.then((res) => setAddresses(res));
  };

  const addOrEditAddress = (data: UserAddress | undefined) => {
    setEditData(data);
    setShowEditCard(true);
  };

  const [selectedAddress, setSelectedAddress] = useState<
    UserAddress | undefined
  >();
  const onAddressChanged = (aId: string | undefined) => {
    const selAddress = aId == null ? undefined : addresses.find((o) => o.id === aId);
    console.log("onAddressChanged", selAddress)
    setSelectedAddress(selAddress);
  };

  const confirmAddressChange = () => {
    onChange(selectedAddress);
    setShowValue(false);
  };

  return (
    <>
      <SheetCard
        show={show}
        setShowValue={setShowValue}
        title={`Indirizzo di ${UserAddressTypeLabel[type]}`}
        body={
          <div className="container gutter-md">
            <div className="row">
              <div className="col-12">
                <h4 className="mb-4">Seleziona indirizzo di {UserAddressTypeLabel[type]}</h4>

                <div className="d-flex flex-column gap-4 mt-4">
                  {addresses.map((a) => {
                    const addressId = `address-${a.id}`;
                    return (
                      <>
                        <div
                          key={addressId}
                          className="d-flex align-items-center justify-content-between gap-3"
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={a.id}
                              id={addressId}
                              name="address"
                              defaultChecked={selectedAddress?.id === a.id}
                              onChange={(e) =>
                                onAddressChanged(e.currentTarget.value)
                              }
                            />
                            <label
                              className="form-check-label d-flex flex-column gap-1"
                              htmlFor={addressId}
                            >
                              <span className="text-primary fw-extrabold lh-1">
                                {[a.name, a.surname].join(" ")}
                              </span>
                              <span className="h6 fw-normal">{a.address}</span>
                              <span className="h6 fw-normal">
                                {a.postalCode} {a.city} ({a.province})
                              </span>

                              <span className="h6 fw-normal">
                                Numero di telefono: {a.phone}
                              </span>
                            </label>
                          </div>

                          <a
                            className="item-center"
                            onClick={() => addOrEditAddress(a)}
                          >
                            <span className="material-symbols-outlined  fill-1">
                              edit
                            </span>
                          </a>
                        </div>

                        <hr key={`div-${addressId}`} className="my-0" />
                      </>
                    );
                  })}

                  <a
                    onClick={() => addOrEditAddress(undefined)}
                    className="select-address-body-footer d-flex align-items-center gap-custom cursor-pointer" /* style="--g-space: 0.75rem;" */
                  >
                    <span className="material-symbols-outlined fill-1">
                      add
                    </span>
                    <span className="fw-extrabold">
                      Aggiungi un nuovo indirizzo
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        }
        footer={
          <button
            className="btn btn-primary btn-md btn-full"
            onClick={() => confirmAddressChange()}
            disabled={selectedAddress == null}
          >
            Spedisci a questo indirizzo
          </button>
        }
      />
      <AddressForm
        hideBackButton
        type={type}
        show={showEditCard}
        setShowValue={setShowEditCard}
        data={editData}
        refreshData={refreshData}
      />
    </>
  );
};
