import urlUtils from "utilities/urlUtils";
import { RuotePageType } from "../constants/router";
import AppMainPage from "pages/app";
import AppLayout from "components/layout/AppLayout";
import Cart from "pages/app/Cart";
import MyProfile from "pages/user/MyProfile";
import User from "pages/user";
import MyAddresses from "pages/user/MyAddresses";
import UserOrderList from "pages/user/MyOrderList";
import CheckPostalCodePage from "pages/postalCode/CheckPostalCodePage";
import UserOrderDetails from "pages/user/MyOrderDetails";
import DeliveryPage from "pages/app/DeliveryPage";
import PaymentCartPage from "pages/app/PaymentCartPage";
import PaymentOrderPage from "pages/app/PaymentOrderPage";
import PaymentOkPage from "pages/app/PaymentOkPage";
import PaymentKoPage from "pages/app/PaymentKoPage";
import DeliveryTypePage from "pages/app/DeliveryTypePage";
import UserSubscriptionList from "pages/user/MySubscriptionList";
import UserSubscriptionDetails from "pages/user/MySubscriptionDetails";
import ChangePassword from "pages/user/ChangePassword";
import HomePage from "pages/Home";
import PostalCodeCheckKOPage from "pages/postalCode/PostalCodeCheckKOPage";
import PostalCodeCheckOKPage from "pages/postalCode/PostalCodeCheckOKPage";
import CartLoginForm from "components/auth/LoginForm";
import CartLogin from "pages/app/CartLogin";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";

export const auth: RuotePageType[] = [
    {
        name: "user",
        title: "",
        path: urlUtils.user.main(),
        element: <User />,
    },
    {
        name: "user-profile",
        title: "",
        path: urlUtils.user.profile(),
        element: <MyProfile />,
    },
    {
        name: "user-change-password",
        title: "",
        path: urlUtils.user.changePassword(),
        element: <ChangePassword />,
    },
    {
        name: "user-addresses",
        title: "",
        path: urlUtils.user.addresses.main(),
        element: <MyAddresses />,
    },
    {
        name: "user-orders",
        title: "",
        path: urlUtils.user.orders.main(),
        element: <UserOrderList />,
    },
    {
        name: "user-order-details",
        title: "",
        path: urlUtils.user.orders.detail(),
        element: <UserOrderDetails />,
    },
    {
        name: "user-subscriptions",
        title: "",
        path: urlUtils.user.subscriptions.main(),
        element: <UserSubscriptionList />,
    },
    {
        name: "user-subscription-details",
        title: "",
        path: urlUtils.user.subscriptions.detail(),
        element: <UserSubscriptionDetails />,
    },
    {
        name: "cart-delivery",
        title: "",
        path: urlUtils.cartSummary(),
        element: (
            <DeliveryPage />
        ),
    },
    {
        name: "cart-login",
        title: "",
        path: urlUtils.cartLogin(),
        element: (
            <CartLogin />
        ),
    },
    {
        name: "cart-type",
        title: "",
        path: urlUtils.cartType(),
        element: (
            <DeliveryTypePage />
        ),
    },
    {
        name: "order-payment",
        title: "",
        path: urlUtils.payment(),
        element: (
            <PaymentCartPage />
        ),
    },
    {
        name: "order-payment-again",
        title: "",
        path: urlUtils.paymentOrder(),
        element: (
            <PaymentOrderPage />
        ),
    },
    {
        name: "order-payment-ok",
        title: "",
        path: urlUtils.paymentOk(),
        element: <PaymentOkPage />,
    },
    {
        name: "order-payment-ko",
        title: "",
        path: urlUtils.paymentKo(),
        element: <PaymentKoPage />,
    }
];

export const notConfirmedCap: RuotePageType[] = [
    {
        name: "check-location-ko",
        title: "",
        path: urlUtils.checkLocation.ko(),
        element: (
            <PostalCodeCheckKOPage />
        ),
    }
];

export const unauth: RuotePageType[] = [
    {
        name: "login",
        title: "",
        path: urlUtils.login(),
        element: (
            <Login />
        ),
    },
    {
        name: "forgot-password",
        title: "",
        path: urlUtils.forgotPassword(),
        element: (
            <ForgotPassword />
        ),
    },
    {
        name: "reset-password",
        title: "",
        path: urlUtils.resetPassword(),
        element: (
            <ResetPassword />
        ),
    },
];

export const app: RuotePageType[] = [
    {

        name: "check-location-ok",
        title: "",
        path: urlUtils.checkLocation.ok(),
        element: (
            <PostalCodeCheckOKPage />
        ),
    },
    {
        name: "cart",
        title: "",
        path: urlUtils.cart(),
        element: <Cart />,
    },
    {
        name: "main-app",
        title: "",
        path: urlUtils.main(),
        element: (
            <HomePage />
        ),
    }
];

export const open: RuotePageType[] = [
    {
        name: "check-location",
        title: "",
        path: urlUtils.checkLocation.main(),
        element: (
            <CheckPostalCodePage />
        ),
    },
];

export const allRoutes: RuotePageType[] = [
    ...auth,
    ...unauth,
    ...app,
    ...open,
    ...notConfirmedCap,
];