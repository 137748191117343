import googleAnalyticsUtils from "./googleAnalyticsUtils";
import metaPixelUtils from "./metaPixelUtils";
import { AnalyticsEventActionType } from "./types";


export default {
    init() {
        googleAnalyticsUtils.init();
        metaPixelUtils.init();
    },
    sendPageChanged(url: string, title: string) {
        googleAnalyticsUtils.sendPageChanged(url, title);
        metaPixelUtils.sendPageChanged(url, title);
    },
    sendEvent(category: string, action: AnalyticsEventActionType, label: string, customData: any) {
        googleAnalyticsUtils.sendEvent(category, action, label, customData);
        metaPixelUtils.sendEvent(category, action, label, customData);
    }
}